import { ProCard } from '@ant-design/pro-components';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './index.module.less';
import { GCardProps } from './typings';

const GCard: FC<GCardProps> = ({ children, className, ...props }) => (
  <ProCard className={classNames(styles.card, className)} {...props}>
    {children}
  </ProCard>
);

export default GCard;
