import { ProColumns } from '@ant-design/pro-components';
import { message, Modal, TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { AttributeValue, GetAllAttributeKeysOptionalParams } from 'services/api/client/src';
import {
  useAllAttributeKeys,
  useCreateAttributeKey,
  useDeleteAttributeKey,
  useGetAllAttributeKeysAsync,
  useGetAllLanguages,
  useUpdateAttributeKeys,
} from 'services/api/useLanguage';
import { errorHandler } from 'utils';
import { downloadJsonToCSV } from 'utils/helpers';
import { LanguageType } from '../components/LanguageTable/typings';

const getLanguageCodes = (obj: LanguageType) => {
  const values: AttributeValue[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (
      key !== 'attribute' &&
      key !== 'id' &&
      key !== 'status' &&
      key !== 'index' &&
      key !== 'application'
    ) {
      values.push({
        languageCode: key || '',
        value: String(value) || '',
      });
    }
  });
  return values;
};

const useLanguageActions = (initParam?: GetAllAttributeKeysOptionalParams) => {
  const queryClient = useQueryClient();
  const [param, setParam] = useState<GetAllAttributeKeysOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    ...initParam,
  });
  const { data: languages } = useGetAllLanguages();
  const { data: attributes, isLoading } = useAllAttributeKeys(param);
  const deleteAttribute = useDeleteAttributeKey(queryClient);
  const createAttribute = useCreateAttributeKey(queryClient);
  const updateAttribute = useUpdateAttributeKeys(queryClient);
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
  const csvRecords = useGetAllAttributeKeysAsync();
  const { filters: languageSearchfilter } = useTableSearchFilter({
    title: '',
  });
  const languageCols: ProColumns<LanguageType>[] = useMemo(
    () =>
      languages?.results?.map((item) => ({
        title: item?.name || '',
        dataIndex: item?.code || '',
        width: 200,
        formItemProps: {
          rules: [
            {
              required: item?.code === 'en',
              message: t('eng_req'),
            },
          ],
        },
        ...languageSearchfilter,
      })) || [],
    [languageSearchfilter, languages?.results, t],
  );

  const records: LanguageType[] = useMemo(
    () =>
      attributes?.results?.map((item) => ({
        id: item?.id || '',
        attribute: item?.name || '',
        status: item?.status || '',
        application: item?.type === 'ALL' ? ['DESKTOP', 'MOBILE'] : [item?.type || ''],
        ...item?.languageValues?.reduce(
          (obj, i) => Object.assign(obj, { [i.languageCode || '']: i.value }),
          {},
        ),
      })) || [],
    [attributes?.results],
  );

  const onDeleteAttributeAPI = useCallback(
    async (id: string, attributeName: string) => {
      try {
        await deleteAttribute.mutateAsync({ id });
        message.success(t('delete_success', { attributeName }));
      } catch (error) {
        message.error(errorHandler(error));
      }
    },
    [deleteAttribute, t],
  );

  const onCreateAttributeAPI = useCallback(
    async (row: LanguageType) => {
      try {
        const values: AttributeValue[] = getLanguageCodes(row);
        const type = row?.application?.length === 1 ? row?.application?.[0] : 'ALL';
        await createAttribute.mutateAsync({
          name: row?.attribute,
          languageValues: [...values],
          status: row?.status || 'Pending',
          type: type || '',
        });
        message.success(t('add_success', { attributeName: row?.attribute }));
      } catch (error) {
        message.error(errorHandler(error));
      }
    },
    [createAttribute, t],
  );

  const onUpdateAttributeAPI = useCallback(
    async (row: LanguageType) => {
      try {
        const values: AttributeValue[] = getLanguageCodes(row);
        const type = row?.application?.length === 1 ? row?.application?.[0] : 'ALL';
        await updateAttribute.mutateAsync({
          id: row?.id || '',
          attributeName: row?.attribute,
          newValues: [...values],
          status: row?.status,
          types: [String(type)] || [],
        });
        message.success(t('update_success', { attributeName: row?.attribute }));
      } catch (error) {
        message.error(errorHandler(error));
      }
    },
    [t, updateAttribute],
  );

  const getLanguageNameByCode = (code: string) =>
    languages?.results?.find((item) => item?.code === code)?.name || '';

  const onDownloadCSV = async () => {
    const loading = message.loading('Please wait we are processing your request...', 0);
    const response = await csvRecords.mutateAsync({
      ...param,
      pageNumber: 1,
      pageSize: attributes?.totalItems || 100000,
    });
    const csvData =
      response?.results?.map((item) => ({
        Attribute: item?.name || '',
        Status: item?.status || '',
        Application: item?.type === 'ALL' ? ['DESKTOP', 'MOBILE'] : [item?.type || ''],
        ...item?.languageValues?.reduce(
          (obj, i) =>
            Object.assign(obj, { [getLanguageNameByCode(i.languageCode || '')]: i.value }),
          {},
        ),
      })) || [];
    downloadJsonToCSV([...csvData], 'Translations');
    loading();
  };

  const onDeleteAttribute = useCallback(
    (id: string, attributeName: string) => {
      Modal.warning({
        title: t('delete_modal_title', { attributeName }),
        content: t('delete_modal_content', { attributeName }),
        centered: true,
        okCancel: true,
        width: 500,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
          className: 'borderbutton',
        },
        okText: t('yes_delete'),
        cancelText: t('no'),
        onOk: () => onDeleteAttributeAPI(id, attributeName),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
          className: 'primarybutton',
        },
      });
    },
    [onDeleteAttributeAPI, t],
  );

  const onTableChange = (
    params: TablePaginationConfig,
    filter: Record<string, Array<string> | null>,
  ) => {
    const paginate = { pageNumber: params?.current || 10, pageSize: params?.pageSize || 5 };
    const attributeFilter = filter?.attribute
      ? {
          attributeKeyName: filter?.attribute?.[0] as string,
        }
      : {};
    const statusFilter = filter?.status
      ? {
          status: filter?.status as string[],
        }
      : {};

    const typeFilter = filter?.application ? { types: filter?.application as string[] } : {};
    const languageCodes: GetAllAttributeKeysOptionalParams[] = [];

    Object.entries(filter).forEach(([key, value]) => {
      if (
        key !== 'attribute' &&
        key !== 'id' &&
        key !== 'status' &&
        key !== 'index' &&
        key !== 'application'
      ) {
        languageCodes.push({
          languageCodes: [key],
          attributeValue: value?.[0] as string,
        });
      }
    });

    const languageFilters = Object.assign({}, ...languageCodes);
    setParam({
      ...paginate,
      ...attributeFilter,
      ...statusFilter,
      ...languageFilters,
      ...typeFilter,
    });
  };
  return {
    languageCols,
    records,
    attributes,
    onDeleteAttribute,
    onCreateAttributeAPI,
    onUpdateAttributeAPI,
    onTableChange,
    param,
    isLoading,
    onDownloadCSV,
  };
};
export default useLanguageActions;
