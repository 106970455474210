import { ProFormColumnsType } from '@ant-design/pro-components';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { ColProps, RowProps, message } from 'antd';
import { FC, useCallback, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import errorHandler from 'utils/errorHandler';
import CardNumberInput, { CardCvvInput, CardExpiryDate } from './CardInput';
import { CardProps, bodyStyle } from './typings';

const rowProps: RowProps = { gutter: [32, 8] };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps12: ColProps = { xs: 12, sm: 12, md: 12, lg: 12 };
export type DataItem = {
  cardNumber: string;
  expirationDate?: string;
  cvv?: string;
  firstName?: string;
  lastName?: string;
  zipCode?: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'accounts.change_card_modal'>;
};

export const EditChangeCardColumns = ({ t }: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    rowProps,
    columns: [
      {
        title: t('col.card_number'),
        dataIndex: 'cardNumber',
        colProps: colProps24,
        fieldProps: {
          placeholder: t('col.card_number'),
        },
        formItemProps: {
          rules: [
            {
              required: true,
              message: t?.('col.req_card_number'),
            },
          ],
          hasFeedback: false,
        },
        renderFormItem: () => <CardNumberInput />,
      },
    ],
  },
  {
    valueType: 'group',
    colProps: colProps24,
    rowProps,
    columns: [
      {
        title: t?.('col.expiration_date'),
        dataIndex: 'expirationDate',
        colProps: colProps12,
        fieldProps: {
          placeholder: t?.('col.expiration_date'),
        },
        formItemProps: {
          rules: [
            {
              required: true,
              message: t?.('col.req_exp_date'),
            },
          ],
          hasFeedback: false,
        },
        renderFormItem: () => <CardExpiryDate />,
      },
      {
        title: t('col.cvv'),
        dataIndex: 'cvv',
        colProps: colProps12,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t?.('col.req_cvv'),
            },
          ],
          hasFeedback: false,
        },
        fieldProps: {
          placeholder: t('col.cvv'),
        },
        renderFormItem: () => <CardCvvInput />,
      },
    ],
  },
  {
    valueType: 'group',
    rowProps,
    columns: [
      {
        title: t('col.first_name'),
        dataIndex: 'firstName',
        colProps: colProps24,
        fieldProps: {
          placeholder: t('col.first_name'),
          className: 'full-width',
        },
        formItemProps: {
          rules: [
            {
              required: true,
              message: t?.('col.first_name_col_req'),
            },
          ],
          hasFeedback: false,
        },
      },
    ],
  },
  {
    valueType: 'group',
    rowProps,
    columns: [
      {
        title: t('col.last_name'),
        dataIndex: 'lastName',
        colProps: colProps24,
        fieldProps: {
          placeholder: t('col.last_name'),
          className: 'full-width',
        },
        formItemProps: {
          rules: [
            {
              required: true,
              message: t?.('col.last_name_col_req'),
            },
          ],
          hasFeedback: false,
        },
      },
    ],
  },
  {
    valueType: 'group',
    rowProps,
    columns: [
      {
        title: t('col.zip_code'),
        dataIndex: 'zipCode',
        colProps: colProps24,
        fieldProps: {
          placeholder: t('col.zip_code'),
          className: 'full-width',
        },
        formItemProps: {
          rules: [
            {
              required: true,
              message: t?.('col.zip_code_col_req'),
            },
          ],
          hasFeedback: false,
        },
      },
    ],
  },
];

const ChangeCardForm: FC<CardProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.change_card_modal' });
  const modalFormRef = useRef<ProFormInstance>();

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onFinish = async (formData: DataItem) => {
    try {
      message.success(t('success_text', { company: formData.cardNumber }));
      closeModal();
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      title={t('modal_title')}
      formRef={modalFormRef}
      columns={EditChangeCardColumns({
        t,
      })}
      grid
      open={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        maskClosable: false,
        bodyStyle,
        width: '70vh',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('save'),
          resetText: t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
        render: (_, defaultDoms) => defaultDoms,
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default ChangeCardForm;
