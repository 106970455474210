import { type ActionType, type ProColumns } from '@ant-design/pro-components';
import { Button, Select, Tag, Typography } from 'antd';
import classNames from 'classnames';
import { Actions } from 'components';
import GTable from 'components/GTable';
import { useTableSearchFilter } from 'hooks';
import useModalVisibility from 'hooks/useModalVisibility';
import useAccountActions from 'pages/Accounts/hook/useAccountActions';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { getStatusColor } from 'utils';
import AddAccountForm from '../AddAccount/AddAccount';
import styles from './index.module.less';
import { AccountType } from './typings';

const AccountTable: FC = () => {
  const tactionRef = useRef<ActionType>();
  const { records, attributes, onTableChange, param, isLoading } = useAccountActions();
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.table' });
  const addAccountModal = useModalVisibility(false);
  const navigate = useNavigate();

  const { filters: attributeSearchfilter } = useTableSearchFilter({
    title: t('accounts'),
  });

  const statusFilter = useMemo(
    () => [
      { text: t('pending'), label: t('pending'), value: 'Pending' },
      { text: t('completed'), label: t('completed'), value: 'Completed' },
    ],
    [t],
  );

  const applicationFilter = useMemo(
    () => [
      { text: t('desktop'), label: t('desktop'), value: 'DESKTOP' },
      { text: t('mobile'), label: t('mobile'), value: 'MOBILE' },
    ],
    [t],
  );

  const onItemClick = useCallback(
    (actionItemKey: string, actionPayload?: AccountType) => {
      switch (actionItemKey) {
        case 'viewAccount':
          navigate(`/view_account/${actionPayload?.id}`);
          break;
        default:
          break;
      }
    },
    [navigate],
  );

  const columns: ProColumns<AccountType>[] = useMemo(
    () => [
      {
        title: t('accounts'),
        dataIndex: 'accountName',
        fixed: 'left',
        width: 300,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('attri_req'),
            },
          ],
        },
        render: (text, record) => (
          <Link
            className="text-link"
            to={String(`/view_account/${record?.id}`)}
            title={`${t('view')} ${record?.accountName}`}
          >
            <div>{`${record?.accountName}`}</div>
          </Link>
        ),
        ...attributeSearchfilter,
      },
      {
        title: t?.('users'),
        dataIndex: 'usersCount',
        sorter: true,
        width: 200,
        ellipsis: true,
      },
      {
        title: t('subscription_plan'),
        dataIndex: 'plan',
        width: 200,
        filters: applicationFilter,
      },
      {
        title: t('status'),
        dataIndex: 'status',
        filters: statusFilter,
        width: 150,
        renderFormItem: () => <Select defaultValue={t('pending')} options={statusFilter} />,
        render: (text: any, record: AccountType) => (
          <Tag className={getStatusColor(record?.status || '')}>{`${text}`}</Tag>
        ),
      },
      {
        dataIndex: 'actions',
        fixed: 'right',
        valueType: 'option',
        render: (text: any, record: AccountType) => (
          <Actions
            className="actions"
            buttonProps={{
              ghost: true,
              size: 'small',
            }}
            items={[
              {
                key: 'viewAccount',
                label: t('view_account'),
                disabled: false,
              },
              {
                key: 'inviteUsers',
                label: t('invite_users'),
                disabled: false,
              },
            ]}
            onClick={onItemClick}
            actionPayload={record}
          />
        ),
        width: 120,
      },
    ],
    [applicationFilter, attributeSearchfilter, onItemClick, statusFilter, t],
  );

  const onActionItemClick = useCallback(() => {
    addAccountModal.show();
  }, [addAccountModal]);

  return (
    <>
      {addAccountModal.visible && <AddAccountForm modal={addAccountModal} />}
      <GTable<AccountType>
        rowKey="id"
        className={classNames(styles.topmargin)}
        scroll={{ x: 800 }}
        loading={isLoading}
        actionRef={tactionRef}
        toolbar={{
          title: (
            <Typography.Title className={styles.headertitle} level={4}>
              {t('accounts')}
            </Typography.Title>
          ),
          actions: [
            <Button type="primary" shape="round" size="middle" onClick={onActionItemClick}>
              {t('add_account')}
            </Button>,
          ],
        }}
        columns={columns}
        value={records}
        // @ts-ignore
        onTableChange={onTableChange}
        options={{
          reload: false,
          setting: false,
        }}
        pagination={{
          defaultPageSize: param?.pageSize || 10,
          total: attributes?.totalItems,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
      />
    </>
  );
};

export default AccountTable;
