import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';

const marginBottom = { marginBottom: 0 };

const CardNumberInput = () => {
  const { meta, wrapperProps, getCardNumberProps } = usePaymentInputs();
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.change_card_modal.col' });
  return (
    <Form.Item
      style={marginBottom}
      name="cardNumber"
      rules={[
        {
          message: t('valid_card_number'),
          validator: (_, value) => {
            if (value?.trim?.() && meta.isTouched && meta.error) {
              return Promise.reject(new Error(t('valid_card_number')));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <PaymentInputsWrapper
        {...wrapperProps}
        styles={{
          fieldWrapper: {
            base: {},
            errored: {},
          },
          inputWrapper: {
            base: { width: '60vh' },
            errored: {},
            focused: {},
          },
          input: {
            base: {},
            errored: {},
            cardNumber: { width: '60vh' },
            expiryDate: {},
            cvc: {},
          },
          errorText: {
            base: { display: 'none' },
          },
        }}
      >
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          <input {...getCardNumberProps()} />
        }
      </PaymentInputsWrapper>
    </Form.Item>
  );
};

export const CardExpiryDate = () => {
  const { meta, wrapperProps, getExpiryDateProps } = usePaymentInputs();
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.change_card_modal.col' });
  return (
    <Form.Item
      style={marginBottom}
      name="expirationDate"
      rules={[
        {
          message: t('valid_exp_date'),
          validator: (_, value) => {
            if (value?.trim?.() && meta.isTouched && meta.error) {
              return Promise.reject(new Error(t('valid_exp_date')));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <PaymentInputsWrapper
        {...wrapperProps}
        styles={{
          fieldWrapper: {
            base: {},
            errored: {},
          },
          inputWrapper: {
            base: { width: '25vh' },
            errored: {},
            focused: {},
          },
          input: {
            base: {},
            errored: {},
            cardNumber: { width: '25vh' },
            expiryDate: {},
            cvc: {},
          },
          errorText: {
            base: {},
          },
        }}
      >
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          <input {...getExpiryDateProps()} />
        }
      </PaymentInputsWrapper>
    </Form.Item>
  );
};

export const CardCvvInput = () => {
  const { meta, wrapperProps, getCVCProps } = usePaymentInputs();
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.change_card_modal.col' });
  return (
    <Form.Item
      style={marginBottom}
      name="cvv"
      rules={[
        {
          message: t('valid_cvv'),
          validator: (_, value) => {
            if (value?.trim?.() && meta.isTouched && meta.error) {
              return Promise.reject(new Error(t('valid_cvv')));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <PaymentInputsWrapper
        {...wrapperProps}
        styles={{
          fieldWrapper: {
            base: {},
            errored: {},
          },
          inputWrapper: {
            base: { width: '25vh' },
            errored: {},
            focused: {},
          },
          input: {
            base: {},
            errored: {},
            cardNumber: { width: '25vh' },
            expiryDate: {},
            cvc: {},
          },
          errorText: {
            base: {},
          },
        }}
      >
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          <input {...getCVCProps()} />
        }
      </PaymentInputsWrapper>
    </Form.Item>
  );
};

export default CardNumberInput;
