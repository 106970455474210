import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import { Button, TablePaginationConfig, Tag, Typography, message } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { FilterValue } from 'antd/es/table/interface';
import { Actions, GCard } from 'components';
import GTable from 'components/GTable';
import { useAuthenticationContext } from 'contexts';
import { CurrentUser } from 'contexts/AuthenticationProvider/typings';
import { useModalVisibility, useTableSearchFilter } from 'hooks';
import { FC, useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useDeleteInvite } from 'services';
import { ListUsersOptionalParams } from 'services/api/client/src';
import useUsers from 'services/api/useUsers';
import { errorHandler } from 'utils';
import { copyToClipboard } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import { ActionItemProps, UserItem } from '../../InviteModal';
import InviteModal from '../../InviteModal/InviteModal';
import { InviteStatusProps } from './typings';

const ActionItems = ({ record, currentUser, t }: ActionItemProps): Array<ItemType> => {
  switch (record?.invitation) {
    case 'Accepted':
      return [
        {
          key: 'update_role',
          label: t?.('change_roles'),
        },
        ...((record?.id || '') !== (currentUser?.id || '')
          ? [
              {
                key: 'resend_invite',
                label: t?.('resend_invite'),
              },
            ]
          : []),
      ];

    default:
      return [
        {
          key: 'copy_invite_link',
          label: t?.('copy_invite_link'),
        },
        {
          key: 'delete_invite',
          label: t?.('delete_invite'),
          disabled: !record?.inviteId,
        },
      ];
  }
};

const coreRoles = (t?: TFunction<'pages', 'settings.users_permission'>) => [
  {
    text: t?.('employee'),
    value: 'Employee',
  },
  {
    text: t?.('admin'),
    value: 'Admin',
  },
];
const coreInvitaionStatus = (t?: TFunction<'pages', 'settings.users_permission'>) => [
  {
    text: t?.('pending'),
    value: 'Pending',
  },
  {
    text: t?.('accepted'),
    value: 'Accepted',
  },
];
type ColumnType = {
  onRowActionClick: (key: string, payload?: UserItem) => void;
  nameSearchfilter: ProColumns;
  emailSearchfilter: ProColumns;
  currentUser?: CurrentUser;
  t?: TFunction<'pages', 'settings.users_permission'>;
};
const InviteStatus: FC<InviteStatusProps> = ({ status, t }) => {
  const color = useMemo(() => {
    switch (status) {
      case 'Active':
        return 'success';
      case 'Accepted':
        return 'success';
      case 'Pending':
        return 'warning';
      case 'Invited':
        return 'processing';
      case 'Disabled':
        return 'error';
      default:
        return 'default';
    }
  }, [status]);
  const text = useMemo(() => {
    switch (status) {
      case 'Active':
        return t?.('accepted');
      case 'Accepted':
        return t?.('accepted');
      case 'Pending':
        return t?.('pending');
      case 'Invited':
        return t?.('invited');
      case 'Disabled':
        return t?.('disabled');
      default:
        return 'default';
    }
  }, [status, t]);
  const icon = useMemo(() => {
    switch (status) {
      case 'Active':
        return <CheckCircleOutlined />;
      case 'Accepted':
        return <CheckCircleOutlined />;
      case 'Pending':
        return <ClockCircleOutlined />;
      case 'Invited':
        return <ClockCircleOutlined />;
      case 'Disabled':
        return <CloseCircleOutlined />;
      default:
        return <InfoCircleOutlined />;
    }
  }, [status]);
  return (
    <Tag color={color} key={status} icon={icon}>
      {text}
    </Tag>
  );
};

const Columns = ({
  onRowActionClick,
  nameSearchfilter,
  emailSearchfilter,
  currentUser,
  t,
}: ColumnType): Array<ProColumns<UserItem>> => [
  {
    title: t?.('name'),
    dataIndex: 'name',
    ellipsis: true,
    render: (text, record) => (
      <span>
        {record?.name || '-'}
        <Typography.Text type="secondary">
          {(record?.id || '') === (currentUser?.id || '') ? ` ${t?.('me')}` : ''}
        </Typography.Text>
      </span>
    ),
    ...nameSearchfilter,
  },
  {
    title: t?.('email'),
    dataIndex: 'email',
    ellipsis: true,
    ...emailSearchfilter,
  },
  {
    title: t?.('role'),
    dataIndex: 'role',
    ellipsis: true,
    filters: coreRoles(t),
    render: (text) => <Tag>{text}</Tag>,
  },
  {
    title: t?.('invitation'),
    dataIndex: 'status',
    ellipsis: true,
    filters: coreInvitaionStatus(t),
    render: (text, record) =>
      (record?.id || '') !== (currentUser?.id || '') && (
        <InviteStatus status={record.invitation} t={t} />
      ),
  },
  {
    dataIndex: 'actions',
    render: (text, record) => (
      <Actions
        buttonProps={{ ghost: true, size: 'small' }}
        className="actions"
        items={ActionItems({ record, currentUser, t })}
        onClick={onRowActionClick}
        actionPayload={record}
      />
    ),
  },
];

const Users = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.view_account.users' });
  const inviteUserModal = useModalVisibility(false);

  const queryClient = useQueryClient();
  const deleteInvite = useDeleteInvite(queryClient);
  const { currentUser } = useAuthenticationContext();

  const [params, setParams] = useState<ListUsersOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
  });
  const { data: dataUsers, isLoading } = useUsers(params);
  const onSearch = useCallback(
    (text: string, key: string) => {
      setParams({
        ...params,
        pageNumber: 1,
        [key]: text,
      });
    },
    [params],
  );
  const { filters: nameSearchfilter } = useTableSearchFilter({
    title: t('name'),
    onSearch: (text) => onSearch(text, 'name'),
  });
  const { filters: emailSearchfilter } = useTableSearchFilter({
    title: t('email'),
    onSearch: (text) => onSearch(text, 'email'),
  });

  const records: UserItem[] = useMemo(
    () =>
      dataUsers?.results?.map((item) => ({
        ...item,
        role: item?.role ? t(item?.role?.toLocaleLowerCase()) : '',
      })) || [],
    [dataUsers?.results, t],
  );
  const totalItems = useMemo(() => dataUsers?.totalItems || 0, [dataUsers]);
  const onCopyLink = (payload?: UserItem) => {
    copyToClipboard(window.location.origin + (payload?.inviteUrl || ''));
    message.success(t('copy_success'));
  };
  const onDeleteInvite = async (payload?: UserItem) => {
    const success = message.loading(t('deleting_invite'), 0);

    try {
      if (payload?.inviteId) {
        await deleteInvite.mutateAsync(payload.inviteId);
        success();
        message.success(t('delete_invite_success'));
      }
    } catch (error) {
      message.error(errorHandler(error));
      success();
    }
  };

  const onRowActionClick = (key: string, payload?: UserItem) => {
    switch (key) {
      case 'update_role':
        inviteUserModal.show();
        break;
      case 'resend_invite':
        inviteUserModal.show();
        break;
      case 'copy_invite_link':
        onCopyLink(payload);
        break;
      case 'delete_invite':
        onDeleteInvite(payload);
        break;
      default:
        break;
    }
  };

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
  ) => {
    const apiFilters: ListUsersOptionalParams = {
      role: filters?.role?.length ? filters?.role?.map((val) => String(val)) : undefined,
      status: filters?.status?.length ? filters?.status?.map((val) => String(val)) : undefined,
    };

    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        ...apiFilters,
        // sortBy: sorter?.field
        //   ? `${sorter.field}${sorter.order === 'ascend' ? '+' : '-'}`
        //   : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        ...apiFilters,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };

  return (
    <>
      {inviteUserModal.visible && <InviteModal modal={inviteUserModal} />}
      <GCard
        title={t('header')}
        //  style={{ height: '70%' }}
        extra={[
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => inviteUserModal?.show()}
          >
            {t('invite')}
          </Button>,
        ]}
      >
        <GTable<UserItem>
          loading={isLoading}
          columns={Columns({
            onRowActionClick,
            nameSearchfilter,
            emailSearchfilter,
            currentUser,
            t,
          })}
          value={records}
          options={{
            setting: false,
            reload: false,
          }}
          onTableChange={handleChange}
          pagination={{
            defaultPageSize: params.pageSize,
            total: totalItems,
            showTotal: (total, range) =>
              t('users_permission_table_pagi', { range0: range[0], range1: range[1], total }),
          }}
          scroll={{ y: '75vh', x: 1000 }}
          columnsState={{
            persistenceKey: 'users-permission',
          }}
          rowKey={(record) => record?.id || uuidv4()}
        />
      </GCard>
    </>
  );
};

export default Users;
