import { Select, SelectProps, Space, Typography } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { CSSProperties, FC } from 'react';
import { phoneCodes } from 'utils/timezones';
import styles from './index.module.less';
import { FlagEmojiProps } from './typings';

const FlagEmoji: FC<FlagEmojiProps> = ({ iso }) => (
  <img
    src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${iso}.svg`}
    alt=""
    className={styles.extimage}
  />
);

const dropdownStyle: CSSProperties = { minWidth: '300px' };
const PhoneCodeSelect = <
  ValueType = string,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => (
  // we create here the list with the shape the select is expecting for

  <Select
    placeholder="Select Extension"
    allowFilterOption
    // @ts-ignore
    defaultValue="US"
    {...props}
    className={styles.phonecodeselect}
    dropdownClassName={styles.phonecodedropdown}
    optionLabelProp="label"
    dropdownStyle={dropdownStyle}
  >
    {phoneCodes.map((ext) => (
      <Select.Option
        label={<FlagEmoji iso={ext.iso} />}
        value={ext.iso}
        key={ext.iso}
        itemProps={ext}
      >
        <Space>
          <FlagEmoji iso={ext.iso} />
          <Typography.Text>{ext.country}</Typography.Text>
          <Typography.Text type="secondary">+{String(ext.code).replace('+', '')}</Typography.Text>
        </Space>
      </Select.Option>
    ))}
  </Select>
);
export default PhoneCodeSelect;
