import type { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps, RadioChangeEvent, RowProps, Typography } from 'antd';
import { RadioSelectPlan } from 'components';
import { TFunction } from 'react-i18next';
import { planTextStyle } from './typings';

const rowProps: RowProps = { gutter: [32, 8] };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
export type DataItem = {
  selectedPlan: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'accounts.add_account'>;
  defaultPlan?: string;
};

const options = [
  {
    value: 'basic',
    options: ['25 Profiles', 'Dynamic QR Codes'],
    extra: '$99',
  },
  {
    value: 'business',
    options: ['50 Profiles', 'Dynamic QR Codes', 'Collections', 'Advanced Brand Settings'],
    extra: '$299',
  },
  {
    value: 'enterprise',
    options: [
      '50 Profiles',
      'Dynamic QR Codes',
      'Collections',
      'Advanced Brand Settings',
      'Multiple Brands',
      'Dynamic Profiles',
      'Custom Domain',
      'Dedicated Onboarding & Account Management',
    ],
    extra: '$1500',
  },
];

export const SourceviewColumns = ({
  t,
  defaultPlan,
}: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    colProps: colProps24,
    rowProps,
    columns: [
      {
        title: <Typography.Text style={planTextStyle}>{t('selected_plan')}</Typography.Text>,
        dataIndex: 'selectedPlan',
        colProps: colProps24,
        renderFormItem: (_, __, form) => (
          <RadioSelectPlan
            options={options}
            defaultValue={defaultPlan}
            t={t}
            onChange={(e: RadioChangeEvent) => {
              form?.setFieldsValue({
                selectedPlan: e.target.value,
              });
            }}
          />
        ),
      },
    ],
  },
];
