import { ProDescriptionsItemProps } from '@ant-design/pro-components';
import { cardlabelStyle } from 'pages/Accounts/typings';
import { TFunction } from 'react-i18next';

export type DataItem = {
  totalSubscriptionPrice: string;
  nextPaymentDue: string;
  billingType: string;
  nextInvoice: string;
  paymentMethod: string;
  lastPayment: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'accounts.view_account.billing'>;
};

const columns = ({ t }: ColumnsType): ProDescriptionsItemProps<DataItem>[] =>
  [
    {
      title: t('total_sub_price'),
      dataIndex: 'totalSubscriptionPrice',
    },
    {
      title: t('next_payment_due'),
      dataIndex: 'nextPaymentDue',
    },
    {
      title: t('billing_type'),
      dataIndex: 'billingType',
    },
    {
      title: t('next_invoice'),
      dataIndex: 'nextInvoice',
    },
    {
      title: t('payment_method'),
      dataIndex: 'paymentMethod',
    },
    {
      title: t('last_payment'),
      dataIndex: 'lastPayment',
    },
  ]?.map((item) => ({ ...item, labelStyle: cardlabelStyle, span: 8 }));

export default columns;
