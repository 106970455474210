import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormInstance } from '@ant-design/pro-components';
import type { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps, RowProps, SelectProps, Space, Switch, Tooltip, Typography } from 'antd';
import { PlacesSelect, TimezoneSelect } from 'components/GSelect';
import PhoneCodeSelect from 'components/GSelect/PhoneCodeSelect';
import { AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { ChangeEvent, MutableRefObject, useState } from 'react';
import { TFunction } from 'react-i18next';
import { PhoneCode } from 'utils';
import styles from './index.module.less';

const rowProps: RowProps = { gutter: [32, 8] };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps12: ColProps = { xs: 12, sm: 12, md: 12, lg: 12 };
// const colProps8: ColProps = { xs: 8, sm: 8, md: 8, lg: 8 };
export type DataItem = {
  gs1Member: boolean;
  email: string[];
  compName: string;
  compAddress: string;
  timeZone: string;
  coordinates: string;
  phone: string;
  erpSystem: string;
  hubspot: string;
  pgln: string;

  address?: string;
  addressCity?: string;
  addressState?: string;
  addressCountry?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressPostalCode?: string;
  phoneCode?: string;
  phoneISO?: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'accounts.add_account'>;
  modalFormRef: MutableRefObject<ProFormInstance | undefined>;
  gs1ValInit?: boolean;
  onChangePlace: SelectProps['onChange'];
};

export const AddEditAccountColumns = ({
  t,
  modalFormRef,
  gs1ValInit,
  onChangePlace,
}: ColumnsType): ProFormColumnsType<DataItem>[] => {
  const [swicthVal, setSwitchVal] = useState<boolean>(gs1ValInit || false);

  return [
    {
      valueType: 'group',
      colProps: colProps24,
      rowProps,
      columns: [
        {
          dataIndex: 'gs1Member',
          colProps: colProps12,
          formItemProps: {
            style: { height: 0 },
          },
          fieldProps: {
            style: { display: 'none' },
          },
          title: (
            <Space>
              <Typography.Text>
                {t('gs1_member')}{' '}
                <Tooltip
                  placement="bottom"
                  title={
                    <div className={styles.tooltiplink}>
                      {t?.('gs1_member_tooltip')}
                      <Typography.Link
                        target="_blank"
                        href="https://support.wholechain.com/article/281-gs1-membership"
                      >
                        &nbsp;{t?.('gs1_member_link')}
                      </Typography.Link>
                    </div>
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </Typography.Text>
              <Switch
                checked={swicthVal}
                onChange={(checked: boolean) => {
                  setSwitchVal(checked);
                  modalFormRef?.current?.setFieldsValue({ gs1Member: checked });
                }}
              />
            </Space>
          ),
        },
        {
          title: '',
          dataIndex: 'email',
          valueType: 'checkbox',
          valueEnum: {
            email: t?.('my_email'),
          },
          colProps: colProps12,
        },
      ],
    },
    {
      valueType: 'group',
      colProps: colProps24,
      rowProps,
      columns: [
        {
          title: t?.('company_name'),
          dataIndex: 'compName',
          colProps: colProps12,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t?.('company_name_col_req'),
              },
            ],
            hasFeedback: false,
          },
          fieldProps: {
            placeholder: t?.('company_name'),
          },
        },
        {
          title: t('company_address'),
          dataIndex: 'address',
          colProps: colProps12,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t?.('company_address_col_req'),
              },
            ],
            hasFeedback: false,
          },
          renderFormItem: () => <PlacesSelect onChange={onChangePlace} allowClear />,
          fieldProps: {
            placeholder: t('company_address'),
          },
        },
      ],
    },
    {
      valueType: 'group',
      colProps: colProps24,
      rowProps,
      columns: [
        {
          title: t?.('time_zone'),
          dataIndex: 'timeZone',
          colProps: colProps12,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t?.('time_zone_col_req'),
              },
            ],
            hasFeedback: false,
          },
          renderFormItem: () => <TimezoneSelect />,
          fieldProps: {
            placeholder: t?.('time_zone'),
          },
        },
        {
          title: t('coordinates'),
          dataIndex: 'coordinates',
          colProps: colProps12,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t?.('coordinates_col_req'),
              },
            ],
            hasFeedback: false,
          },
          fieldProps: {
            placeholder: t('coordinates'),
          },
        },
      ],
    },
    {
      valueType: 'group',
      colProps: colProps24,
      rowProps,
      columns: [
        {
          valueType: 'dependency',
          fieldProps: {
            name: ['phoneISO'],
          },
          colProps: colProps12,
          columns: ({ phoneISO }: DataItem) => [
            {
              title: t?.('phone_no'),
              dataIndex: 'phone',
              colProps: colProps12,
              fieldProps: (form) => ({
                placeholder: t?.('phone_no'),
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  const countryCode = form?.getFieldValue?.('phoneISO') || 'US';
                  const value = new AsYouType(countryCode).input(e.target.value);
                  form?.setFieldsValue({
                    phone: value,
                  });
                },
                addonBefore: (
                  <PhoneCodeSelect
                    onChange={(val, option) => {
                      // @ts-ignore
                      const item: PhoneCode = option?.itemProps;
                      form?.setFieldsValue({
                        phoneCode: `+${String(item?.code || '').replace('+', '')}`,
                        phoneISO: item.iso,
                      });
                    }}
                  />
                ),
              }),
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t?.('phone_no_col_req'),
                  },
                  {
                    message: t?.('phone_number_valid'),
                    validator: (_, value) => {
                      if (!value?.trim?.()) {
                        return Promise.resolve();
                      }
                      if (isValidPhoneNumber(value, phoneISO as CountryCode)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t?.('phone_number_valid')));
                    },
                  },
                ],
              },
            },
          ],
        },
        {
          title: t('erp_system'),
          dataIndex: 'erpSystem',
          colProps: colProps12,
          fieldProps: {
            placeholder: t('erp_system'),
          },
        },
      ],
    },
    {
      valueType: 'group',
      colProps: colProps24,
      rowProps,
      columns: [
        {
          title: t?.('hubspot'),
          dataIndex: 'hubspot',
          colProps: colProps12,
          fieldProps: {
            placeholder: t?.('hubspot'),
          },
        },
        {
          title: t('pgln'),
          dataIndex: 'pgln',
          colProps: colProps12,
          fieldProps: {
            placeholder: t('pgln'),
            disabled: true,
          },
        },

        /* hidden fields */
        {
          dataIndex: 'addressCity',
          fieldProps: {
            className: 'no-display',
          },
          formItemProps: {
            className: 'no-display',
          },
          colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
        },
        {
          dataIndex: 'addressCountry',
          fieldProps: {
            className: 'no-display',
          },
          formItemProps: {
            className: 'no-display',
          },
          colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
        },
        {
          dataIndex: 'addressState',
          fieldProps: {
            className: 'no-display',
          },
          formItemProps: {
            className: 'no-display',
          },
          colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
        },
        {
          dataIndex: 'addressLine1',
          fieldProps: {
            className: 'no-display',
          },
          formItemProps: {
            className: 'no-display',
          },
          colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
        },
        {
          dataIndex: 'addressLine2',
          fieldProps: {
            className: 'no-display',
          },
          formItemProps: {
            className: 'no-display',
          },
          colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
        },
        {
          dataIndex: 'addressPostalCode',
          fieldProps: {
            className: 'no-display',
          },
          formItemProps: {
            className: 'no-display',
          },
          colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
        },
        {
          dataIndex: 'phoneCode',
          initialValue: '+1',
          fieldProps: {
            className: 'no-display',
          },
          formItemProps: {
            className: 'no-display',
          },
          colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
        },
        {
          dataIndex: 'phoneISO',
          initialValue: 'US',
          fieldProps: {
            className: 'no-display',
          },
          formItemProps: {
            className: 'no-display',
          },
          colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
        },
      ],
    },
  ];
};
