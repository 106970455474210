import { ProDescriptionsItemProps } from '@ant-design/pro-components';
import { cardlabelStyle } from 'pages/Accounts/typings';
import { TFunction } from 'react-i18next';

export type DataItem = {
  compName: string;
  compAddress: string;
  timeZone: string;
  coordinates: string;
  phone: string;
  erpSystem: string;
  hubspot: string;
  pgln: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'accounts.view_account.company'>;
};

const columns = ({ t }: ColumnsType): ProDescriptionsItemProps<DataItem>[] =>
  [
    {
      title: t('comp_name'),
      dataIndex: 'compName',
    },
    {
      title: t('comp_address'),
      dataIndex: 'compAddress',
    },
    {
      title: t('timeZone'),
      dataIndex: 'timeZone',
    },
    {
      title: t('coordinates'),
      dataIndex: 'coordinates',
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
    },
    {
      title: t('erp_system'),
      dataIndex: 'erpSystem',
    },
    {
      title: t('hubspot'),
      dataIndex: 'hubspot',
    },
    {
      title: t('pgln'),
      dataIndex: 'pgln',
    },
  ]?.map((item) => ({ ...item, labelStyle: cardlabelStyle, span: 5 }));

export default columns;
