import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { Input, Select, Tag, Typography } from 'antd';
import classNames from 'classnames';
import { ActionButtons, Actions } from 'components';
import GTable from 'components/GTable';
import { useTableSearchFilter } from 'hooks';
import useModalVisibility from 'hooks/useModalVisibility';
import useLanguageActions from 'pages/Dashboard/hook/useLanguageActions';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getStatusColor } from 'utils';
import JSONUpload from '../JSONUpload';
import styles from './index.module.less';
import { LanguageType } from './typings';

const LanguageTable: FC = () => {
  const tactionRef = useRef<ActionType>();
  const {
    languageCols,
    records,
    attributes,
    onDeleteAttribute,
    onCreateAttributeAPI,
    onUpdateAttributeAPI,
    onTableChange,
    param,
    isLoading,
    onDownloadCSV,
  } = useLanguageActions();
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
  const uploadJSONModal = useModalVisibility(false);

  const { filters: attributeSearchfilter } = useTableSearchFilter({
    title: t('attribute'),
  });

  const statusFilter = useMemo(
    () => [
      { text: t('pending'), label: t('pending'), value: 'Pending' },
      { text: t('completed'), label: t('completed'), value: 'Completed' },
    ],
    [t],
  );

  const applicationFilter = useMemo(
    () => [
      { text: t('desktop'), label: t('desktop'), value: 'DESKTOP' },
      { text: t('mobile'), label: t('mobile'), value: 'MOBILE' },
    ],
    [t],
  );

  const onEditRow = (row?: LanguageType) => {
    tactionRef.current?.startEditable(String(row?.id) || 0);
  };

  const columns: ProColumns<LanguageType>[] = useMemo(
    () => [
      {
        title: t('attribute'),
        dataIndex: 'attribute',
        fixed: 'left',
        width: 300,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('attri_req'),
            },
          ],
        },
        renderFormItem: (_, config) => (
          <Input placeholder={t('attribute')} disabled={!!config?.record?.status} />
        ),
        ...attributeSearchfilter,
      },
      ...languageCols,
      {
        title: t('status'),
        dataIndex: 'status',
        filters: statusFilter,
        width: 150,
        renderFormItem: () => <Select defaultValue={t('pending')} options={statusFilter} />,
        render: (text: any, record: LanguageType) => (
          <Tag className={getStatusColor(record?.status || '')}>{`${text}`}</Tag>
        ),
      },
      {
        title: t('application'),
        dataIndex: 'application',
        width: 200,
        filters: applicationFilter,
        renderFormItem: () => (
          <Select mode="tags" defaultValue={['DESKTOP', 'MOBILE']} options={applicationFilter} />
        ),
        render: (text: any) => text?.map((item: string) => <Tag className="blue-tag">{item}</Tag>),
      },
      {
        dataIndex: 'actions',
        fixed: 'right',
        valueType: 'option',
        render: (text: any, record: LanguageType) => (
          <ActionButtons record={record} onEdit={onEditRow} showDelete={false} />
        ),
        width: 120,
      },
    ],
    [applicationFilter, attributeSearchfilter, languageCols, statusFilter, t],
  );

  const onSaveAttribute = useCallback(
    async (_: any, data: LanguageType) => {
      const editableRecord = records.find((record) => record.attribute === data.attribute);
      if (editableRecord) {
        onUpdateAttributeAPI(data);
      } else {
        onCreateAttributeAPI(data);
      }
      tactionRef.current?.cancelEditable(data?.attribute || 0);
    },
    [onCreateAttributeAPI, onUpdateAttributeAPI, records],
  );

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'addTable':
        tactionRef.current?.addEditRecord?.({
          id: Date.now().toString(),
        });
        break;
      case 'addJSON':
        uploadJSONModal.show();
        break;
      case 'csv':
        onDownloadCSV();
        break;

      default:
        break;
    }
  };

  return (
    <>
      {uploadJSONModal.visible && <JSONUpload modal={uploadJSONModal} />}
      <GTable<LanguageType>
        rowKey="id"
        className={classNames(styles.topmargin)}
        scroll={{ x: 800 }}
        loading={isLoading}
        actionRef={tactionRef}
        columns={columns}
        value={records}
        // @ts-ignore
        onTableChange={onTableChange}
        editable={{
          onSave: onSaveAttribute,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
          delete: true,
        }}
        headerTitle={
          <Typography.Title className={styles.headertitle} level={4}>
            {t('language_selector')}
          </Typography.Title>
        }
        customDeleteButton={(row) => {
          if (row?.status) {
            tactionRef.current?.cancelEditable(row?.id || 0);
            onDeleteAttribute(row?.id, row?.attribute);
          }
        }}
        toolBarRender={() => [
          <Actions
            key="actions"
            items={[
              {
                key: 'addTable',
                label: t('add_table'),
                disabled: false,
              },
              {
                key: 'addJSON',
                label: t('add_json'),
                disabled: false,
              },
              {
                key: 'csv',
                label: t('download_csv'),
                disabled: !records?.length,
              },
            ]}
            onClick={onActionItemClick}
          />,
        ]}
        pagination={{
          defaultPageSize: param?.pageSize || 10,
          total: attributes?.totalItems,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        }}
      />
    </>
  );
};

export default LanguageTable;
