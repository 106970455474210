import { WebAPIClient } from 'services/api/client/src';
import { getVariableFromEnv } from 'utils/config';
import TokenCredential from './TokenCredential';

const host = getVariableFromEnv('API_HOST');

const httpClient = new WebAPIClient(new TokenCredential(), host, {
  retryOptions: { maxRetries: 1 },
});

export default httpClient;
