import type { ProFormColumnsType } from '@ant-design/pro-form';
import { Button, ColProps, RowProps } from 'antd';
import { TFunction } from 'react-i18next';
import styles from './index.module.less';

const rowProps: RowProps = { gutter: [32, 8] };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps12: ColProps = { xs: 12, sm: 12, md: 12, lg: 12 };
export type DataItem = {
  nextPaymentDue?: string;
  billingType?: string;
  wcSubscriptionPrice?: string;
  scSubscriptionPrice?: string;
  paymentMethod?: string;
  nextInvoice?: string;
  lastPayment?: string;
  totalSubscriptionPrice?: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'accounts.edit_billing'>;
  onChangeCard: () => void;
};

export const EditBillingColumns = ({
  t,
  onChangeCard,
}: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    colProps: colProps24,
    rowProps,
    columns: [
      {
        title: t('col.next_payment_due'),
        dataIndex: 'nextPaymentDue',
        valueType: 'date',
        colProps: colProps12,
        fieldProps: {
          placeholder: t('col.next_payment_due'),
          className: 'full-width',
        },
      },
      {
        title: t('col.billing_type'),
        dataIndex: 'billingType',
        valueType: 'select',
        valueEnum: {
          monthlyPrice: {
            text: t('col.monthly_price'),
            status: 'Default',
          },
          monthlyPriceAnually: {
            text: t('col.monthly_price_paid_annually'),
            status: 'Default',
          },
        },
        colProps: colProps12,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t?.('col.billing_type_col_req'),
            },
          ],
          hasFeedback: false,
        },
        fieldProps: {
          placeholder: t('col.billing_type'),
        },
      },
    ],
  },
  {
    valueType: 'group',
    colProps: colProps24,
    rowProps,
    columns: [
      {
        title: t('col.next_invoice_due'),
        dataIndex: 'nextInvoice',
        valueType: 'date',
        colProps: colProps12,
        fieldProps: {
          placeholder: t('col.next_invoice_due'),
          className: 'full-width',
        },
      },
      {
        title: t('col.wc_subscription_price'),
        dataIndex: 'wcSubscriptionPrice',
        colProps: colProps12,
        formItemProps: {
          rules: [
            {
              pattern: /^([-]?[1-9][0-9]*|0)$/,
              message: t('col.wc_subscription_price_col_req'),
            },
            {
              required: true,
              message: t?.('col.wc_subscription_price_col_req'),
            },
          ],
          hasFeedback: false,
        },
        fieldProps: {
          placeholder: t('col.wc_subscription_price'),
          addonBefore: '$',
        },
      },
    ],
  },
  {
    valueType: 'group',
    colProps: colProps24,
    rowProps,
    columns: [
      {
        title: t?.('col.payment_method'),
        dataIndex: 'paymentMethod',
        colProps: colProps12,
        fieldProps: {
          placeholder: t?.('col.payment_method'),
        },
        renderFormItem: (_, __, form) => (
          <div className={styles.divspace}>
            {form?.getFieldValue('paymentMethod')}
            <Button type="link" className="primarycolor" onClick={onChangeCard}>
              {t?.('col.change_card')}
            </Button>
          </div>
        ),
      },
      {
        title: t('col.sc_subscription_price'),
        dataIndex: 'scSubscriptionPrice',
        colProps: colProps12,
        formItemProps: {
          rules: [
            {
              pattern: /^([-]?[1-9][0-9]*|0)$/,
              message: t('col.wc_subscription_price_col_req'),
            },
            {
              required: true,
              message: t?.('col.sc_subscription_price_col_req'),
            },
          ],
          hasFeedback: false,
        },
        fieldProps: {
          placeholder: t('col.sc_subscription_price'),
          addonBefore: '$',
        },
      },
    ],
  },
  {
    valueType: 'group',
    colProps: colProps24,
    rowProps,
    columns: [
      {
        title: t?.('col.last_payment'),
        dataIndex: 'lastPayment',
        colProps: colProps12,
        fieldProps: {
          placeholder: t?.('col.last_payment'),
        },
        renderFormItem: (_, __, form) => <div>{form?.getFieldValue('lastPayment')}</div>,
      },
      {
        title: t('col.total_subscription_price'),
        dataIndex: 'totalSubscriptionPrice',
        colProps: colProps12,
        fieldProps: {
          placeholder: t('col.total_subscription_price'),
        },
        renderFormItem: (_, __, form) => <div>{form?.getFieldValue('totalSubscriptionPrice')}</div>,
      },
    ],
  },
];
