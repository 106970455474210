import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  AddAttributeKeyBulkRequest,
  AddAttributeKeyRequest,
  BulkUpdateAttributeKeyRequest,
  BulkUpdateAttributeKeysResponse,
  CreateAttributeKeyBulkResponse,
  CreateAttributeKeyResponse,
  DeleteAttributeKeyRequest,
  DeleteAttributeKeyResponse,
  GetAccountOptionalParams,
  GetAllAttributeKeysOptionalParams,
  GetAllAttributeKeysResponse,
  GetAttributeValueOperationResponse,
  GetAttributeValueOptionalParams,
  ListLanguagesOperationResponse,
  ListLanguagesOptionalParams,
  UpdateAttributeKeyRequest,
  UpdateAttributeKeysResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

/* Get all languages */

const getAllLanguages = async (
  params?: ListLanguagesOptionalParams,
): Promise<ListLanguagesOperationResponse> => HttpClient.listLanguages(params);

export const useGetAllLanguages = (params?: GetAccountOptionalParams) =>
  useQuery(['allLanguages', params], () => getAllLanguages(params));

export const getAllAttributeKeys = (
  params?: GetAllAttributeKeysOptionalParams,
): Promise<GetAllAttributeKeysResponse> => HttpClient.getAllAttributeKeys(params);

export const useAllAttributeKeys = (params?: GetAllAttributeKeysOptionalParams) =>
  useQuery(['allAttributes', params], () => getAllAttributeKeys(params));

const deleteAttributeKey = (
  params?: DeleteAttributeKeyRequest,
): Promise<DeleteAttributeKeyResponse> => HttpClient.deleteAttributeKey({ body: params });

export const useDeleteAttributeKey = (queryClient: QueryClient) =>
  useMutation((body: DeleteAttributeKeyRequest) => deleteAttributeKey(body), {
    onSuccess: () => {
      queryClient.refetchQueries('allAttributes');
    },
  });

export const createAttributeKey = (
  params?: AddAttributeKeyRequest,
): Promise<CreateAttributeKeyResponse> => HttpClient.createAttributeKey({ body: params });

export const useCreateAttributeKey = (queryClient: QueryClient) =>
  useMutation((body: AddAttributeKeyRequest) => createAttributeKey(body), {
    onSuccess: () => {
      queryClient.refetchQueries('allAttributes');
    },
  });

export const updateAttributeKeys = (
  params?: UpdateAttributeKeyRequest,
): Promise<UpdateAttributeKeysResponse> => HttpClient.updateAttributeKeys({ body: params });

export const useUpdateAttributeKeys = (queryClient: QueryClient) =>
  useMutation((body: UpdateAttributeKeyRequest) => updateAttributeKeys(body), {
    onSuccess: () => {
      queryClient.refetchQueries('allAttributes');
    },
  });

export const useGetAllAttributeKeysAsync = () =>
  useMutation((params?: GetAllAttributeKeysOptionalParams) => getAllAttributeKeys(params));

export const getAttributeValue = (
  options?: GetAttributeValueOptionalParams,
): Promise<GetAttributeValueOperationResponse> => HttpClient.getAttributeValue(options);

export const createAttributeKeyBulk = (
  options?: AddAttributeKeyBulkRequest,
): Promise<CreateAttributeKeyBulkResponse> => HttpClient.createAttributeKeyBulk({ body: options });

export const bulkUpdateAttributeKeys = (
  options?: BulkUpdateAttributeKeyRequest,
): Promise<BulkUpdateAttributeKeysResponse> =>
  HttpClient.bulkUpdateAttributeKeys({ body: options });

export const useCreateAttributeKeyBulk = (queryClient: QueryClient) =>
  useMutation((body: AddAttributeKeyBulkRequest) => createAttributeKeyBulk(body), {
    onSuccess: () => {
      queryClient.refetchQueries('allAttributes');
    },
  });
