import { ActionType, ProColumns } from '@ant-design/pro-components';
import { Col, Row, Typography } from 'antd';
import { ActionButtons } from 'components';
import GTable from 'components/GTable';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import { DataType, PlanTableProps } from './typings';

const PlanTable: FC<PlanTableProps> = ({ records, title, subTitle, extraSubTitle, onSave }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.view_account.subscription' });
  const tactionRef = useRef<ActionType>();
  const columns: ProColumns<DataType>[] = [
    {
      title: '',
      dataIndex: 'title',
      editable: () => false,
      width: 150,
      render: (text) => ({
        props: {
          className: styles.colgrey,
        },
        children: <Typography.Text className={styles.title}>{text}</Typography.Text>,
      }),
    },
    {
      title: t('col.current'),
      dataIndex: 'current',
      editable: () => false,
    },
    {
      title: t('col.plan'),
      dataIndex: 'plan',
      editable: () => false,
    },
    {
      title: t('col.custom'),
      dataIndex: 'custom',
      editable: () => true,
    },
    {
      dataIndex: 'actions',
      fixed: 'right',
      valueType: 'option',
      render: (text: any, record: DataType) => (
        <ActionButtons
          record={record}
          onEdit={() => tactionRef?.current?.startEditable(record?.id)}
          showDelete={false}
        />
      ),
      width: 80,
    },
  ];

  const onSaveAttribute = useCallback(
    async (_: any, data: DataType) => {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ file: Subscription.tsx:108 ~ data:', data);
      onSave(data);
    },
    [onSave],
  );

  return (
    <GTable<DataType>
      rowKey="id"
      columns={columns}
      actionRef={tactionRef}
      actionsRenderOptions={{
        save: true,
        cancel: true,
        delete: false,
      }}
      headerTitle={
        <Col>
          <Typography.Text>{title}</Typography.Text>
          <Row className={styles.row}>
            <Typography.Text className={styles.subtitle}>{subTitle}</Typography.Text>
            {extraSubTitle}
          </Row>
        </Col>
      }
      value={records}
      editable={{
        onSave: onSaveAttribute,
      }}
      options={{
        reload: false,
        setting: false,
      }}
    />
  );
};

export default PlanTable;
