import { BellOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { useAccount } from 'services';
import AvatarDropdown from './AvatarDropdown';
import styles from './index.module.less';
import { RightContentProps } from './typings';

export type SiderTheme = 'light' | 'dark';

const RightContent: React.FC<RightContentProps> = ({ settings }) => {
  const { data: account } = useAccount();
  const { navTheme, layout } = settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right} ${styles.dark}`;
  }

  return (
    <div className={className}>
      <Tooltip title="Search">
        <Button
          type="text"
          icon={<SearchOutlined className={styles.icon} />}
          // onClick={() => window.Beacon('toggle')}
        />
      </Tooltip>
      <Tooltip title="Notifications">
        <Button
          type="text"
          icon={<BellOutlined className={styles.icon} />}
          // onClick={() => window.Beacon('toggle')}
        />
      </Tooltip>

      {/* <Tooltip title="Settings">
        <Button
          type="text"
          icon={<SettingOutlined className={styles.icon} />}
          onClick={() => navigate('../settings')}
        />
      </Tooltip> */}
      {/* <NotificationDropdown /> */}
      {/* <SourceDropdown /> */}
      <AvatarDropdown accountName={account?.name} />
    </div>
  );
};
export default RightContent;
