import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message } from 'antd';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from 'utils/errorHandler';
import { DataItem, SourceviewColumns } from './Sourceview.fields';
import { SourceviewProps, bodyStyle } from './typings';

const AddSourceviewForm: FC<SourceviewProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.add_sourceview' });
  const modalFormRef = useRef<ProFormInstance>();

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onFinish = async (formData: DataItem) => {
    try {
      message.success(t('add_success_text', { company: formData.selectedPlan }));
      closeModal();
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      title={t('add_modal_title')}
      formRef={modalFormRef}
      columns={SourceviewColumns({
        t,
      })}
      grid
      open={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        maskClosable: false,
        bodyStyle,
        width: '150vh',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('add'),
          resetText: t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
        render: (_, defaultDoms) => defaultDoms,
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default AddSourceviewForm;
