export const isLoggedInUser = (connectionType: string) => connectionType === 'SELF';
export const isNetworkInvite = (status: string) => status === 'Pending' || status === 'Rejected';
export const getAccountType = (
  isOwnAccount?: boolean,
  isWCUser?: boolean,
  isRemovedWC?: boolean,
) => {
  if (isOwnAccount) {
    return 'ME';
  }
  if (isRemovedWC) {
    return 'NONWC';
  }
  if (isWCUser) {
    return 'WC';
  }
  return 'NONWC';
};

export const getConnectionTypeString = (value: string) => {
  if (value.toUpperCase() === 'BUYER') {
    return 'BUYER';
  }
  if (value.toUpperCase() === 'SUPPLIER') {
    return 'SUPPLIER';
  }
  return 'OTHER';
};

export const showConnectionType = (value: string) => {
  if (value.toUpperCase() === 'BUYER') {
    return 'buyer';
  }
  if (value.toUpperCase() === 'SUPPLIER') {
    return 'supplier';
  }
  return 'other';
};

export const getStatusColor = (status: string) => {
  switch (status.toLocaleUpperCase()) {
    case 'PENDING':
      return 'yellow-tag';
    case 'COMPLETED':
      return 'green-tag';
    default:
      return 'blue';
  }
};

export const getStatus = (status: string) => {
  if (status === 'Self') {
    return 'Wholechain';
  }

  if (status === 'NonWholechain') {
    return 'Non-Wholechain';
  }

  return status;
};

export const generateAPIKey = (
  length = 20,
  wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$',
) =>
  Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => wishlist[x % wishlist.length])
    .join('');

export const generateHash = async (text: string) => {
  const textAsBuffer = new TextEncoder().encode(text);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const digest = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return digest;
};
