import { Button } from 'antd';
import { GCard, GDescriptions } from 'components';
import { useModalVisibility } from 'hooks';
import { useTranslation } from 'react-i18next';
import EditAccountForm from '../../AddAccount/EditAccount';
import columns, { DataItem } from './Company.fields';

const colProps = { xs: 4, sm: 8, md: 12, lg: 24 };

const records: DataItem = {
  compName: 'abc',
  compAddress: 'abc',
  timeZone: 'abc',
  coordinates: 'abc',
  phone: 'abc',
  erpSystem: 'abc',
  hubspot: 'abc',
  pgln: 'abc',
};

const Company = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.view_account.company' });
  const editAccountModal = useModalVisibility(false);
  return (
    <>
      {editAccountModal.visible && <EditAccountForm modal={editAccountModal} />}
      <GCard
        title={t('header')}
        extra={[
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => editAccountModal?.show()}
          >
            {t('edit')}
          </Button>,
        ]}
      >
        <GDescriptions<DataItem>
          column={colProps}
          layout="vertical"
          colon={false}
          dataSource={records}
          columns={columns({ t })}
        />
      </GCard>
    </>
  );
};

export default Company;
