import { Button, Typography } from 'antd';
import { GCard } from 'components';
import { useModalVisibility } from 'hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AddSourceview } from '../../AddSourceview';
import EditSourceviewForm from '../../AddSourceview/EditSourceview';
import PlanTable from '../../PlanTable';
import { DataType } from '../../PlanTable/typings';
import styles from './index.module.less';

const SourceviewEmptyState = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.view_account.sourceview' });
  const addSourceviewModal = useModalVisibility(false);
  return (
    <>
      {addSourceviewModal.visible && <AddSourceview modal={addSourceviewModal} />}
      <GCard
        title={t('header')}
        className={styles.card}
        extra={[
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => addSourceviewModal?.show()}
          >
            {t('add')}
          </Button>,
        ]}
      >
        <div className={styles.empty}>
          <Typography.Text className={styles.emptytext}>{t('empty_text')}</Typography.Text>
        </div>
      </GCard>
    </>
  );
};

const Sourceview = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.view_account.sourceview' });
  const editSourceviewModal = useModalVisibility(false);

  const records: DataType[] = [
    {
      id: 1,
      title: t('row.number_of_profiles'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 2,
      title: t('row.dynamic_qr_codes'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 3,
      title: t('row.collections'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 4,
      title: t('row.brand_settings'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 5,
      title: t('row.multiple_brands'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 6,
      title: t('row.dynamic_profile'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 7,
      title: t('row.custom_domain'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 8,
      title: t('row.dedicated_onboarding'),
      current: '33',
      plan: '88',
      custom: '5',
    },
  ];

  const onSaveAttribute = useCallback(async (data: DataType) => {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ file: Subscription.tsx:108 ~ data:', data);
  }, []);

  return records?.length ? (
    <>
      {editSourceviewModal.visible && <EditSourceviewForm modal={editSourceviewModal} />}
      <GCard
        title={t('header')}
        extra={[
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => editSourceviewModal?.show()}
          >
            {t('edit')}
          </Button>,
        ]}
      >
        <PlanTable
          records={records}
          title={t('sub_header')}
          subTitle={t('basic')}
          onSave={onSaveAttribute}
        />
      </GCard>
    </>
  ) : (
    <SourceviewEmptyState />
  );
};

export default Sourceview;
