import { Button } from 'antd';
import { GCard, GDescriptions } from 'components';
import { useModalVisibility } from 'hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BillingHistory from '../../BillingHistory/BillingHistory';
import EditBillingForm from '../../EditBilling';
import columns, { DataItem } from './Billing.fields';

const colProps8 = { xs: 8, sm: 8, md: 16, lg: 24 };

const records: DataItem = {
  totalSubscriptionPrice: '22',
  nextPaymentDue: '22',
  billingType: '22',
  nextInvoice: '22',
  paymentMethod: '22',
  lastPayment: '22',
};

const Billing = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.view_account.billing' });
  const editBillingModal = useModalVisibility(false);
  const viewBillingHistory = useModalVisibility(false);

  const onViewHistory = useCallback(() => viewBillingHistory?.show(), [viewBillingHistory]);

  return (
    <>
      {editBillingModal.visible && <EditBillingForm modal={editBillingModal} />}
      {viewBillingHistory.visible && <BillingHistory modal={viewBillingHistory} />}
      <GCard
        title={t('header')}
        extra={[
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => editBillingModal?.show()}
          >
            {t('edit')}
          </Button>,
        ]}
      >
        <GDescriptions<DataItem>
          column={colProps8}
          layout="vertical"
          colon={false}
          dataSource={records}
          columns={columns({ t })}
        />
        <Button type="link" className="primarycolor" onClick={onViewHistory}>
          {t?.('view_billing_history')}
        </Button>
      </GCard>
    </>
  );
};

export default Billing;
