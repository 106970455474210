import { ThemeConfig } from 'antd/lib/config-provider/context';
import Colors from './colors';

const theme: ThemeConfig = {
  token: {
    colorPrimary: Colors.primaryOpacity,
    colorPrimaryHover: Colors.primaryOpacity,
  },
  components: {
    Layout: {
      colorBgBody: Colors.primary,
      colorBgHeader: Colors.primary,
      colorBgLayout: Colors.primary,
      colorBgBase: Colors.primary,
      colorPrimary: Colors.primary,
      // change sidebar color
    },
    Button: {
      borderRadius: 10,
      colorPrimary: Colors.primary,
      colorPrimaryHover: Colors.primaryOpacity,
    },
    Popconfirm: {
      borderRadius: 10,
    },
    Popover: {
      borderRadius: 10,
    },
    Tooltip: {
      borderRadius: 10,
    },
    Menu: {
      borderRadius: 10,
    },
    Dropdown: {
      borderRadius: 10,
    },
    Modal: {
      borderRadius: 10,
    },
    Input: {
      borderRadius: 10,
      colorPrimaryHover: Colors.primary,
    },
    Select: {
      borderRadius: 10,
      colorPrimaryHover: Colors.primary,
    },
  },
};
export default theme;
