import * as coreClient from "@azure/core-client";
import * as coreRestPipeline from "@azure/core-rest-pipeline";
import * as coreAuth from "@azure/core-auth";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  WebAPIClientOptionalParams,
  CreateAccountOptionalParams,
  CreateAccountOperationResponse,
  GetAccountOptionalParams,
  GetAccountOperationResponse,
  UpdateAccountOptionalParams,
  UpdateAccountOperationResponse,
  UpdateSubscriptionOptionalParams,
  UpdateSubscriptionResponse,
  GetSubscriptionOptionalParams,
  GetSubscriptionOperationResponse,
  FixMissingSubsOptionalParams,
  FixMissingSubsResponse,
  GetAccountByIdOptionalParams,
  GetAccountByIdOperationResponse,
  CreateNetworkInvitationOptionalParams,
  CreateNetworkInvitationOperationResponse,
  ListNetworkInvitationsOptionalParams,
  ListNetworkInvitationsOperationResponse,
  ListNetworkOptionalParams,
  ListNetworkOperationResponse,
  ListAccountsOptionalParams,
  ListAccountsResponse,
  AddAccountToUserOptionalParams,
  AddAccountToUserOperationResponse,
  PinUserAccountOptionalParams,
  HasActiveInventoryOptionalParams,
  HasActiveInventoryOperationResponse,
  AcceptNetworkInvitationOptionalParams,
  AcceptNetworkInvitationResponse,
  RejectNetworkInvitationOptionalParams,
  RejectNetworkInvitationResponse,
  ResendNetworkInvitationOptionalParams,
  ResendNetworkInvitationResponse,
  CancelNetworkInvitationOptionalParams,
  CancelNetworkInvitationResponse,
  RevokeNetworkInvitationOptionalParams,
  RevokeNetworkInvitationResponse,
  ListBizstepsOptionalParams,
  ListBizstepsResponse,
  ListDispositionsOptionalParams,
  ListDispositionsResponse,
  GetDocumentByIdOptionalParams,
  GetDocumentByIdResponse,
  UpdateDocumentOptionalParams,
  UpdateDocumentResponse,
  DownloadDocumentOptionalParams,
  DownloadDocumentResponse,
  ListDocumentsOptionalParams,
  ListDocumentsOperationResponse,
  CreateDocumentOptionalParams,
  CreateDocumentResponse,
  SendExpiringDocumentNotificationsOptionalParams,
  ShareDocumentOptionalParams,
  ShareDocumentOperationResponse,
  UnshareDocumentOptionalParams,
  UnshareDocumentResponse,
  CreateEpcisDataOptionalParams,
  CreateEpcisDataResponse,
  GetEventTemplateByIdOptionalParams,
  GetEventTemplateByIdResponse,
  UpdateEventTemplateOptionalParams,
  UpdateEventTemplateResponse,
  DeleteEventTemplateOptionalParams,
  DeleteEventTemplateResponse,
  GetEventTemplatesOptionalParams,
  GetEventTemplatesOperationResponse,
  CreateEventTemplateOptionalParams,
  CreateEventTemplateResponse,
  ShareEventTemplateOptionalParams,
  ShareEventTemplateResponse,
  UnshareEventTemplateOptionalParams,
  UnshareEventTemplateResponse,
  GetBlockchainStatusByEventIdOptionalParams,
  GetBlockchainStatusByEventIdResponse,
  DeleteEventOptionalParams,
  DeleteEventResponse,
  GetEventByIdOptionalParams,
  GetEventByIdResponse,
  GetEventsOptionalParams,
  GetEventsResponse,
  ResolveOrphanOptionalParams,
  ResolveOrphanResponse,
  GetAllFirstEventsOfOrphansSupplyChainsOptionalParams,
  GetAllFirstEventsOfOrphansSupplyChainsOperationResponse,
  ListEventDetailsOptionalParams,
  ListEventDetailsOperationResponse,
  GetEventDetailsDiagramOptionalParams,
  GetEventDetailsDiagramResponse,
  GetEventDetailsMapOptionalParams,
  GetEventDetailsMapResponse,
  AggregateOptionalParams,
  AggregateResponse,
  DisaggregateOptionalParams,
  DisaggregateResponse,
  CommissionOptionalParams,
  CommissionResponse,
  MultiCommissionOptionalParams,
  MultiCommissionResponse,
  DecommissionOptionalParams,
  DecommissionResponse,
  ObserveOptionalParams,
  ObserveResponse,
  ReceiveOptionalParams,
  ReceiveResponse,
  MultiObserveOptionalParams,
  MultiObserveResponse,
  ShipOptionalParams,
  ShipResponse,
  MultiShipOptionalParams,
  MultiShipResponse,
  MultiReceiveOptionalParams,
  MultiReceiveResponse,
  TransformOptionalParams,
  TransformResponse,
  BatchShipOptionalParams,
  BatchShipResponse,
  GetBatchShipEventsOptionalParams,
  GetBatchShipEventsResponse,
  GetBatchShipEventByIdOptionalParams,
  GetBatchShipEventByIdResponse,
  UpdateBatchShipEventOptionalParams,
  UpdateBatchShipEventResponse,
  GetDashboardEventsOptionalParams,
  GetDashboardEventsOperationResponse,
  SimpleEventQueryOptionalParams,
  SimpleEventQueryOperationResponse,
  GetEventOptionalParams,
  GetEventResponse,
  GetEventNodeOptionalParams,
  GetEventNodeResponse,
  GetEventBatchOptionalParams,
  GetEventBatchResponse,
  UpdateEventBatchStatusOptionalParams,
  UpdateEventBatchStatusResponse,
  GetEventBatchStatusOptionalParams,
  GetEventBatchStatusResponse,
  CreateIntegrationDataOptionalParams,
  CreateIntegrationDataResponse,
  CreateLanguageOptionalParams,
  CreateLanguageResponse,
  ListLanguagesOptionalParams,
  ListLanguagesOperationResponse,
  DeleteLanguageOptionalParams,
  DeleteLanguageResponse,
  CreateAttributeKeyOptionalParams,
  CreateAttributeKeyResponse,
  GetAttributeValueOptionalParams,
  GetAttributeValueOperationResponse,
  UpdateAttributeKeysOptionalParams,
  UpdateAttributeKeysResponse,
  DeleteAttributeKeyOptionalParams,
  DeleteAttributeKeyResponse,
  CreateAttributeKeyBulkOptionalParams,
  CreateAttributeKeyBulkResponse,
  GetAttributeKeysOptionalParams,
  GetAttributeKeysResponse,
  GetAllAttributeKeysOptionalParams,
  GetAllAttributeKeysResponse,
  BulkUpdateAttributeKeysOptionalParams,
  BulkUpdateAttributeKeysResponse,
  GetLocationByIdOptionalParams,
  GetLocationByIdResponse,
  UpdateLocationOptionalParams,
  UpdateLocationOperationResponse,
  GetLocationsOptionalParams,
  GetLocationsResponse,
  CreateLocationOptionalParams,
  CreateLocationResponse,
  GetLocationByURNOptionalParams,
  GetLocationByURNResponse,
  GetLocationNetworkDetailsOptionalParams,
  GetLocationNetworkDetailsResponse,
  ArchiveLocationOptionalParams,
  ArchiveLocationOperationResponse,
  UnarchiveLocationOptionalParams,
  UnarchiveLocationOperationResponse,
  ShareLocationOptionalParams,
  ShareLocationOperationResponse,
  MasterDataPglnOptionalParams,
  MasterDataPglnResponse,
  MasterDataGLNOptionalParams,
  MasterDataGLNResponse,
  MasterDataGtinOptionalParams,
  MasterDataGtinResponse,
  MasterDataGtinLotSerialOptionalParams,
  MasterDataGtinLotSerialResponse,
  GetNamespacesOptionalParams,
  GetNamespacesResponse,
  CreateNamespaceOptionalParams,
  CreateNamespaceResponse,
  UpdateNamespaceOptionalParams,
  UpdateNamespaceResponse,
  DeleteNamespaceOptionalParams,
  DeleteNamespaceResponse,
  ListUserNotificationsOptionalParams,
  ListUserNotificationsOperationResponse,
  ReadNotificationsOptionalParams,
  SendSupportEmailOptionalParams,
  SendSupportEmailResponse,
  GetProductInstanceQuantitiesOptionalParams,
  GetProductInstanceQuantitiesResponse,
  GetProductsInstanceQuantitiesOptionalParams,
  GetProductsInstanceQuantitiesResponse,
  GetProductInstancesInContainerOptionalParams,
  GetProductInstancesInContainerResponse,
  GetProductInstanceOptionalParams,
  GetProductInstanceResponse,
  ListProductInstancesOptionalParams,
  ListProductInstancesResponse,
  ListProductInstancesByEventOptionalParams,
  ListProductInstancesByEventResponse,
  GetProductByURNOptionalParams,
  GetProductByURNResponse,
  GetProductByIdOptionalParams,
  GetProductByIdResponse,
  GetSourceProductByIdOptionalParams,
  GetSourceProductByIdResponse,
  GetProductsOptionalParams,
  GetProductsResponse,
  CreateProductOptionalParams,
  CreateProductResponse,
  UpdateProductOptionalParams,
  UpdateProductOperationResponse,
  GetAssociatedProductsOptionalParams,
  GetAssociatedProductsResponse,
  LinkAssociatedProductOptionalParams,
  LinkAssociatedProductResponse,
  UnlinkAssociatedProductOptionalParams,
  UnlinkAssociatedProductResponse,
  UpdateProductInventoryOptionalParams,
  ListPossibleSubProductsOptionalParams,
  ListPossibleSubProductsOperationResponse,
  AddSubProductsOptionalParams,
  AddSubProductsOperationResponse,
  RemoveSubProductOptionalParams,
  RemoveSubProductOperationResponse,
  GetNamesAndIdsOptionalParams,
  GetNamesAndIdsResponse,
  ListShipmentsOptionalParams,
  ListShipmentsOperationResponse,
  ShipmentSummaryOptionalParams,
  ShipmentSummaryOperationResponse,
  RejectShipmentOptionalParams,
  RejectShipmentResponse,
  CreateExternalShipmentOptionalParams,
  CreateExternalShipmentOperationResponse,
  GetExternalRegistryDetailsOptionalParams,
  GetExternalRegistryDetailsOperationResponse,
  SetExternalRegistryDetailsOptionalParams,
  SetExternalRegistryDetailsOperationResponse,
  UpdateExternalRegistryDetailsOptionalParams,
  UpdateExternalRegistryDetailsOperationResponse,
  SetOutboundApiKeyOptionalParams,
  SetOutboundApiKeyOperationResponse,
  QueryDigitalLinkOptionalParams,
  QueryDigitalLinkResponse,
  CsvProcessingNegotiateOptionalParams,
  GetTemplateAttributeByIdOptionalParams,
  GetTemplateAttributeByIdResponse,
  UpdateTemplateAttributeOptionalParams,
  UpdateTemplateAttributeResponse,
  DeleteTemplateAttributeOptionalParams,
  DeleteTemplateAttributeResponse,
  GetTemplateAttributesOptionalParams,
  GetTemplateAttributesResponse,
  CreateTemplateAttributeOptionalParams,
  CreateTemplateAttributeResponse,
  GetDefaultTemplateAttributesOptionalParams,
  GetDefaultTemplateAttributesResponse,
  GetTradePartnerByIdOptionalParams,
  GetTradePartnerByIdResponse,
  UpdateTradePartnerOptionalParams,
  UpdateTradePartnerResponse,
  DeleteTradePartnerOptionalParams,
  GetTradePartnerByURNOptionalParams,
  GetTradePartnerByURNResponse,
  GetTradePartnersOptionalParams,
  GetTradePartnersResponse,
  CreateTradePartnerOptionalParams,
  CreateTradePartnerResponse,
  GetNetworkDetailsOptionalParams,
  GetNetworkDetailsResponse,
  GetUserByIdOptionalParams,
  GetUserByIdOperationResponse,
  UpdateUserOptionalParams,
  UpdateUserOperationResponse,
  DeleteUserOptionalParams,
  ListUsersOptionalParams,
  ListUsersOperationResponse,
  UploadProfilePictureOptionalParams,
  UploadProfilePictureOperationResponse,
  ChangeAccountOptionalParams,
  ChangeAccountResponse,
  InviteUserOptionalParams,
  InviteUserOperationResponse,
  RedeemInviteOptionalParams,
  RedeemInviteOperationResponse,
  DeleteInviteOptionalParams,
  DeleteInviteOperationResponse,
  GetInviteByIdOptionalParams,
  GetInviteByIdResponse,
  SetSiteAdminOptionalParams,
  AcceptTermsAndConditionsOptionalParams,
  ResetTermsAndConditionsOptionalParams,
  CreateWorkflowTemplateOptionalParams,
  CreateWorkflowTemplateOperationResponse,
  ListWorkflowTemplatesOptionalParams,
  ListWorkflowTemplatesOperationResponse,
  GetWorkflowTemplateByIdOptionalParams,
  GetWorkflowTemplateByIdOperationResponse,
  DeleteWorkflowTemplateOptionalParams,
  DeleteWorkflowTemplateOperationResponse,
  UpdateWorkflowTemplateOptionalParams,
  UpdateWorkflowTemplateOperationResponse,
  CreateWorkflowOptionalParams,
  CreateWorkflowOperationResponse,
  ListWorkflowsOptionalParams,
  ListWorkflowsOperationResponse,
  GetWorkflowByIdOptionalParams,
  GetWorkflowByIdOperationResponse,
  DeleteWorkflowOptionalParams,
  DeleteWorkflowOperationResponse,
  UpdateWorkflowOptionalParams,
  UpdateWorkflowOperationResponse
} from "./models";

export class WebAPIClient extends coreClient.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the WebAPIClient class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param $host server parameter
   * @param options The parameter options
   */
  constructor(
    credentials: coreAuth.TokenCredential,
    $host: string,
    options?: WebAPIClientOptionalParams
  ) {
    if (credentials === undefined) {
      throw new Error("'credentials' cannot be null");
    }
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: WebAPIClientOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
      credential: credentials
    };

    const packageDetails = `azsdk-js-WebAPIClient/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    if (!options.credentialScopes) {
      options.credentialScopes = [
        "https://wholechainusermigrationdev.onmicrosoft.com/df631b5d-e243-4944-a388-b6667c8fda08/.default"
      ];
    }
    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}"
    };
    super(optionsWithDefaults);

    let bearerTokenAuthenticationPolicyFound: boolean = false;
    if (options?.pipeline && options.pipeline.getOrderedPolicies().length > 0) {
      const pipelinePolicies: coreRestPipeline.PipelinePolicy[] = options.pipeline.getOrderedPolicies();
      bearerTokenAuthenticationPolicyFound = pipelinePolicies.some(
        (pipelinePolicy) =>
          pipelinePolicy.name ===
          coreRestPipeline.bearerTokenAuthenticationPolicyName
      );
    }
    if (
      !options ||
      !options.pipeline ||
      options.pipeline.getOrderedPolicies().length == 0 ||
      !bearerTokenAuthenticationPolicyFound
    ) {
      this.pipeline.removePolicy({
        name: coreRestPipeline.bearerTokenAuthenticationPolicyName
      });
      this.pipeline.addPolicy(
        coreRestPipeline.bearerTokenAuthenticationPolicy({
          credential: credentials,
          scopes:
            optionsWithDefaults.credentialScopes ??
            `${optionsWithDefaults.endpoint}/.default`,
          challengeCallbacks: {
            authorizeRequestOnChallenge:
              coreClient.authorizeRequestOnClaimChallenge
          }
        })
      );
    }
    // Parameter assignments
    this.$host = $host;
  }

  /**
   * Creates a new account
   * @param options The options parameters.
   */
  createAccount(
    options?: CreateAccountOptionalParams
  ): Promise<CreateAccountOperationResponse> {
    return this.sendOperationRequest({ options }, createAccountOperationSpec);
  }

  /**
   * Get the company account that matches the currently logged in user
   * @param options The options parameters.
   */
  getAccount(
    options?: GetAccountOptionalParams
  ): Promise<GetAccountOperationResponse> {
    return this.sendOperationRequest({ options }, getAccountOperationSpec);
  }

  /**
   * Update the account of which the currently logged in user is a member
   * @param options The options parameters.
   */
  updateAccount(
    options?: UpdateAccountOptionalParams
  ): Promise<UpdateAccountOperationResponse> {
    return this.sendOperationRequest({ options }, updateAccountOperationSpec);
  }

  /**
   * Requires admin role. Any properties not specified in the request will not be updated.
   * @param options The options parameters.
   */
  updateSubscription(
    options?: UpdateSubscriptionOptionalParams
  ): Promise<UpdateSubscriptionResponse> {
    return this.sendOperationRequest(
      { options },
      updateSubscriptionOperationSpec
    );
  }

  /**
   * Get the subscription for the current account
   * @param options The options parameters.
   */
  getSubscription(
    options?: GetSubscriptionOptionalParams
  ): Promise<GetSubscriptionOperationResponse> {
    return this.sendOperationRequest({ options }, getSubscriptionOperationSpec);
  }

  /**
   * Administrative method to fix any accounts that may be missing a subscription
   * @param options The options parameters.
   */
  fixMissingSubs(
    options?: FixMissingSubsOptionalParams
  ): Promise<FixMissingSubsResponse> {
    return this.sendOperationRequest({ options }, fixMissingSubsOperationSpec);
  }

  /**
   * Get the account based on the provided ID
   * @param id Id of the account
   * @param options The options parameters.
   */
  getAccountById(
    id: string,
    options?: GetAccountByIdOptionalParams
  ): Promise<GetAccountByIdOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      getAccountByIdOperationSpec
    );
  }

  /**
   * Create a new network invitation
   * @param options The options parameters.
   */
  createNetworkInvitation(
    options?: CreateNetworkInvitationOptionalParams
  ): Promise<CreateNetworkInvitationOperationResponse> {
    return this.sendOperationRequest(
      { options },
      createNetworkInvitationOperationSpec
    );
  }

  /**
   * Gets a list of all incoming/outgoing network invitations
   * @param options The options parameters.
   */
  listNetworkInvitations(
    options?: ListNetworkInvitationsOptionalParams
  ): Promise<ListNetworkInvitationsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listNetworkInvitationsOperationSpec
    );
  }

  /**
   * Gets a network invitation based on the filters provided
   * @param options The options parameters.
   */
  listNetwork(
    options?: ListNetworkOptionalParams
  ): Promise<ListNetworkOperationResponse> {
    return this.sendOperationRequest({ options }, listNetworkOperationSpec);
  }

  /**
   * List all accounts for the user
   * @param options The options parameters.
   */
  listAccounts(
    options?: ListAccountsOptionalParams
  ): Promise<ListAccountsResponse> {
    return this.sendOperationRequest({ options }, listAccountsOperationSpec);
  }

  /**
   * List all accounts for the user
   * @param options The options parameters.
   */
  addAccountToUser(
    options?: AddAccountToUserOptionalParams
  ): Promise<AddAccountToUserOperationResponse> {
    return this.sendOperationRequest(
      { options },
      addAccountToUserOperationSpec
    );
  }

  /**
   * Pin/unpin user account
   * @param options The options parameters.
   */
  pinUserAccount(options?: PinUserAccountOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, pinUserAccountOperationSpec);
  }

  /**
   * Returns true if the account has at least one location with active inventory, filtered by vessel or
   * not
   * @param options The options parameters.
   */
  hasActiveInventory(
    options?: HasActiveInventoryOptionalParams
  ): Promise<HasActiveInventoryOperationResponse> {
    return this.sendOperationRequest(
      { options },
      hasActiveInventoryOperationSpec
    );
  }

  /**
   * Accepts the network invite
   * @param id The invitation id to accept
   * @param options The options parameters.
   */
  acceptNetworkInvitation(
    id: string,
    options?: AcceptNetworkInvitationOptionalParams
  ): Promise<AcceptNetworkInvitationResponse> {
    return this.sendOperationRequest(
      { id, options },
      acceptNetworkInvitationOperationSpec
    );
  }

  /**
   * Rejects the network invite
   * @param id The invitation id to reject
   * @param options The options parameters.
   */
  rejectNetworkInvitation(
    id: string,
    options?: RejectNetworkInvitationOptionalParams
  ): Promise<RejectNetworkInvitationResponse> {
    return this.sendOperationRequest(
      { id, options },
      rejectNetworkInvitationOperationSpec
    );
  }

  /**
   * Resends the network invite
   * @param id The invitation id to resend
   * @param options The options parameters.
   */
  resendNetworkInvitation(
    id: string,
    options?: ResendNetworkInvitationOptionalParams
  ): Promise<ResendNetworkInvitationResponse> {
    return this.sendOperationRequest(
      { id, options },
      resendNetworkInvitationOperationSpec
    );
  }

  /**
   * Cancels the network invite
   * @param id The invitation id to cancel
   * @param options The options parameters.
   */
  cancelNetworkInvitation(
    id: string,
    options?: CancelNetworkInvitationOptionalParams
  ): Promise<CancelNetworkInvitationResponse> {
    return this.sendOperationRequest(
      { id, options },
      cancelNetworkInvitationOperationSpec
    );
  }

  /**
   * Revoke the network connection
   * @param id The invitation id to cancel
   * @param options The options parameters.
   */
  revokeNetworkInvitation(
    id: string,
    options?: RevokeNetworkInvitationOptionalParams
  ): Promise<RevokeNetworkInvitationResponse> {
    return this.sendOperationRequest(
      { id, options },
      revokeNetworkInvitationOperationSpec
    );
  }

  /**
   * Get a list of the business steps
   * @param options The options parameters.
   */
  listBizsteps(
    options?: ListBizstepsOptionalParams
  ): Promise<ListBizstepsResponse> {
    return this.sendOperationRequest({ options }, listBizstepsOperationSpec);
  }

  /**
   * Get a list of the dispositions
   * @param options The options parameters.
   */
  listDispositions(
    options?: ListDispositionsOptionalParams
  ): Promise<ListDispositionsResponse> {
    return this.sendOperationRequest(
      { options },
      listDispositionsOperationSpec
    );
  }

  /**
   * Get the document based on the provided ID
   * @param id Id of the document
   * @param options The options parameters.
   */
  getDocumentById(
    id: string,
    options?: GetDocumentByIdOptionalParams
  ): Promise<GetDocumentByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getDocumentByIdOperationSpec
    );
  }

  /**
   * Updates a document
   * @param id Id of the document to be updated
   * @param options The options parameters.
   */
  updateDocument(
    id: string,
    options?: UpdateDocumentOptionalParams
  ): Promise<UpdateDocumentResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateDocumentOperationSpec
    );
  }

  /**
   * Downloads the document based on the provided ID
   * @param id Id of the document
   * @param options The options parameters.
   */
  downloadDocument(
    id: string,
    options?: DownloadDocumentOptionalParams
  ): Promise<DownloadDocumentResponse> {
    return this.sendOperationRequest(
      { id, options },
      downloadDocumentOperationSpec
    );
  }

  /**
   * List the documents based on the provided filters
   * @param options The options parameters.
   */
  listDocuments(
    options?: ListDocumentsOptionalParams
  ): Promise<ListDocumentsOperationResponse> {
    return this.sendOperationRequest({ options }, listDocumentsOperationSpec);
  }

  /**
   * Creates a new document
   * @param options The options parameters.
   */
  createDocument(
    options?: CreateDocumentOptionalParams
  ): Promise<CreateDocumentResponse> {
    return this.sendOperationRequest({ options }, createDocumentOperationSpec);
  }

  /**
   * Creates the expiring notifications
   * @param options The options parameters.
   */
  sendExpiringDocumentNotifications(
    options?: SendExpiringDocumentNotificationsOptionalParams
  ): Promise<void> {
    return this.sendOperationRequest(
      { options },
      sendExpiringDocumentNotificationsOperationSpec
    );
  }

  /**
   * Shares one or more documents
   * @param options The options parameters.
   */
  shareDocument(
    options?: ShareDocumentOptionalParams
  ): Promise<ShareDocumentOperationResponse> {
    return this.sendOperationRequest({ options }, shareDocumentOperationSpec);
  }

  /**
   * Unshares a document
   * @param documentId Id of the document to be updated
   * @param options The options parameters.
   */
  unshareDocument(
    documentId: string,
    options?: UnshareDocumentOptionalParams
  ): Promise<UnshareDocumentResponse> {
    return this.sendOperationRequest(
      { documentId, options },
      unshareDocumentOperationSpec
    );
  }

  /** @param options The options parameters. */
  createEpcisData(
    options?: CreateEpcisDataOptionalParams
  ): Promise<CreateEpcisDataResponse> {
    return this.sendOperationRequest({ options }, createEpcisDataOperationSpec);
  }

  /**
   * Get an event template by its Id
   * @param id Event template Id
   * @param options The options parameters.
   */
  getEventTemplateById(
    id: string,
    options?: GetEventTemplateByIdOptionalParams
  ): Promise<GetEventTemplateByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventTemplateByIdOperationSpec
    );
  }

  /**
   * Update the name or template body for an event template
   * @param id The ID of the template
   * @param options The options parameters.
   */
  updateEventTemplate(
    id: string,
    options?: UpdateEventTemplateOptionalParams
  ): Promise<UpdateEventTemplateResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateEventTemplateOperationSpec
    );
  }

  /**
   * Delete an event template
   * @param id The id of the template to delete
   * @param options The options parameters.
   */
  deleteEventTemplate(
    id: string,
    options?: DeleteEventTemplateOptionalParams
  ): Promise<DeleteEventTemplateResponse> {
    return this.sendOperationRequest(
      { id, options },
      deleteEventTemplateOperationSpec
    );
  }

  /**
   * Get a list of all event templates for the current account
   * @param options The options parameters.
   */
  getEventTemplates(
    options?: GetEventTemplatesOptionalParams
  ): Promise<GetEventTemplatesOperationResponse> {
    return this.sendOperationRequest(
      { options },
      getEventTemplatesOperationSpec
    );
  }

  /**
   * Add a new event template
   * @param options The options parameters.
   */
  createEventTemplate(
    options?: CreateEventTemplateOptionalParams
  ): Promise<CreateEventTemplateResponse> {
    return this.sendOperationRequest(
      { options },
      createEventTemplateOperationSpec
    );
  }

  /**
   * Share an event template
   * @param templateId The event template id to be shared
   * @param options The options parameters.
   */
  shareEventTemplate(
    templateId: string,
    options?: ShareEventTemplateOptionalParams
  ): Promise<ShareEventTemplateResponse> {
    return this.sendOperationRequest(
      { templateId, options },
      shareEventTemplateOperationSpec
    );
  }

  /**
   * Unshare an event template
   * @param templateId The event template id to be unshared
   * @param options The options parameters.
   */
  unshareEventTemplate(
    templateId: string,
    options?: UnshareEventTemplateOptionalParams
  ): Promise<UnshareEventTemplateResponse> {
    return this.sendOperationRequest(
      { templateId, options },
      unshareEventTemplateOperationSpec
    );
  }

  /**
   * Get the blockchain transaction information by event id
   * @param id The ID of the event
   * @param options The options parameters.
   */
  getBlockchainStatusByEventId(
    id: string,
    options?: GetBlockchainStatusByEventIdOptionalParams
  ): Promise<GetBlockchainStatusByEventIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getBlockchainStatusByEventIdOperationSpec
    );
  }

  /**
   * Delete an event. Currently site admins only.
   * @param eventId the event ID to delete in the current account
   * @param options The options parameters.
   */
  deleteEvent(
    eventId: string,
    options?: DeleteEventOptionalParams
  ): Promise<DeleteEventResponse> {
    return this.sendOperationRequest(
      { eventId, options },
      deleteEventOperationSpec
    );
  }

  /**
   * Get an event based on its ID
   * @param id The ID of the event
   * @param options The options parameters.
   */
  getEventById(
    id: string,
    options?: GetEventByIdOptionalParams
  ): Promise<GetEventByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventByIdOperationSpec
    );
  }

  /**
   * Return a list of events that match a given URN, or set of URNs.
   * @param options The options parameters.
   */
  getEvents(options?: GetEventsOptionalParams): Promise<GetEventsResponse> {
    return this.sendOperationRequest({ options }, getEventsOperationSpec);
  }

  /**
   * Connects an orphan to its respective supply chain
   * @param eventId The eventId of the first event on the orphan supply chain
   * @param options The options parameters.
   */
  resolveOrphan(
    eventId: string,
    options?: ResolveOrphanOptionalParams
  ): Promise<ResolveOrphanResponse> {
    return this.sendOperationRequest(
      { eventId, options },
      resolveOrphanOperationSpec
    );
  }

  /**
   * Return a list of the first events of each orphan supply chain of the account.
   * @param options The options parameters.
   */
  getAllFirstEventsOfOrphansSupplyChains(
    options?: GetAllFirstEventsOfOrphansSupplyChainsOptionalParams
  ): Promise<GetAllFirstEventsOfOrphansSupplyChainsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      getAllFirstEventsOfOrphansSupplyChainsOperationSpec
    );
  }

  /**
   * Return a list of events details for the whole supply chain
   * @param options The options parameters.
   */
  listEventDetails(
    options?: ListEventDetailsOptionalParams
  ): Promise<ListEventDetailsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listEventDetailsOperationSpec
    );
  }

  /**
   * Return the event diagram by event Id
   * @param id Event Id
   * @param options The options parameters.
   */
  getEventDetailsDiagram(
    id: string,
    options?: GetEventDetailsDiagramOptionalParams
  ): Promise<GetEventDetailsDiagramResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventDetailsDiagramOperationSpec
    );
  }

  /**
   * Return the event details map by Id and LotSerial
   * @param id Event Id
   * @param options The options parameters.
   */
  getEventDetailsMap(
    id: string,
    options?: GetEventDetailsMapOptionalParams
  ): Promise<GetEventDetailsMapResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventDetailsMapOperationSpec
    );
  }

  /**
   * Create an aggregation event.
   * @param options The options parameters.
   */
  aggregate(options?: AggregateOptionalParams): Promise<AggregateResponse> {
    return this.sendOperationRequest({ options }, aggregateOperationSpec);
  }

  /**
   * Create an disaggregation event.
   * @param options The options parameters.
   */
  disaggregate(
    options?: DisaggregateOptionalParams
  ): Promise<DisaggregateResponse> {
    return this.sendOperationRequest({ options }, disaggregateOperationSpec);
  }

  /**
   * Create an commission event.
   * @param options The options parameters.
   */
  commission(options?: CommissionOptionalParams): Promise<CommissionResponse> {
    return this.sendOperationRequest({ options }, commissionOperationSpec);
  }

  /**
   * Create multiple commission events.
   * @param options The options parameters.
   */
  multiCommission(
    options?: MultiCommissionOptionalParams
  ): Promise<MultiCommissionResponse> {
    return this.sendOperationRequest({ options }, multiCommissionOperationSpec);
  }

  /**
   * Create an decommission event.
   * @param options The options parameters.
   */
  decommission(
    options?: DecommissionOptionalParams
  ): Promise<DecommissionResponse> {
    return this.sendOperationRequest({ options }, decommissionOperationSpec);
  }

  /**
   * Create an observe event.
   * @param options The options parameters.
   */
  observe(options?: ObserveOptionalParams): Promise<ObserveResponse> {
    return this.sendOperationRequest({ options }, observeOperationSpec);
  }

  /**
   * Create an receive event.
   * @param options The options parameters.
   */
  receive(options?: ReceiveOptionalParams): Promise<ReceiveResponse> {
    return this.sendOperationRequest({ options }, receiveOperationSpec);
  }

  /**
   * Create multiple observe events.
   * @param options The options parameters.
   */
  multiObserve(
    options?: MultiObserveOptionalParams
  ): Promise<MultiObserveResponse> {
    return this.sendOperationRequest({ options }, multiObserveOperationSpec);
  }

  /**
   * Create an ship event.
   * @param options The options parameters.
   */
  ship(options?: ShipOptionalParams): Promise<ShipResponse> {
    return this.sendOperationRequest({ options }, shipOperationSpec);
  }

  /**
   * Create multiple ship events.
   * @param options The options parameters.
   */
  multiShip(options?: MultiShipOptionalParams): Promise<MultiShipResponse> {
    return this.sendOperationRequest({ options }, multiShipOperationSpec);
  }

  /**
   * Create multiple receive events.
   * @param options The options parameters.
   */
  multiReceive(
    options?: MultiReceiveOptionalParams
  ): Promise<MultiReceiveResponse> {
    return this.sendOperationRequest({ options }, multiReceiveOperationSpec);
  }

  /**
   * Create an transform event.
   * @param options The options parameters.
   */
  transform(options?: TransformOptionalParams): Promise<TransformResponse> {
    return this.sendOperationRequest({ options }, transformOperationSpec);
  }

  /**
   * Create a draft of a ship event
   * @param options The options parameters.
   */
  batchShip(options?: BatchShipOptionalParams): Promise<BatchShipResponse> {
    return this.sendOperationRequest({ options }, batchShipOperationSpec);
  }

  /**
   * Return a list of batch ship events for the account
   * @param options The options parameters.
   */
  getBatchShipEvents(
    options?: GetBatchShipEventsOptionalParams
  ): Promise<GetBatchShipEventsResponse> {
    return this.sendOperationRequest(
      { options },
      getBatchShipEventsOperationSpec
    );
  }

  /**
   * Get a batch ship event based on its ID
   * @param id The ID of the event
   * @param options The options parameters.
   */
  getBatchShipEventById(
    id: string,
    options?: GetBatchShipEventByIdOptionalParams
  ): Promise<GetBatchShipEventByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getBatchShipEventByIdOperationSpec
    );
  }

  /**
   * Update a batch ship event details
   * @param id The ID of the event to update
   * @param options The options parameters.
   */
  updateBatchShipEvent(
    id: string,
    options?: UpdateBatchShipEventOptionalParams
  ): Promise<UpdateBatchShipEventResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateBatchShipEventOperationSpec
    );
  }

  /**
   * Return a list of dashboard events for the current account
   * @param options The options parameters.
   */
  getDashboardEvents(
    options?: GetDashboardEventsOptionalParams
  ): Promise<GetDashboardEventsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      getDashboardEventsOperationSpec
    );
  }

  /**
   * Test
   * @param options The options parameters.
   */
  simpleEventQuery(
    options?: SimpleEventQueryOptionalParams
  ): Promise<SimpleEventQueryOperationResponse> {
    return this.sendOperationRequest(
      { options },
      simpleEventQueryOperationSpec
    );
  }

  /**
   * Return an event
   * @param options The options parameters.
   */
  getEvent(options?: GetEventOptionalParams): Promise<GetEventResponse> {
    return this.sendOperationRequest({ options }, getEventOperationSpec);
  }

  /**
   * Return an event node
   * @param id Event id
   * @param options The options parameters.
   */
  getEventNode(
    id: string,
    options?: GetEventNodeOptionalParams
  ): Promise<GetEventNodeResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventNodeOperationSpec
    );
  }

  /**
   * Return an event batch
   * @param id
   * @param options The options parameters.
   */
  getEventBatch(
    id: string,
    options?: GetEventBatchOptionalParams
  ): Promise<GetEventBatchResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventBatchOperationSpec
    );
  }

  /**
   * Update an event batch
   * @param id Event id
   * @param options The options parameters.
   */
  updateEventBatchStatus(
    id: string,
    options?: UpdateEventBatchStatusOptionalParams
  ): Promise<UpdateEventBatchStatusResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateEventBatchStatusOperationSpec
    );
  }

  /**
   * Return the status of an event batch
   * @param id
   * @param options The options parameters.
   */
  getEventBatchStatus(
    id: string,
    options?: GetEventBatchStatusOptionalParams
  ): Promise<GetEventBatchStatusResponse> {
    return this.sendOperationRequest(
      { id, options },
      getEventBatchStatusOperationSpec
    );
  }

  /** @param options The options parameters. */
  createIntegrationData(
    options?: CreateIntegrationDataOptionalParams
  ): Promise<CreateIntegrationDataResponse> {
    return this.sendOperationRequest(
      { options },
      createIntegrationDataOperationSpec
    );
  }

  /**
   * Creates a new language
   * @param options The options parameters.
   */
  createLanguage(
    options?: CreateLanguageOptionalParams
  ): Promise<CreateLanguageResponse> {
    return this.sendOperationRequest({ options }, createLanguageOperationSpec);
  }

  /**
   * Gets all languages
   * @param options The options parameters.
   */
  listLanguages(
    options?: ListLanguagesOptionalParams
  ): Promise<ListLanguagesOperationResponse> {
    return this.sendOperationRequest({ options }, listLanguagesOperationSpec);
  }

  /**
   * Delete a language
   * @param options The options parameters.
   */
  deleteLanguage(
    options?: DeleteLanguageOptionalParams
  ): Promise<DeleteLanguageResponse> {
    return this.sendOperationRequest({ options }, deleteLanguageOperationSpec);
  }

  /**
   * Adds a new attribute key
   * @param options The options parameters.
   */
  createAttributeKey(
    options?: CreateAttributeKeyOptionalParams
  ): Promise<CreateAttributeKeyResponse> {
    return this.sendOperationRequest(
      { options },
      createAttributeKeyOperationSpec
    );
  }

  /**
   * Gets a specific attribute key
   * @param options The options parameters.
   */
  getAttributeValue(
    options?: GetAttributeValueOptionalParams
  ): Promise<GetAttributeValueOperationResponse> {
    return this.sendOperationRequest(
      { options },
      getAttributeValueOperationSpec
    );
  }

  /**
   * Updates attribute keys
   * @param options The options parameters.
   */
  updateAttributeKeys(
    options?: UpdateAttributeKeysOptionalParams
  ): Promise<UpdateAttributeKeysResponse> {
    return this.sendOperationRequest(
      { options },
      updateAttributeKeysOperationSpec
    );
  }

  /**
   * Delete an attribute key
   * @param options The options parameters.
   */
  deleteAttributeKey(
    options?: DeleteAttributeKeyOptionalParams
  ): Promise<DeleteAttributeKeyResponse> {
    return this.sendOperationRequest(
      { options },
      deleteAttributeKeyOperationSpec
    );
  }

  /**
   * Adds new attribute keys
   * @param options The options parameters.
   */
  createAttributeKeyBulk(
    options?: CreateAttributeKeyBulkOptionalParams
  ): Promise<CreateAttributeKeyBulkResponse> {
    return this.sendOperationRequest(
      { options },
      createAttributeKeyBulkOperationSpec
    );
  }

  /**
   * Gets all attribute keys for a language
   * @param options The options parameters.
   */
  getAttributeKeys(
    options?: GetAttributeKeysOptionalParams
  ): Promise<GetAttributeKeysResponse> {
    return this.sendOperationRequest(
      { options },
      getAttributeKeysOperationSpec
    );
  }

  /**
   * Gets all attribute keys
   * @param options The options parameters.
   */
  getAllAttributeKeys(
    options?: GetAllAttributeKeysOptionalParams
  ): Promise<GetAllAttributeKeysResponse> {
    return this.sendOperationRequest(
      { options },
      getAllAttributeKeysOperationSpec
    );
  }

  /**
   * Bulk updates attribute keys
   * @param options The options parameters.
   */
  bulkUpdateAttributeKeys(
    options?: BulkUpdateAttributeKeysOptionalParams
  ): Promise<BulkUpdateAttributeKeysResponse> {
    return this.sendOperationRequest(
      { options },
      bulkUpdateAttributeKeysOperationSpec
    );
  }

  /**
   * Get a location for the current user's account by location ID
   * @param id The ID of the location to return
   * @param options The options parameters.
   */
  getLocationById(
    id: string,
    options?: GetLocationByIdOptionalParams
  ): Promise<GetLocationByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getLocationByIdOperationSpec
    );
  }

  /**
   * Update a location on the current user's account
   * @param id Id of the location to be updated
   * @param options The options parameters.
   */
  updateLocation(
    id: string,
    options?: UpdateLocationOptionalParams
  ): Promise<UpdateLocationOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateLocationOperationSpec
    );
  }

  /**
   * Get a list of locations for the current user's account based on the filters
   * @param options The options parameters.
   */
  getLocations(
    options?: GetLocationsOptionalParams
  ): Promise<GetLocationsResponse> {
    return this.sendOperationRequest({ options }, getLocationsOperationSpec);
  }

  /**
   * Create a new location on the current user's account
   * @param options The options parameters.
   */
  createLocation(
    options?: CreateLocationOptionalParams
  ): Promise<CreateLocationResponse> {
    return this.sendOperationRequest({ options }, createLocationOperationSpec);
  }

  /**
   * Get a location by its URN
   * @param options The options parameters.
   */
  getLocationByURN(
    options?: GetLocationByURNOptionalParams
  ): Promise<GetLocationByURNResponse> {
    return this.sendOperationRequest(
      { options },
      getLocationByURNOperationSpec
    );
  }

  /**
   * Get the details of a location by ID
   * @param id The ID of the location to return
   * @param options The options parameters.
   */
  getLocationNetworkDetails(
    id: string,
    options?: GetLocationNetworkDetailsOptionalParams
  ): Promise<GetLocationNetworkDetailsResponse> {
    return this.sendOperationRequest(
      { id, options },
      getLocationNetworkDetailsOperationSpec
    );
  }

  /**
   * Archive locations
   * @param options The options parameters.
   */
  archiveLocation(
    options?: ArchiveLocationOptionalParams
  ): Promise<ArchiveLocationOperationResponse> {
    return this.sendOperationRequest({ options }, archiveLocationOperationSpec);
  }

  /**
   * Unarchive locations
   * @param options The options parameters.
   */
  unarchiveLocation(
    options?: UnarchiveLocationOptionalParams
  ): Promise<UnarchiveLocationOperationResponse> {
    return this.sendOperationRequest(
      { options },
      unarchiveLocationOperationSpec
    );
  }

  /**
   * Shares location with a networked trade partner
   * @param options The options parameters.
   */
  shareLocation(
    options?: ShareLocationOptionalParams
  ): Promise<ShareLocationOperationResponse> {
    return this.sendOperationRequest({ options }, shareLocationOperationSpec);
  }

  /**
   * @param pgln
   * @param options The options parameters.
   */
  masterDataPgln(
    pgln: string,
    options?: MasterDataPglnOptionalParams
  ): Promise<MasterDataPglnResponse> {
    return this.sendOperationRequest(
      { pgln, options },
      masterDataPglnOperationSpec
    );
  }

  /**
   * @param gln
   * @param options The options parameters.
   */
  masterDataGLN(
    gln: string,
    options?: MasterDataGLNOptionalParams
  ): Promise<MasterDataGLNResponse> {
    return this.sendOperationRequest(
      { gln, options },
      masterDataGLNOperationSpec
    );
  }

  /**
   * @param gtin
   * @param options The options parameters.
   */
  masterDataGtin(
    gtin: string,
    options?: MasterDataGtinOptionalParams
  ): Promise<MasterDataGtinResponse> {
    return this.sendOperationRequest(
      { gtin, options },
      masterDataGtinOperationSpec
    );
  }

  /**
   * @param gtin
   * @param lotSerial
   * @param options The options parameters.
   */
  masterDataGtinLotSerial(
    gtin: string,
    lotSerial: string,
    options?: MasterDataGtinLotSerialOptionalParams
  ): Promise<MasterDataGtinLotSerialResponse> {
    return this.sendOperationRequest(
      { gtin, lotSerial, options },
      masterDataGtinLotSerialOperationSpec
    );
  }

  /**
   * Get a list of namespaces defined on the current user's account
   * @param options The options parameters.
   */
  getNamespaces(
    options?: GetNamespacesOptionalParams
  ): Promise<GetNamespacesResponse> {
    return this.sendOperationRequest({ options }, getNamespacesOperationSpec);
  }

  /**
   * Add a new namespace to the current user's account
   * @param options The options parameters.
   */
  createNamespace(
    options?: CreateNamespaceOptionalParams
  ): Promise<CreateNamespaceResponse> {
    return this.sendOperationRequest({ options }, createNamespaceOperationSpec);
  }

  /**
   * Update the URI for an existing namespace
   * @param options The options parameters.
   */
  updateNamespace(
    options?: UpdateNamespaceOptionalParams
  ): Promise<UpdateNamespaceResponse> {
    return this.sendOperationRequest({ options }, updateNamespaceOperationSpec);
  }

  /**
   * Delete an existing namespace
   * @param prefix The prefix of the namespace to delete
   * @param options The options parameters.
   */
  deleteNamespace(
    prefix: string,
    options?: DeleteNamespaceOptionalParams
  ): Promise<DeleteNamespaceResponse> {
    return this.sendOperationRequest(
      { prefix, options },
      deleteNamespaceOperationSpec
    );
  }

  /**
   * Get a list of notifications for the current user
   * @param options The options parameters.
   */
  listUserNotifications(
    options?: ListUserNotificationsOptionalParams
  ): Promise<ListUserNotificationsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listUserNotificationsOperationSpec
    );
  }

  /**
   * Update the status of the notification to read
   * @param options The options parameters.
   */
  readNotifications(options?: ReadNotificationsOptionalParams): Promise<void> {
    return this.sendOperationRequest(
      { options },
      readNotificationsOperationSpec
    );
  }

  /** @param options The options parameters. */
  sendSupportEmail(
    options?: SendSupportEmailOptionalParams
  ): Promise<SendSupportEmailResponse> {
    return this.sendOperationRequest(
      { options },
      sendSupportEmailOperationSpec
    );
  }

  /**
   * history overrides isShipped if specified. history=true implies shipped or consumed,
   * history=false implies INTERNALLY shipped or not consumed.
   * @param parentProductId
   * @param options The options parameters.
   */
  getProductInstanceQuantities(
    parentProductId: string,
    options?: GetProductInstanceQuantitiesOptionalParams
  ): Promise<GetProductInstanceQuantitiesResponse> {
    return this.sendOperationRequest(
      { parentProductId, options },
      getProductInstanceQuantitiesOperationSpec
    );
  }

  /**
   * history overrides isShipped if specified. history=true implies shipped or consumed,
   * history=false implies INTERNALLY shipped or not consumed.
   * @param options The options parameters.
   */
  getProductsInstanceQuantities(
    options?: GetProductsInstanceQuantitiesOptionalParams
  ): Promise<GetProductsInstanceQuantitiesResponse> {
    return this.sendOperationRequest(
      { options },
      getProductsInstanceQuantitiesOperationSpec
    );
  }

  /**
   * Get all instances in a container
   * @param containerId The ID for the container
   * @param options The options parameters.
   */
  getProductInstancesInContainer(
    containerId: string,
    options?: GetProductInstancesInContainerOptionalParams
  ): Promise<GetProductInstancesInContainerResponse> {
    return this.sendOperationRequest(
      { containerId, options },
      getProductInstancesInContainerOperationSpec
    );
  }

  /**
   * Get a product instance by ID
   * @param id The ID of the instance to return
   * @param options The options parameters.
   */
  getProductInstance(
    id: string,
    options?: GetProductInstanceOptionalParams
  ): Promise<GetProductInstanceResponse> {
    return this.sendOperationRequest(
      { id, options },
      getProductInstanceOperationSpec
    );
  }

  /**
   * Get product instances by URN
   * @param options The options parameters.
   */
  listProductInstances(
    options?: ListProductInstancesOptionalParams
  ): Promise<ListProductInstancesResponse> {
    return this.sendOperationRequest(
      { options },
      listProductInstancesOperationSpec
    );
  }

  /**
   * Get product instances by event id
   * @param options The options parameters.
   */
  listProductInstancesByEvent(
    options?: ListProductInstancesByEventOptionalParams
  ): Promise<ListProductInstancesByEventResponse> {
    return this.sendOperationRequest(
      { options },
      listProductInstancesByEventOperationSpec
    );
  }

  /**
   * Get a product by its URN
   * @param options The options parameters.
   */
  getProductByURN(
    options?: GetProductByURNOptionalParams
  ): Promise<GetProductByURNResponse> {
    return this.sendOperationRequest({ options }, getProductByURNOperationSpec);
  }

  /**
   * Get a single product by its ID
   * @param id The ID of the product to return
   * @param options The options parameters.
   */
  getProductById(
    id: string,
    options?: GetProductByIdOptionalParams
  ): Promise<GetProductByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getProductByIdOperationSpec
    );
  }

  /**
   * Get a single product by its ID from an inbound shipment
   * @param id The ID of the product to return
   * @param options The options parameters.
   */
  getSourceProductById(
    id: string,
    options?: GetSourceProductByIdOptionalParams
  ): Promise<GetSourceProductByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getSourceProductByIdOperationSpec
    );
  }

  /**
   * Return a list of all products on the current user's account
   * @param options The options parameters.
   */
  getProducts(
    options?: GetProductsOptionalParams
  ): Promise<GetProductsResponse> {
    return this.sendOperationRequest({ options }, getProductsOperationSpec);
  }

  /**
   * Add a product to the current user's account
   * @param options The options parameters.
   */
  createProduct(
    options?: CreateProductOptionalParams
  ): Promise<CreateProductResponse> {
    return this.sendOperationRequest({ options }, createProductOperationSpec);
  }

  /**
   * Update multiple existing products
   * @param options The options parameters.
   */
  updateProduct(
    options?: UpdateProductOptionalParams
  ): Promise<UpdateProductOperationResponse> {
    return this.sendOperationRequest({ options }, updateProductOperationSpec);
  }

  /**
   * Return a list of all associated products on the current user's account
   * @param options The options parameters.
   */
  getAssociatedProducts(
    options?: GetAssociatedProductsOptionalParams
  ): Promise<GetAssociatedProductsResponse> {
    return this.sendOperationRequest(
      { options },
      getAssociatedProductsOperationSpec
    );
  }

  /**
   * Link an associated product
   * @param options The options parameters.
   */
  linkAssociatedProduct(
    options?: LinkAssociatedProductOptionalParams
  ): Promise<LinkAssociatedProductResponse> {
    return this.sendOperationRequest(
      { options },
      linkAssociatedProductOperationSpec
    );
  }

  /**
   * Unlink an associated product
   * @param options The options parameters.
   */
  unlinkAssociatedProduct(
    options?: UnlinkAssociatedProductOptionalParams
  ): Promise<UnlinkAssociatedProductResponse> {
    return this.sendOperationRequest(
      { options },
      unlinkAssociatedProductOperationSpec
    );
  }

  /**
   * Refreshes the inventory of an existing product
   * @param id The ID of the product to update
   * @param options The options parameters.
   */
  updateProductInventory(
    id: string,
    options?: UpdateProductInventoryOptionalParams
  ): Promise<void> {
    return this.sendOperationRequest(
      { id, options },
      updateProductInventoryOperationSpec
    );
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  listPossibleSubProducts(
    id: string,
    options?: ListPossibleSubProductsOptionalParams
  ): Promise<ListPossibleSubProductsOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      listPossibleSubProductsOperationSpec
    );
  }

  /**
   * @param productId
   * @param options The options parameters.
   */
  addSubProducts(
    productId: string,
    options?: AddSubProductsOptionalParams
  ): Promise<AddSubProductsOperationResponse> {
    return this.sendOperationRequest(
      { productId, options },
      addSubProductsOperationSpec
    );
  }

  /**
   * @param productId
   * @param subProductId
   * @param options The options parameters.
   */
  removeSubProduct(
    productId: string,
    subProductId: string,
    options?: RemoveSubProductOptionalParams
  ): Promise<RemoveSubProductOperationResponse> {
    return this.sendOperationRequest(
      { productId, subProductId, options },
      removeSubProductOperationSpec
    );
  }

  /**
   * List all trade partners, product names/ids and location names of the current account
   * @param options The options parameters.
   */
  getNamesAndIds(
    options?: GetNamesAndIdsOptionalParams
  ): Promise<GetNamesAndIdsResponse> {
    return this.sendOperationRequest({ options }, getNamesAndIdsOperationSpec);
  }

  /**
   * Get all shipments based on the provided filters
   * @param options The options parameters.
   */
  listShipments(
    options?: ListShipmentsOptionalParams
  ): Promise<ListShipmentsOperationResponse> {
    return this.sendOperationRequest({ options }, listShipmentsOperationSpec);
  }

  /**
   * Get a shipment summary based on based on the provided filters
   * @param options The options parameters.
   */
  shipmentSummary(
    options?: ShipmentSummaryOptionalParams
  ): Promise<ShipmentSummaryOperationResponse> {
    return this.sendOperationRequest({ options }, shipmentSummaryOperationSpec);
  }

  /**
   * Reject a shipment
   * @param options The options parameters.
   */
  rejectShipment(
    options?: RejectShipmentOptionalParams
  ): Promise<RejectShipmentResponse> {
    return this.sendOperationRequest({ options }, rejectShipmentOperationSpec);
  }

  /**
   * Create an external shipment
   * @param options The options parameters.
   */
  createExternalShipment(
    options?: CreateExternalShipmentOptionalParams
  ): Promise<CreateExternalShipmentOperationResponse> {
    return this.sendOperationRequest(
      { options },
      createExternalShipmentOperationSpec
    );
  }

  /**
   * Get the external Registry details
   * @param options The options parameters.
   */
  getExternalRegistryDetails(
    options?: GetExternalRegistryDetailsOptionalParams
  ): Promise<GetExternalRegistryDetailsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      getExternalRegistryDetailsOperationSpec
    );
  }

  /**
   * Set the external Registry details
   * @param options The options parameters.
   */
  setExternalRegistryDetails(
    options?: SetExternalRegistryDetailsOptionalParams
  ): Promise<SetExternalRegistryDetailsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      setExternalRegistryDetailsOperationSpec
    );
  }

  /**
   * Update the external Registry details
   * @param options The options parameters.
   */
  updateExternalRegistryDetails(
    options?: UpdateExternalRegistryDetailsOptionalParams
  ): Promise<UpdateExternalRegistryDetailsOperationResponse> {
    return this.sendOperationRequest(
      { options },
      updateExternalRegistryDetailsOperationSpec
    );
  }

  /**
   * Generates a new outbound API Key for the trade partner provided
   * @param options The options parameters.
   */
  setOutboundApiKey(
    options?: SetOutboundApiKeyOptionalParams
  ): Promise<SetOutboundApiKeyOperationResponse> {
    return this.sendOperationRequest(
      { options },
      setOutboundApiKeyOperationSpec
    );
  }

  /**
   * Hits the digital link to test the connection
   * @param options The options parameters.
   */
  queryDigitalLink(
    options?: QueryDigitalLinkOptionalParams
  ): Promise<QueryDigitalLinkResponse> {
    return this.sendOperationRequest(
      { options },
      queryDigitalLinkOperationSpec
    );
  }

  /** @param options The options parameters. */
  csvProcessingNegotiate(
    options?: CsvProcessingNegotiateOptionalParams
  ): Promise<void> {
    return this.sendOperationRequest(
      { options },
      csvProcessingNegotiateOperationSpec
    );
  }

  /**
   * Get a template attribute by its id
   * @param id The template attribute id
   * @param options The options parameters.
   */
  getTemplateAttributeById(
    id: string,
    options?: GetTemplateAttributeByIdOptionalParams
  ): Promise<GetTemplateAttributeByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getTemplateAttributeByIdOperationSpec
    );
  }

  /**
   * Update the name or template body for an template attribute
   * @param id The ID of the template attribute
   * @param options The options parameters.
   */
  updateTemplateAttribute(
    id: string,
    options?: UpdateTemplateAttributeOptionalParams
  ): Promise<UpdateTemplateAttributeResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateTemplateAttributeOperationSpec
    );
  }

  /**
   * Delete an template attribute
   * @param id The id of the template to delete
   * @param options The options parameters.
   */
  deleteTemplateAttribute(
    id: string,
    options?: DeleteTemplateAttributeOptionalParams
  ): Promise<DeleteTemplateAttributeResponse> {
    return this.sendOperationRequest(
      { id, options },
      deleteTemplateAttributeOperationSpec
    );
  }

  /**
   * Get a list of all templates for the current account
   * @param options The options parameters.
   */
  getTemplateAttributes(
    options?: GetTemplateAttributesOptionalParams
  ): Promise<GetTemplateAttributesResponse> {
    return this.sendOperationRequest(
      { options },
      getTemplateAttributesOperationSpec
    );
  }

  /**
   * Add a new template attribute
   * @param options The options parameters.
   */
  createTemplateAttribute(
    options?: CreateTemplateAttributeOptionalParams
  ): Promise<CreateTemplateAttributeResponse> {
    return this.sendOperationRequest(
      { options },
      createTemplateAttributeOperationSpec
    );
  }

  /**
   * Get a list of all default templates for the provided filters
   * @param options The options parameters.
   */
  getDefaultTemplateAttributes(
    options?: GetDefaultTemplateAttributesOptionalParams
  ): Promise<GetDefaultTemplateAttributesResponse> {
    return this.sendOperationRequest(
      { options },
      getDefaultTemplateAttributesOperationSpec
    );
  }

  /**
   * Return a trade partner based on their ID
   * @param id The id of the trade partner to return
   * @param options The options parameters.
   */
  getTradePartnerById(
    id: string,
    options?: GetTradePartnerByIdOptionalParams
  ): Promise<GetTradePartnerByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getTradePartnerByIdOperationSpec
    );
  }

  /**
   * Update an existing trade partner
   * @param id The ID of the trade partner to update
   * @param options The options parameters.
   */
  updateTradePartner(
    id: string,
    options?: UpdateTradePartnerOptionalParams
  ): Promise<UpdateTradePartnerResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateTradePartnerOperationSpec
    );
  }

  /**
   * Delete an existing trade partner
   * @param id The ID of the trade partner to delete
   * @param options The options parameters.
   */
  deleteTradePartner(
    id: string,
    options?: DeleteTradePartnerOptionalParams
  ): Promise<void> {
    return this.sendOperationRequest(
      { id, options },
      deleteTradePartnerOperationSpec
    );
  }

  /**
   * Get a trade partner by URN
   * @param options The options parameters.
   */
  getTradePartnerByURN(
    options?: GetTradePartnerByURNOptionalParams
  ): Promise<GetTradePartnerByURNResponse> {
    return this.sendOperationRequest(
      { options },
      getTradePartnerByURNOperationSpec
    );
  }

  /**
   * Get a list of trade partners for the current user's account
   * @param options The options parameters.
   */
  getTradePartners(
    options?: GetTradePartnersOptionalParams
  ): Promise<GetTradePartnersResponse> {
    return this.sendOperationRequest(
      { options },
      getTradePartnersOperationSpec
    );
  }

  /**
   * Create a new trade partner on the current user's account.
   * @param options The options parameters.
   */
  createTradePartner(
    options?: CreateTradePartnerOptionalParams
  ): Promise<CreateTradePartnerResponse> {
    return this.sendOperationRequest(
      { options },
      createTradePartnerOperationSpec
    );
  }

  /**
   * Get the network details of the trade partner locations
   * @param id The ID of the partner to return
   * @param options The options parameters.
   */
  getNetworkDetails(
    id: string,
    options?: GetNetworkDetailsOptionalParams
  ): Promise<GetNetworkDetailsResponse> {
    return this.sendOperationRequest(
      { id, options },
      getNetworkDetailsOperationSpec
    );
  }

  /**
   * Get the user based on its ID
   * @param id The id of the user to return
   * @param options The options parameters.
   */
  getUserById(
    id: string,
    options?: GetUserByIdOptionalParams
  ): Promise<GetUserByIdOperationResponse> {
    return this.sendOperationRequest({ id, options }, getUserByIdOperationSpec);
  }

  /**
   * Update an user
   * @param id Id of the user to be updated
   * @param options The options parameters.
   */
  updateUser(
    id: string,
    options?: UpdateUserOptionalParams
  ): Promise<UpdateUserOperationResponse> {
    return this.sendOperationRequest({ id, options }, updateUserOperationSpec);
  }

  /**
   * Delete an user
   * @param id Id of the user to delete
   * @param options The options parameters.
   */
  deleteUser(id: string, options?: DeleteUserOptionalParams): Promise<void> {
    return this.sendOperationRequest({ id, options }, deleteUserOperationSpec);
  }

  /**
   * Get the users based on the filters
   * @param options The options parameters.
   */
  listUsers(
    options?: ListUsersOptionalParams
  ): Promise<ListUsersOperationResponse> {
    return this.sendOperationRequest({ options }, listUsersOperationSpec);
  }

  /**
   * Update the profile picture for the current user
   * @param options The options parameters.
   */
  uploadProfilePicture(
    options?: UploadProfilePictureOptionalParams
  ): Promise<UploadProfilePictureOperationResponse> {
    return this.sendOperationRequest(
      { options },
      uploadProfilePictureOperationSpec
    );
  }

  /**
   * Changes the current account for an user
   * @param id Id of the user to be updated
   * @param options The options parameters.
   */
  changeAccount(
    id: string,
    options?: ChangeAccountOptionalParams
  ): Promise<ChangeAccountResponse> {
    return this.sendOperationRequest(
      { id, options },
      changeAccountOperationSpec
    );
  }

  /**
   * Invite one or more new users
   * @param options The options parameters.
   */
  inviteUser(
    options?: InviteUserOptionalParams
  ): Promise<InviteUserOperationResponse> {
    return this.sendOperationRequest({ options }, inviteUserOperationSpec);
  }

  /**
   * Redeem an invite
   * @param id Id of the invite to redeem
   * @param options The options parameters.
   */
  redeemInvite(
    id: string,
    options?: RedeemInviteOptionalParams
  ): Promise<RedeemInviteOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      redeemInviteOperationSpec
    );
  }

  /**
   * Delete an invite
   * @param id Id of the invite to delete
   * @param options The options parameters.
   */
  deleteInvite(
    id: string,
    options?: DeleteInviteOptionalParams
  ): Promise<DeleteInviteOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      deleteInviteOperationSpec
    );
  }

  /**
   * Get the invite based on its ID
   * @param id The id of the invite to return
   * @param options The options parameters.
   */
  getInviteById(
    id: string,
    options?: GetInviteByIdOptionalParams
  ): Promise<GetInviteByIdResponse> {
    return this.sendOperationRequest(
      { id, options },
      getInviteByIdOperationSpec
    );
  }

  /**
   * Sets/unsets user as Site Admin
   * @param options The options parameters.
   */
  setSiteAdmin(options?: SetSiteAdminOptionalParams): Promise<void> {
    return this.sendOperationRequest({ options }, setSiteAdminOperationSpec);
  }

  /**
   * Accepts the terms and conditions to current user
   * @param options The options parameters.
   */
  acceptTermsAndConditions(
    options?: AcceptTermsAndConditionsOptionalParams
  ): Promise<void> {
    return this.sendOperationRequest(
      { options },
      acceptTermsAndConditionsOperationSpec
    );
  }

  /**
   * Sets the terms and conditions to not accepted for all users, only site admin users can reset it
   * @param options The options parameters.
   */
  resetTermsAndConditions(
    options?: ResetTermsAndConditionsOptionalParams
  ): Promise<void> {
    return this.sendOperationRequest(
      { options },
      resetTermsAndConditionsOperationSpec
    );
  }

  /** @param options The options parameters. */
  createWorkflowTemplate(
    options?: CreateWorkflowTemplateOptionalParams
  ): Promise<CreateWorkflowTemplateOperationResponse> {
    return this.sendOperationRequest(
      { options },
      createWorkflowTemplateOperationSpec
    );
  }

  /** @param options The options parameters. */
  listWorkflowTemplates(
    options?: ListWorkflowTemplatesOptionalParams
  ): Promise<ListWorkflowTemplatesOperationResponse> {
    return this.sendOperationRequest(
      { options },
      listWorkflowTemplatesOperationSpec
    );
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  getWorkflowTemplateById(
    id: string,
    options?: GetWorkflowTemplateByIdOptionalParams
  ): Promise<GetWorkflowTemplateByIdOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      getWorkflowTemplateByIdOperationSpec
    );
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  deleteWorkflowTemplate(
    id: string,
    options?: DeleteWorkflowTemplateOptionalParams
  ): Promise<DeleteWorkflowTemplateOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      deleteWorkflowTemplateOperationSpec
    );
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  updateWorkflowTemplate(
    id: string,
    options?: UpdateWorkflowTemplateOptionalParams
  ): Promise<UpdateWorkflowTemplateOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateWorkflowTemplateOperationSpec
    );
  }

  /** @param options The options parameters. */
  createWorkflow(
    options?: CreateWorkflowOptionalParams
  ): Promise<CreateWorkflowOperationResponse> {
    return this.sendOperationRequest({ options }, createWorkflowOperationSpec);
  }

  /** @param options The options parameters. */
  listWorkflows(
    options?: ListWorkflowsOptionalParams
  ): Promise<ListWorkflowsOperationResponse> {
    return this.sendOperationRequest({ options }, listWorkflowsOperationSpec);
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  getWorkflowById(
    id: string,
    options?: GetWorkflowByIdOptionalParams
  ): Promise<GetWorkflowByIdOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      getWorkflowByIdOperationSpec
    );
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  deleteWorkflow(
    id: string,
    options?: DeleteWorkflowOptionalParams
  ): Promise<DeleteWorkflowOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      deleteWorkflowOperationSpec
    );
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  updateWorkflow(
    id: string,
    options?: UpdateWorkflowOptionalParams
  ): Promise<UpdateWorkflowOperationResponse> {
    return this.sendOperationRequest(
      { id, options },
      updateWorkflowOperationSpec
    );
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const createAccountOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateAccountResponse
    }
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getAccountOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetAccountResponse
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const updateAccountOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateAccountResponse
    }
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const updateSubscriptionOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/subscription",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateAccountSubscriptionResponse
    }
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getSubscriptionOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/subscription",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetSubscriptionResponse
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const fixMissingSubsOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/fixsubs",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getAccountByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetAccountByIdResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const createNetworkInvitationOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/networks",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateNetworkInvitationResponse
    }
  },
  requestBody: Parameters.body3,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const listNetworkInvitationsOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/networks",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListNetworkInvitationsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.status,
    Parameters.networkInvitationId,
    Parameters.accountId,
    Parameters.incoming,
    Parameters.isResentRequest
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const listNetworkOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/network",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListNetworkResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.incoming,
    Parameters.tradePartnerIds,
    Parameters.address,
    Parameters.locationName,
    Parameters.networkStatus,
    Parameters.tradePartnerStatus
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const listAccountsOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/listAccounts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListUserAccountsResponse
    }
  },
  queryParameters: [Parameters.name],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const addAccountToUserOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/addAccount",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.AddAccountToUserResponse
    }
  },
  requestBody: Parameters.body4,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const pinUserAccountOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/pin",
  httpMethod: "PUT",
  responses: { 200: {} },
  queryParameters: [Parameters.accountId, Parameters.pin],
  urlParameters: [Parameters.$host],
  serializer
};
const hasActiveInventoryOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/hasActiveInventory",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.HasActiveInventoryResponse
    }
  },
  queryParameters: [Parameters.isVessel, Parameters.partnerId],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const acceptNetworkInvitationOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/networks/{id}/accept",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNetworkInvitationResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const rejectNetworkInvitationOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/networks/{id}/reject",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNetworkInvitationResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const resendNetworkInvitationOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/networks/{id}/resend",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNetworkInvitationResponse
    }
  },
  queryParameters: [Parameters.locationIds],
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const cancelNetworkInvitationOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/networks/{id}/cancel",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNetworkInvitationResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const revokeNetworkInvitationOperationSpec: coreClient.OperationSpec = {
  path: "/Accounts/networks/{id}/revoke",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateNetworkInvitationResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const listBizstepsOperationSpec: coreClient.OperationSpec = {
  path: "/Bizstep",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "BizStep" } }
        }
      }
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const listDispositionsOperationSpec: coreClient.OperationSpec = {
  path: "/Disposition",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "BizStep" } }
        }
      }
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getDocumentByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Documents/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.DocumentResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const updateDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/Documents/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.DocumentResponse
    }
  },
  requestBody: Parameters.body5,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const downloadDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/Documents/{id}/download",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept1],
  serializer
};
const listDocumentsOperationSpec: coreClient.OperationSpec = {
  path: "/Documents",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListDocumentsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.status1,
    Parameters.title,
    Parameters.documentType,
    Parameters.recentActivity,
    Parameters.expirationDate,
    Parameters.blockchain,
    Parameters.privacy,
    Parameters.url,
    Parameters.locations,
    Parameters.products,
    Parameters.events,
    Parameters.tradePartners,
    Parameters.sharedWith,
    Parameters.eventsHistory
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/Documents",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.DocumentResponse
    }
  },
  formDataParameters: [
    Parameters.title1,
    Parameters.file,
    Parameters.privacy1,
    Parameters.blockchain1,
    Parameters.documentType1,
    Parameters.documentTypeName,
    Parameters.expirationDate1,
    Parameters.expirationAlertDate,
    Parameters.locationsIds,
    Parameters.productsIds,
    Parameters.tradePartnersIds,
    Parameters.purchaseOrderNumber,
    Parameters.certificationStandard,
    Parameters.certificationAgency,
    Parameters.certificationId,
    Parameters.shipFrom,
    Parameters.shipTo,
    Parameters.sID,
    Parameters.cID,
    Parameters.fOB,
    Parameters.shipDate,
    Parameters.carrier,
    Parameters.items,
    Parameters.quantitiesOfShippedItems
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType1, Parameters.accept2],
  serializer
};
const sendExpiringDocumentNotificationsOperationSpec: coreClient.OperationSpec = {
  path: "/Documents/sendExpiringDocumentNotifications",
  httpMethod: "POST",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  serializer
};
const shareDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/Documents/share",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ShareDocumentResponse
    }
  },
  requestBody: Parameters.body6,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const unshareDocumentOperationSpec: coreClient.OperationSpec = {
  path: "/Documents/unshare/{documentId}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  requestBody: Parameters.body7,
  urlParameters: [Parameters.$host, Parameters.documentId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const createEpcisDataOperationSpec: coreClient.OperationSpec = {
  path: "/EPCIS",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  requestBody: Parameters.body8,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getEventTemplateByIdOperationSpec: coreClient.OperationSpec = {
  path: "/EventTemplates/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplate
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const updateEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/EventTemplates/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplate
    }
  },
  requestBody: Parameters.body9,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const deleteEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/EventTemplates/{id}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplate
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const getEventTemplatesOperationSpec: coreClient.OperationSpec = {
  path: "/EventTemplates",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetEventTemplatesResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.templateName,
    Parameters.primaryId,
    Parameters.sharedWith1,
    Parameters.roles
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/EventTemplates",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplate
    }
  },
  requestBody: Parameters.body10,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const shareEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/EventTemplates/share/{templateId}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplate
    }
  },
  requestBody: Parameters.body11,
  urlParameters: [Parameters.$host, Parameters.templateId],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const unshareEventTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/EventTemplates/unshare/{templateId}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.EventTemplate
    }
  },
  queryParameters: [Parameters.wholechainId],
  urlParameters: [Parameters.$host, Parameters.templateId],
  headerParameters: [Parameters.accept],
  serializer
};
const getBlockchainStatusByEventIdOperationSpec: coreClient.OperationSpec = {
  path: "/Events/{id}/blockchain",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetStatusByEventIdResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept],
  serializer
};
const deleteEventOperationSpec: coreClient.OperationSpec = {
  path: "/Events/{eventId}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  urlParameters: [Parameters.$host, Parameters.eventId],
  headerParameters: [Parameters.accept],
  serializer
};
const getEventByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Events/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Event
    }
  },
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept],
  serializer
};
const getEventsOperationSpec: coreClient.OperationSpec = {
  path: "/Events",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "Event" } }
        }
      }
    }
  },
  queryParameters: [Parameters.urns],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const resolveOrphanOperationSpec: coreClient.OperationSpec = {
  path: "/Events/orphans/resolve/{eventId}",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  urlParameters: [Parameters.$host, Parameters.eventId],
  headerParameters: [Parameters.accept],
  serializer
};
const getAllFirstEventsOfOrphansSupplyChainsOperationSpec: coreClient.OperationSpec = {
  path: "/Events/orphans",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetAllFirstEventsOfOrphansSupplyChainsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.lotSerial,
    Parameters.purchaseOrder,
    Parameters.productName,
    Parameters.company,
    Parameters.quantity,
    Parameters.ticketId,
    Parameters.gTIN,
    Parameters.shipmentStatus,
    Parameters.shippedToIds,
    Parameters.shippedFromIds,
    Parameters.eventTypes,
    Parameters.locationIds1,
    Parameters.startEventDate,
    Parameters.endEventDate,
    Parameters.startLoggedDate,
    Parameters.endLoggedDate
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const listEventDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/Events/details",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListEventDetailsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.lotSerial,
    Parameters.productName,
    Parameters.locationIds1,
    Parameters.eventIds,
    Parameters.eventTypes1,
    Parameters.companyIds,
    Parameters.productIds
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getEventDetailsDiagramOperationSpec: coreClient.OperationSpec = {
  path: "/Events/{id}/diagram",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetEventHistoryDiagramResponse
    }
  },
  queryParameters: [Parameters.instanceId, Parameters.containerId],
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept],
  serializer
};
const getEventDetailsMapOperationSpec: coreClient.OperationSpec = {
  path: "/Events/{id}/map",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetEventHistoryMapResponse
    }
  },
  queryParameters: [Parameters.instanceId, Parameters.containerId],
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept],
  serializer
};
const aggregateOperationSpec: coreClient.OperationSpec = {
  path: "/Events/aggregate",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.AggregationEvent
    }
  },
  requestBody: Parameters.body12,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const disaggregateOperationSpec: coreClient.OperationSpec = {
  path: "/Events/disaggregate",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.DisaggregationEvent
    }
  },
  requestBody: Parameters.body13,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const commissionOperationSpec: coreClient.OperationSpec = {
  path: "/Events/commission",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CommissionEvent
    }
  },
  requestBody: Parameters.body14,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const multiCommissionOperationSpec: coreClient.OperationSpec = {
  path: "/Events/multicommission",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateMultiCommissionResponse
    }
  },
  requestBody: Parameters.body15,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const decommissionOperationSpec: coreClient.OperationSpec = {
  path: "/Events/decommission",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.DecommissionEvent
    }
  },
  requestBody: Parameters.body16,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const observeOperationSpec: coreClient.OperationSpec = {
  path: "/Events/observe",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ObservationEvent
    }
  },
  requestBody: Parameters.body17,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const receiveOperationSpec: coreClient.OperationSpec = {
  path: "/Events/receive",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ReceiveEvent
    }
  },
  requestBody: Parameters.body18,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const multiObserveOperationSpec: coreClient.OperationSpec = {
  path: "/Events/multiobserve",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateMultiObserveResponse
    }
  },
  requestBody: Parameters.body19,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const shipOperationSpec: coreClient.OperationSpec = {
  path: "/Events/ship",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ShipEvent
    }
  },
  requestBody: Parameters.body20,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const multiShipOperationSpec: coreClient.OperationSpec = {
  path: "/Events/multiship",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateMultiShipResponse
    }
  },
  requestBody: Parameters.body21,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const multiReceiveOperationSpec: coreClient.OperationSpec = {
  path: "/Events/multireceive",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateMultiReceiveResponse
    }
  },
  requestBody: Parameters.body22,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const transformOperationSpec: coreClient.OperationSpec = {
  path: "/Events/transform",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.TransformEvent
    }
  },
  requestBody: Parameters.body23,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const batchShipOperationSpec: coreClient.OperationSpec = {
  path: "/Events/batchship",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ShipTicket
    }
  },
  requestBody: Parameters.body24,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getBatchShipEventsOperationSpec: coreClient.OperationSpec = {
  path: "/Events/batchship",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListBatchShipResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.productName,
    Parameters.lotId,
    Parameters.dispatchDate
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getBatchShipEventByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Events/batchship/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ShipTicket
    }
  },
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept],
  serializer
};
const updateBatchShipEventOperationSpec: coreClient.OperationSpec = {
  path: "/Events/batchship/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.ShipTicket
    }
  },
  requestBody: Parameters.body25,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getDashboardEventsOperationSpec: coreClient.OperationSpec = {
  path: "/Events/dashboard",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetDashboardEventsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.productName,
    Parameters.locationIds1,
    Parameters.eventName,
    Parameters.eventTypes2
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const simpleEventQueryOperationSpec: coreClient.OperationSpec = {
  path: "/Events/simpleeventquery",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.SimpleEventQueryResponse
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getEventOperationSpec: coreClient.OperationSpec = {
  path: "/Events/GetEvent",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Event
    }
  },
  queryParameters: [Parameters.eventId1],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getEventNodeOperationSpec: coreClient.OperationSpec = {
  path: "/Events/eventNode/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.EventNode
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const getEventBatchOperationSpec: coreClient.OperationSpec = {
  path: "/Events/eventbatch/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.EventBatch
    }
  },
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept],
  serializer
};
const updateEventBatchStatusOperationSpec: coreClient.OperationSpec = {
  path: "/Events/eventbatch/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.EventBatch
    }
  },
  requestBody: Parameters.body27,
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getEventBatchStatusOperationSpec: coreClient.OperationSpec = {
  path: "/Events/eventbatch/{id}/status",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: { type: { name: "String" } }
    }
  },
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.accept3],
  serializer
};
const createIntegrationDataOperationSpec: coreClient.OperationSpec = {
  path: "/Integration",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  requestBody: Parameters.body28,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const createLanguageOperationSpec: coreClient.OperationSpec = {
  path: "/Languages",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.LanguageResponse
    }
  },
  requestBody: Parameters.body29,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const listLanguagesOperationSpec: coreClient.OperationSpec = {
  path: "/Languages",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListLanguagesResponse
    }
  },
  queryParameters: [Parameters.languageCode],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const deleteLanguageOperationSpec: coreClient.OperationSpec = {
  path: "/Languages",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  requestBody: Parameters.body30,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const createAttributeKeyOperationSpec: coreClient.OperationSpec = {
  path: "/Languages/attributeKey",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.AttributeKeyResponse
    }
  },
  requestBody: Parameters.body31,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getAttributeValueOperationSpec: coreClient.OperationSpec = {
  path: "/Languages/attributeKey",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetAttributeValueResponse
    }
  },
  queryParameters: [
    Parameters.languageCode,
    Parameters.attributeKeyName,
    Parameters.type
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const updateAttributeKeysOperationSpec: coreClient.OperationSpec = {
  path: "/Languages/attributeKey",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.AttributeKeyResponse
    }
  },
  requestBody: Parameters.body32,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const deleteAttributeKeyOperationSpec: coreClient.OperationSpec = {
  path: "/Languages/attributeKey",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  requestBody: Parameters.body33,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const createAttributeKeyBulkOperationSpec: coreClient.OperationSpec = {
  path: "/Languages/attributeKeyBulk",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.AttributeKeyResponse
    }
  },
  requestBody: Parameters.body34,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getAttributeKeysOperationSpec: coreClient.OperationSpec = {
  path: "/Languages/attributeKeys",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListAttributeKeysResponse
    }
  },
  queryParameters: [
    Parameters.languageCode,
    Parameters.languageId,
    Parameters.type1
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getAllAttributeKeysOperationSpec: coreClient.OperationSpec = {
  path: "/Languages/allAttributeKeys",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListAllAttributeKeysResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.attributeKeyName,
    Parameters.languageCodes,
    Parameters.status2,
    Parameters.attributeValue,
    Parameters.types
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const bulkUpdateAttributeKeysOperationSpec: coreClient.OperationSpec = {
  path: "/Languages/bulkAttributeKey",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.AttributeKeyResponse
    }
  },
  requestBody: Parameters.body35,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getLocationByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Locations/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Location
    }
  },
  queryParameters: [Parameters.isArchived],
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const updateLocationOperationSpec: coreClient.OperationSpec = {
  path: "/Locations/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateLocationResponse
    }
  },
  requestBody: Parameters.body36,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getLocationsOperationSpec: coreClient.OperationSpec = {
  path: "/Locations",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListLocationsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.locationName,
    Parameters.isVessel,
    Parameters.sharedWith,
    Parameters.partnerIds,
    Parameters.tradePartnerStatus1,
    Parameters.archived
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createLocationOperationSpec: coreClient.OperationSpec = {
  path: "/Locations",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.Location
    }
  },
  requestBody: Parameters.body37,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getLocationByURNOperationSpec: coreClient.OperationSpec = {
  path: "/Locations/urn",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Location
    }
  },
  queryParameters: [Parameters.urn],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getLocationNetworkDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/Locations/networkdetails/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.NetworkLocationDetailsResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const archiveLocationOperationSpec: coreClient.OperationSpec = {
  path: "/Locations/archive",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ArchiveLocationResponse
    }
  },
  requestBody: Parameters.body38,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const unarchiveLocationOperationSpec: coreClient.OperationSpec = {
  path: "/Locations/unarchive",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.UnarchiveLocationResponse
    }
  },
  requestBody: Parameters.body39,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const shareLocationOperationSpec: coreClient.OperationSpec = {
  path: "/Locations/share",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ShareLocationResponse
    }
  },
  requestBody: Parameters.body40,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const masterDataPglnOperationSpec: coreClient.OperationSpec = {
  path: "/MasterData/party/{pgln}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.TradePartner
    }
  },
  urlParameters: [Parameters.$host, Parameters.pgln],
  headerParameters: [Parameters.accept4],
  serializer
};
const masterDataGLNOperationSpec: coreClient.OperationSpec = {
  path: "/MasterData/gln/{gln}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Location
    }
  },
  urlParameters: [Parameters.$host, Parameters.gln],
  headerParameters: [Parameters.accept4],
  serializer
};
const masterDataGtinOperationSpec: coreClient.OperationSpec = {
  path: "/MasterData/gtin/{gtin}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Product
    }
  },
  urlParameters: [Parameters.$host, Parameters.gtin],
  headerParameters: [Parameters.accept4],
  serializer
};
const masterDataGtinLotSerialOperationSpec: coreClient.OperationSpec = {
  path: "/MasterData/gtin/{gtin}/lot/{lotSerial}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ProductInstance
    }
  },
  urlParameters: [Parameters.$host, Parameters.gtin, Parameters.lotSerial1],
  headerParameters: [Parameters.accept4],
  serializer
};
const getNamespacesOperationSpec: coreClient.OperationSpec = {
  path: "/Namespaces",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "Namespace" } }
        }
      }
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createNamespaceOperationSpec: coreClient.OperationSpec = {
  path: "/Namespaces",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.Namespace
    }
  },
  requestBody: Parameters.body41,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const updateNamespaceOperationSpec: coreClient.OperationSpec = {
  path: "/Namespaces",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.Namespace
    }
  },
  requestBody: Parameters.body42,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const deleteNamespaceOperationSpec: coreClient.OperationSpec = {
  path: "/Namespaces/{prefix}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.Namespace
    }
  },
  urlParameters: [Parameters.$host, Parameters.prefix],
  headerParameters: [Parameters.accept],
  serializer
};
const listUserNotificationsOperationSpec: coreClient.OperationSpec = {
  path: "/Notifications",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListUserNotificationsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.notificationType,
    Parameters.currentAccountId
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const readNotificationsOperationSpec: coreClient.OperationSpec = {
  path: "/Notifications",
  httpMethod: "PUT",
  responses: { 200: {} },
  requestBody: Parameters.body43,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer
};
const sendSupportEmailOperationSpec: coreClient.OperationSpec = {
  path: "/Notifications/email",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  requestBody: Parameters.body44,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getProductInstanceQuantitiesOperationSpec: coreClient.OperationSpec = {
  path: "/ProductInstances/GetQuantities/{parentProductId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListProductInstanceQuantitiesResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.locationName,
    Parameters.sortBy,
    Parameters.primaryId,
    Parameters.lotSerial,
    Parameters.locationIds1,
    Parameters.parentProductId1,
    Parameters.history,
    Parameters.isShipped,
    Parameters.isAggregated,
    Parameters.isConsumed,
    Parameters.isSerial,
    Parameters.eventTypes3,
    Parameters.parentProductName
  ],
  urlParameters: [Parameters.$host, Parameters.parentProductId],
  headerParameters: [Parameters.accept],
  serializer
};
const getProductsInstanceQuantitiesOperationSpec: coreClient.OperationSpec = {
  path: "/ProductInstances/GetProductsQuantities",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListProductsInstanceQuantitiesResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.locationName,
    Parameters.sortBy,
    Parameters.primaryId,
    Parameters.lotSerial,
    Parameters.locationIds1,
    Parameters.history,
    Parameters.isShipped,
    Parameters.isAggregated,
    Parameters.isConsumed,
    Parameters.isSerial,
    Parameters.parentProductName,
    Parameters.parentProductId1,
    Parameters.eventTypes4
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getProductInstancesInContainerOperationSpec: coreClient.OperationSpec = {
  path: "/ProductInstances/GetInstances/container/{containerId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListProductInstancesInContainerResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.productName,
    Parameters.containerId11,
    Parameters.productId
  ],
  urlParameters: [Parameters.$host, Parameters.containerId1],
  headerParameters: [Parameters.accept],
  serializer
};
const getProductInstanceOperationSpec: coreClient.OperationSpec = {
  path: "/ProductInstances/GetInstance/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ProductInstance
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const listProductInstancesOperationSpec: coreClient.OperationSpec = {
  path: "/ProductInstances/ListInstances",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "ProductInstance" } }
        }
      }
    }
  },
  queryParameters: [Parameters.urns],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const listProductInstancesByEventOperationSpec: coreClient.OperationSpec = {
  path: "/ProductInstances/ListInstancesByEvent",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "ProductInstance" } }
        }
      }
    }
  },
  queryParameters: [Parameters.eventId1],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getProductByURNOperationSpec: coreClient.OperationSpec = {
  path: "/Products/urn",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Product
    }
  },
  queryParameters: [Parameters.urn],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getProductByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Products/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Product
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const getSourceProductByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Products/inbound/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.Product
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const getProductsOperationSpec: coreClient.OperationSpec = {
  path: "/Products",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListProductsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.sortBy,
    Parameters.primaryId,
    Parameters.productName,
    Parameters.archived,
    Parameters.isSerial
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createProductOperationSpec: coreClient.OperationSpec = {
  path: "/Products",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.Product
    }
  },
  requestBody: Parameters.body45,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const updateProductOperationSpec: coreClient.OperationSpec = {
  path: "/Products",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "UpdateProductResponse" }
          }
        }
      }
    }
  },
  requestBody: Parameters.body46,
  queryParameters: [Parameters.productIds1],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getAssociatedProductsOperationSpec: coreClient.OperationSpec = {
  path: "/Products/associated",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "AssociatedProduct" }
          }
        }
      }
    }
  },
  queryParameters: [
    Parameters.productName,
    Parameters.urn,
    Parameters.companyName,
    Parameters.productId1
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const linkAssociatedProductOperationSpec: coreClient.OperationSpec = {
  path: "/Products/associated",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } }
    }
  },
  queryParameters: [Parameters.sourceProductId, Parameters.associatedProductId],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const unlinkAssociatedProductOperationSpec: coreClient.OperationSpec = {
  path: "/Products/associated",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } }
    }
  },
  queryParameters: [Parameters.sourceProductId, Parameters.associatedProductId],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const updateProductInventoryOperationSpec: coreClient.OperationSpec = {
  path: "/Products/updateInventory/{id}",
  httpMethod: "PUT",
  responses: { 200: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer
};
const listPossibleSubProductsOperationSpec: coreClient.OperationSpec = {
  path: "/Products/{id}/possibleSubProducts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListPossibleSubProductsResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const addSubProductsOperationSpec: coreClient.OperationSpec = {
  path: "/Products/{productId}/subProducts",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.AddSubProductsResponse
    }
  },
  requestBody: Parameters.body47,
  urlParameters: [Parameters.$host, Parameters.productId2],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const removeSubProductOperationSpec: coreClient.OperationSpec = {
  path: "/Products/{productId}/subProducts/{subProductId}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.RemoveSubProductResponse
    }
  },
  urlParameters: [
    Parameters.$host,
    Parameters.productId2,
    Parameters.subProductId
  ],
  headerParameters: [Parameters.accept],
  serializer
};
const getNamesAndIdsOperationSpec: coreClient.OperationSpec = {
  path: "/Search",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListNamesAndIdsResponse
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const listShipmentsOperationSpec: coreClient.OperationSpec = {
  path: "/Shipments",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListShipmentsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.productName,
    Parameters.ticketId,
    Parameters.direction,
    Parameters.primaryId1,
    Parameters.status3,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.purchaseOrderNumber1,
    Parameters.sortBy1,
    Parameters.originIds,
    Parameters.destinationIds,
    Parameters.tradePartnerURN,
    Parameters.locationId,
    Parameters.senderName,
    Parameters.receiverName
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const shipmentSummaryOperationSpec: coreClient.OperationSpec = {
  path: "/Shipments/summary",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ShipmentSummaryResponse
    }
  },
  queryParameters: [
    Parameters.tradePartnerURN,
    Parameters.locationId,
    Parameters.startDate1,
    Parameters.endDate1
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const rejectShipmentOperationSpec: coreClient.OperationSpec = {
  path: "/Shipments/reject",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: {
        type: { name: "Dictionary", value: { type: { name: "any" } } }
      }
    }
  },
  requestBody: Parameters.body48,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const createExternalShipmentOperationSpec: coreClient.OperationSpec = {
  path: "/Shipments/externalShipment",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateExternalShipmentResponse
    }
  },
  requestBody: Parameters.body49,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getExternalRegistryDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/Shipments/externalRegistryDetails",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetExternalRegistryDetailsResponse
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const setExternalRegistryDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/Shipments/setExternalRegistryDetails",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.SetExternalRegistryDetailsResponse
    }
  },
  requestBody: Parameters.body50,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const updateExternalRegistryDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/Shipments/updateExternalRegistryDetails",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateExternalRegistryDetailsResponse
    }
  },
  requestBody: Parameters.body51,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const setOutboundApiKeyOperationSpec: coreClient.OperationSpec = {
  path: "/Shipments/setOutboundApiKey",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.SetOutboundApiKeyResponse
    }
  },
  queryParameters: [Parameters.tradePartnerId],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const queryDigitalLinkOperationSpec: coreClient.OperationSpec = {
  path: "/Shipments/testDigitalLink",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetDigitalLinkResponse
    }
  },
  queryParameters: [Parameters.url1, Parameters.linkType, Parameters.apiKey],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const csvProcessingNegotiateOperationSpec: coreClient.OperationSpec = {
  path: "/SignalR/csvProcessing/negotiate",
  httpMethod: "POST",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  serializer
};
const getTemplateAttributeByIdOperationSpec: coreClient.OperationSpec = {
  path: "/TemplateAttribute/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.TemplateAttribute
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const updateTemplateAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/TemplateAttribute/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.TemplateAttribute
    }
  },
  requestBody: Parameters.body52,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const deleteTemplateAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/TemplateAttribute/{id}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.TemplateAttribute
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const getTemplateAttributesOperationSpec: coreClient.OperationSpec = {
  path: "/TemplateAttribute",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListTemplateAttributesResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.locationName,
    Parameters.locationIds1,
    Parameters.partnerIds,
    Parameters.fieldName,
    Parameters.propertyName,
    Parameters.fieldTypes,
    Parameters.values,
    Parameters.defaultValue,
    Parameters.namespaces,
    Parameters.propertyLocations,
    Parameters.expandValues
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createTemplateAttributeOperationSpec: coreClient.OperationSpec = {
  path: "/TemplateAttribute",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.TemplateAttribute
    }
  },
  requestBody: Parameters.body53,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getDefaultTemplateAttributesOperationSpec: coreClient.OperationSpec = {
  path: "/TemplateAttribute/DefaultValues",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListTemplateAttributesResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.locationName,
    Parameters.locationIds1,
    Parameters.partnerIds,
    Parameters.fieldName,
    Parameters.propertyName,
    Parameters.values,
    Parameters.defaultValue,
    Parameters.namespaces,
    Parameters.expandValues,
    Parameters.fieldTypes1,
    Parameters.propertyLocations1
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getTradePartnerByIdOperationSpec: coreClient.OperationSpec = {
  path: "/TradePartners/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.TradePartner
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const updateTradePartnerOperationSpec: coreClient.OperationSpec = {
  path: "/TradePartners/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.TradePartner
    }
  },
  requestBody: Parameters.body54,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const deleteTradePartnerOperationSpec: coreClient.OperationSpec = {
  path: "/TradePartners/{id}",
  httpMethod: "DELETE",
  responses: { 200: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer
};
const getTradePartnerByURNOperationSpec: coreClient.OperationSpec = {
  path: "/TradePartners/urn",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.TradePartner
    }
  },
  queryParameters: [Parameters.urn],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getTradePartnersOperationSpec: coreClient.OperationSpec = {
  path: "/TradePartners",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "TradePartner" } }
        }
      }
    }
  },
  queryParameters: [Parameters.tradePartnerName, Parameters.tradePartnerType],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createTradePartnerOperationSpec: coreClient.OperationSpec = {
  path: "/TradePartners",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.TradePartner
    }
  },
  requestBody: Parameters.body55,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const getNetworkDetailsOperationSpec: coreClient.OperationSpec = {
  path: "/TradePartners/networkdetails/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.NetworkLocationDetailsResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const getUserByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Users/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetUserByIdResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const updateUserOperationSpec: coreClient.OperationSpec = {
  path: "/Users/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateUserResponse
    }
  },
  requestBody: Parameters.body56,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const deleteUserOperationSpec: coreClient.OperationSpec = {
  path: "/Users/{id}",
  httpMethod: "DELETE",
  responses: { 200: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer
};
const listUsersOperationSpec: coreClient.OperationSpec = {
  path: "/Users",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListUsersResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.name,
    Parameters.email,
    Parameters.role,
    Parameters.status4
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const uploadProfilePictureOperationSpec: coreClient.OperationSpec = {
  path: "/Users/profile-picture",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UploadProfilePictureResponse
    }
  },
  formDataParameters: [Parameters.picture],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType1, Parameters.accept2],
  serializer
};
const changeAccountOperationSpec: coreClient.OperationSpec = {
  path: "/Users/{id}/changeaccount",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.ChangeCurrentAccountResponse
    }
  },
  requestBody: Parameters.body57,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const inviteUserOperationSpec: coreClient.OperationSpec = {
  path: "/Users/invites",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.InviteUserResponse
    }
  },
  requestBody: Parameters.body58,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const redeemInviteOperationSpec: coreClient.OperationSpec = {
  path: "/Users/invites/{id}/redeem",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.RedeemInviteResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const deleteInviteOperationSpec: coreClient.OperationSpec = {
  path: "/Users/invites/{id}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.DeleteInviteResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const getInviteByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Users/invites/{id}/isValid",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.IsInviteValidResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const setSiteAdminOperationSpec: coreClient.OperationSpec = {
  path: "/Users/setSiteAdmin",
  httpMethod: "PUT",
  responses: { 200: {} },
  queryParameters: [Parameters.userId, Parameters.isAdmin],
  urlParameters: [Parameters.$host],
  serializer
};
const acceptTermsAndConditionsOperationSpec: coreClient.OperationSpec = {
  path: "/Users/acceptTerms",
  httpMethod: "PUT",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  serializer
};
const resetTermsAndConditionsOperationSpec: coreClient.OperationSpec = {
  path: "/Users/resetTermsForAllUsers",
  httpMethod: "PUT",
  responses: { 200: {} },
  urlParameters: [Parameters.$host],
  serializer
};
const createWorkflowTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/WorkflowTemplate",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateWorkflowTemplateResponse
    }
  },
  requestBody: Parameters.body59,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const listWorkflowTemplatesOperationSpec: coreClient.OperationSpec = {
  path: "/WorkflowTemplate",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListWorkflowTemplatesResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.name,
    Parameters.sortBy2
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getWorkflowTemplateByIdOperationSpec: coreClient.OperationSpec = {
  path: "/WorkflowTemplate/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetWorkflowTemplateByIdResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const deleteWorkflowTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/WorkflowTemplate/{id}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.DeleteWorkflowTemplateResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const updateWorkflowTemplateOperationSpec: coreClient.OperationSpec = {
  path: "/WorkflowTemplate/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateWorkflowTemplateResponse
    }
  },
  requestBody: Parameters.body60,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const createWorkflowOperationSpec: coreClient.OperationSpec = {
  path: "/Workflows",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.CreateWorkflowResponse
    }
  },
  requestBody: Parameters.body61,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
const listWorkflowsOperationSpec: coreClient.OperationSpec = {
  path: "/Workflows",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ListWorkflowsResponse
    }
  },
  queryParameters: [
    Parameters.pageNumber,
    Parameters.pageSize,
    Parameters.name,
    Parameters.sortBy3
  ],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const getWorkflowByIdOperationSpec: coreClient.OperationSpec = {
  path: "/Workflows/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.GetWorkflowByIdResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const deleteWorkflowOperationSpec: coreClient.OperationSpec = {
  path: "/Workflows/{id}",
  httpMethod: "DELETE",
  responses: {
    200: {
      bodyMapper: Mappers.DeleteWorkflowResponse
    }
  },
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.accept],
  serializer
};
const updateWorkflowOperationSpec: coreClient.OperationSpec = {
  path: "/Workflows/{id}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.UpdateWorkflowResponse
    }
  },
  requestBody: Parameters.body62,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept],
  mediaType: "json",
  serializer
};
