import {
  OperationParameter,
  OperationQueryParameter,
  OperationURLParameter
} from "@azure/core-client";
import {
  AddAccountToUserRequest as AddAccountToUserRequestMapper,
  AddAttributeKeyBulkRequest as AddAttributeKeyBulkRequestMapper,
  AddAttributeKeyRequest as AddAttributeKeyRequestMapper,
  AddSubProductContract as AddSubProductContractMapper,
  ArchiveLocationRequest as ArchiveLocationRequestMapper,
  BulkUpdateAttributeKeyRequest as BulkUpdateAttributeKeyRequestMapper,
  ChangeCurrentAccountRequest as ChangeCurrentAccountRequestMapper,
  CreateAccountRequest as CreateAccountRequestMapper,
  CreateAggregationRequest as CreateAggregationRequestMapper,
  CreateBatchShipRequest as CreateBatchShipRequestMapper,
  CreateCommissionRequest as CreateCommissionRequestMapper,
  CreateDecommissionRequest as CreateDecommissionRequestMapper,
  CreateDisaggregationRequest as CreateDisaggregationRequestMapper,
  CreateEpcisDataRequest as CreateEpcisDataRequestMapper,
  CreateEventTemplateRequest as CreateEventTemplateRequestMapper,
  CreateExternalShipmentRequest as CreateExternalShipmentRequestMapper,
  CreateIntegrationDataRequest as CreateIntegrationDataRequestMapper,
  CreateLanguageRequest as CreateLanguageRequestMapper,
  CreateLocationRequest as CreateLocationRequestMapper,
  CreateMultiCommissionRequest as CreateMultiCommissionRequestMapper,
  CreateMultiObserveRequest as CreateMultiObserveRequestMapper,
  CreateMultiReceiveRequest as CreateMultiReceiveRequestMapper,
  CreateMultiShipRequest as CreateMultiShipRequestMapper,
  CreateNamespaceRequest as CreateNamespaceRequestMapper,
  CreateNetworkInvitationRequest as CreateNetworkInvitationRequestMapper,
  CreateObserveRequest as CreateObserveRequestMapper,
  CreateProductRequest as CreateProductRequestMapper,
  CreateReceiveRequest as CreateReceiveRequestMapper,
  CreateShipRequest as CreateShipRequestMapper,
  CreateTemplateAttributeRequest as CreateTemplateAttributeRequestMapper,
  CreateTradePartnerRequest as CreateTradePartnerRequestMapper,
  CreateTransformRequest as CreateTransformRequestMapper,
  CreateWorkflowRequest as CreateWorkflowRequestMapper,
  CreateWorkflowTemplateRequest as CreateWorkflowTemplateRequestMapper,
  DeleteAttributeKeyRequest as DeleteAttributeKeyRequestMapper,
  DeleteLanguageRequest as DeleteLanguageRequestMapper,
  InviteUserRequest as InviteUserRequestMapper,
  ReadNotificationsRequest as ReadNotificationsRequestMapper,
  RejectShipmentRequest as RejectShipmentRequestMapper,
  SendSupportEmailRequest as SendSupportEmailRequestMapper,
  SetExternalRegistryDetailsRequest as SetExternalRegistryDetailsRequestMapper,
  ShareDocumentRequest as ShareDocumentRequestMapper,
  ShareEventTemplateRequest as ShareEventTemplateRequestMapper,
  ShareLocationRequest as ShareLocationRequestMapper,
  SimpleEventQueryRequest as SimpleEventQueryRequestMapper,
  UnarchiveLocationRequest as UnarchiveLocationRequestMapper,
  UnshareDocumentRequest as UnshareDocumentRequestMapper,
  UpdateAccountRequest as UpdateAccountRequestMapper,
  UpdateAccountSubscriptionRequest as UpdateAccountSubscriptionRequestMapper,
  UpdateAttributeKeyRequest as UpdateAttributeKeyRequestMapper,
  UpdateBatchShipRequest as UpdateBatchShipRequestMapper,
  UpdateDocumentRequest as UpdateDocumentRequestMapper,
  UpdateEventTemplateRequest as UpdateEventTemplateRequestMapper,
  UpdateExternalRegistryDetailsRequest as UpdateExternalRegistryDetailsRequestMapper,
  UpdateLocationRequest as UpdateLocationRequestMapper,
  UpdateNamespaceRequest as UpdateNamespaceRequestMapper,
  UpdateProductRequest as UpdateProductRequestMapper,
  UpdateTemplateAttributeRequest as UpdateTemplateAttributeRequestMapper,
  UpdateTradePartnerRequest as UpdateTradePartnerRequestMapper,
  UpdateUserRequest as UpdateUserRequestMapper,
  UpdateWorkflowRequest as UpdateWorkflowRequestMapper,
  UpdateWorkflowTemplateRequest as UpdateWorkflowTemplateRequestMapper
} from "../models/mappers";

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String"
    }
  }
};

export const body: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateAccountRequestMapper
};

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json, text/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String"
    }
  },
  skipEncoding: true
};

export const body1: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateAccountRequestMapper
};

export const body2: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateAccountSubscriptionRequestMapper
};

export const id: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "Uuid"
    }
  }
};

export const body3: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateNetworkInvitationRequestMapper
};

export const pageNumber: OperationQueryParameter = {
  parameterPath: ["options", "pageNumber"],
  mapper: {
    serializedName: "pageNumber",
    type: {
      name: "Number"
    }
  }
};

export const pageSize: OperationQueryParameter = {
  parameterPath: ["options", "pageSize"],
  mapper: {
    serializedName: "pageSize",
    type: {
      name: "Number"
    }
  }
};

export const status: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    serializedName: "status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const networkInvitationId: OperationQueryParameter = {
  parameterPath: ["options", "networkInvitationId"],
  mapper: {
    serializedName: "networkInvitationId",
    type: {
      name: "Uuid"
    }
  }
};

export const accountId: OperationQueryParameter = {
  parameterPath: ["options", "accountId"],
  mapper: {
    serializedName: "accountId",
    type: {
      name: "Uuid"
    }
  }
};

export const incoming: OperationQueryParameter = {
  parameterPath: ["options", "incoming"],
  mapper: {
    serializedName: "incoming",
    type: {
      name: "Boolean"
    }
  }
};

export const isResentRequest: OperationQueryParameter = {
  parameterPath: ["options", "isResentRequest"],
  mapper: {
    serializedName: "isResentRequest",
    type: {
      name: "Boolean"
    }
  }
};

export const tradePartnerIds: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "tradePartnerIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const address: OperationQueryParameter = {
  parameterPath: ["options", "address"],
  mapper: {
    serializedName: "address",
    type: {
      name: "String"
    }
  }
};

export const locationName: OperationQueryParameter = {
  parameterPath: ["options", "locationName"],
  mapper: {
    serializedName: "locationName",
    type: {
      name: "String"
    }
  }
};

export const networkStatus: OperationQueryParameter = {
  parameterPath: ["options", "networkStatus"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "networkStatus",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const tradePartnerStatus: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerStatus"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "tradePartnerStatus",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const name: OperationQueryParameter = {
  parameterPath: ["options", "name"],
  mapper: {
    serializedName: "name",
    type: {
      name: "String"
    }
  }
};

export const body4: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: AddAccountToUserRequestMapper
};

export const pin: OperationQueryParameter = {
  parameterPath: ["options", "pin"],
  mapper: {
    serializedName: "pin",
    type: {
      name: "Boolean"
    }
  }
};

export const isVessel: OperationQueryParameter = {
  parameterPath: ["options", "isVessel"],
  mapper: {
    serializedName: "isVessel",
    type: {
      name: "Boolean"
    }
  }
};

export const partnerId: OperationQueryParameter = {
  parameterPath: ["options", "partnerId"],
  mapper: {
    serializedName: "partnerId",
    type: {
      name: "Uuid"
    }
  }
};

export const locationIds: OperationQueryParameter = {
  parameterPath: ["options", "locationIds"],
  mapper: {
    serializedName: "locationIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const body5: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateDocumentRequestMapper
};

export const accept1: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/octet-stream",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const status1: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const title: OperationQueryParameter = {
  parameterPath: ["options", "title"],
  mapper: {
    serializedName: "title",
    type: {
      name: "String"
    }
  }
};

export const documentType: OperationQueryParameter = {
  parameterPath: ["options", "documentType"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "documentType",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const recentActivity: OperationQueryParameter = {
  parameterPath: ["options", "recentActivity"],
  mapper: {
    serializedName: "recentActivity",
    type: {
      name: "DateTime"
    }
  }
};

export const expirationDate: OperationQueryParameter = {
  parameterPath: ["options", "expirationDate"],
  mapper: {
    serializedName: "expirationDate",
    type: {
      name: "Date"
    }
  }
};

export const blockchain: OperationQueryParameter = {
  parameterPath: ["options", "blockchain"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "blockchain",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const privacy: OperationQueryParameter = {
  parameterPath: ["options", "privacy"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "privacy",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const url: OperationQueryParameter = {
  parameterPath: ["options", "url"],
  mapper: {
    serializedName: "url",
    type: {
      name: "String"
    }
  }
};

export const locations: OperationQueryParameter = {
  parameterPath: ["options", "locations"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "locations",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const products: OperationQueryParameter = {
  parameterPath: ["options", "products"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "products",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const events: OperationQueryParameter = {
  parameterPath: ["options", "events"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "events",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const tradePartners: OperationQueryParameter = {
  parameterPath: ["options", "tradePartners"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "tradePartners",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const sharedWith: OperationQueryParameter = {
  parameterPath: ["options", "sharedWith"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "sharedWith",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const eventsHistory: OperationQueryParameter = {
  parameterPath: ["options", "eventsHistory"],
  mapper: {
    serializedName: "eventsHistory",
    type: {
      name: "Boolean"
    }
  }
};

export const contentType1: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "multipart/form-data",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String"
    }
  }
};

export const title1: OperationParameter = {
  parameterPath: ["options", "title"],
  mapper: {
    serializedName: "title",
    type: {
      name: "String"
    }
  }
};

export const file: OperationParameter = {
  parameterPath: ["options", "file"],
  mapper: {
    serializedName: "file",
    type: {
      name: "Stream"
    }
  }
};

export const privacy1: OperationParameter = {
  parameterPath: ["options", "privacy"],
  mapper: {
    serializedName: "privacy",
    type: {
      name: "String"
    }
  }
};

export const blockchain1: OperationParameter = {
  parameterPath: ["options", "blockchain"],
  mapper: {
    serializedName: "blockchain",
    type: {
      name: "String"
    }
  }
};

export const documentType1: OperationParameter = {
  parameterPath: ["options", "documentType"],
  mapper: {
    serializedName: "documentType",
    type: {
      name: "String"
    }
  }
};

export const documentTypeName: OperationParameter = {
  parameterPath: ["options", "documentTypeName"],
  mapper: {
    serializedName: "documentTypeName",
    type: {
      name: "String"
    }
  }
};

export const expirationDate1: OperationParameter = {
  parameterPath: ["options", "expirationDate"],
  mapper: {
    serializedName: "expirationDate",
    type: {
      name: "Date"
    }
  }
};

export const expirationAlertDate: OperationParameter = {
  parameterPath: ["options", "expirationAlertDate"],
  mapper: {
    serializedName: "expirationAlertDate",
    type: {
      name: "Date"
    }
  }
};

export const locationsIds: OperationParameter = {
  parameterPath: ["options", "locationsIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "locationsIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const productsIds: OperationParameter = {
  parameterPath: ["options", "productsIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "productsIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const tradePartnersIds: OperationParameter = {
  parameterPath: ["options", "tradePartnersIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "tradePartnersIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const purchaseOrderNumber: OperationParameter = {
  parameterPath: ["options", "purchaseOrderNumber"],
  mapper: {
    serializedName: "purchaseOrderNumber",
    type: {
      name: "String"
    }
  }
};

export const certificationStandard: OperationParameter = {
  parameterPath: ["options", "certificationStandard"],
  mapper: {
    serializedName: "certificationStandard",
    type: {
      name: "String"
    }
  }
};

export const certificationAgency: OperationParameter = {
  parameterPath: ["options", "certificationAgency"],
  mapper: {
    serializedName: "certificationAgency",
    type: {
      name: "String"
    }
  }
};

export const certificationId: OperationParameter = {
  parameterPath: ["options", "certificationId"],
  mapper: {
    serializedName: "certificationId",
    type: {
      name: "String"
    }
  }
};

export const shipFrom: OperationParameter = {
  parameterPath: ["options", "shipFrom"],
  mapper: {
    serializedName: "shipFrom",
    type: {
      name: "String"
    }
  }
};

export const shipTo: OperationParameter = {
  parameterPath: ["options", "shipTo"],
  mapper: {
    serializedName: "shipTo",
    type: {
      name: "String"
    }
  }
};

export const sID: OperationParameter = {
  parameterPath: ["options", "sID"],
  mapper: {
    serializedName: "sID",
    type: {
      name: "String"
    }
  }
};

export const cID: OperationParameter = {
  parameterPath: ["options", "cID"],
  mapper: {
    serializedName: "cID",
    type: {
      name: "String"
    }
  }
};

export const fOB: OperationParameter = {
  parameterPath: ["options", "fOB"],
  mapper: {
    serializedName: "fOB",
    type: {
      name: "String"
    }
  }
};

export const shipDate: OperationParameter = {
  parameterPath: ["options", "shipDate"],
  mapper: {
    serializedName: "shipDate",
    type: {
      name: "DateTime"
    }
  }
};

export const carrier: OperationParameter = {
  parameterPath: ["options", "carrier"],
  mapper: {
    serializedName: "carrier",
    type: {
      name: "String"
    }
  }
};

export const items: OperationParameter = {
  parameterPath: ["options", "items"],
  mapper: {
    serializedName: "items",
    type: {
      name: "String"
    }
  }
};

export const quantitiesOfShippedItems: OperationParameter = {
  parameterPath: ["options", "quantitiesOfShippedItems"],
  mapper: {
    serializedName: "quantitiesOfShippedItems",
    type: {
      name: "String"
    }
  }
};

export const accept2: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json, text/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const body6: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ShareDocumentRequestMapper
};

export const body7: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UnshareDocumentRequestMapper
};

export const documentId: OperationURLParameter = {
  parameterPath: "documentId",
  mapper: {
    serializedName: "documentId",
    required: true,
    type: {
      name: "Uuid"
    }
  }
};

export const body8: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateEpcisDataRequestMapper
};

export const body9: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateEventTemplateRequestMapper
};

export const sortBy: OperationQueryParameter = {
  parameterPath: ["options", "sortBy"],
  mapper: {
    serializedName: "sortBy",
    type: {
      name: "String"
    }
  }
};

export const templateName: OperationQueryParameter = {
  parameterPath: ["options", "templateName"],
  mapper: {
    serializedName: "templateName",
    type: {
      name: "String"
    }
  }
};

export const primaryId: OperationQueryParameter = {
  parameterPath: ["options", "primaryId"],
  mapper: {
    serializedName: "primaryId",
    type: {
      name: "Uuid"
    }
  }
};

export const sharedWith1: OperationQueryParameter = {
  parameterPath: ["options", "sharedWith"],
  mapper: {
    serializedName: "sharedWith",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const roles: OperationQueryParameter = {
  parameterPath: ["options", "roles"],
  mapper: {
    serializedName: "roles",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const body10: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateEventTemplateRequestMapper
};

export const body11: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ShareEventTemplateRequestMapper
};

export const templateId: OperationURLParameter = {
  parameterPath: "templateId",
  mapper: {
    serializedName: "templateId",
    required: true,
    type: {
      name: "Uuid"
    }
  }
};

export const wholechainId: OperationQueryParameter = {
  parameterPath: ["options", "wholechainId"],
  mapper: {
    serializedName: "wholechainId",
    type: {
      name: "Uuid"
    }
  }
};

export const id1: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const eventId: OperationURLParameter = {
  parameterPath: "eventId",
  mapper: {
    serializedName: "eventId",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const urns: OperationQueryParameter = {
  parameterPath: ["options", "urns"],
  mapper: {
    serializedName: "urns",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const lotSerial: OperationQueryParameter = {
  parameterPath: ["options", "lotSerial"],
  mapper: {
    serializedName: "lotSerial",
    type: {
      name: "String"
    }
  }
};

export const purchaseOrder: OperationQueryParameter = {
  parameterPath: ["options", "purchaseOrder"],
  mapper: {
    serializedName: "purchaseOrder",
    type: {
      name: "String"
    }
  }
};

export const productName: OperationQueryParameter = {
  parameterPath: ["options", "productName"],
  mapper: {
    serializedName: "productName",
    type: {
      name: "String"
    }
  }
};

export const company: OperationQueryParameter = {
  parameterPath: ["options", "company"],
  mapper: {
    serializedName: "company",
    type: {
      name: "String"
    }
  }
};

export const quantity: OperationQueryParameter = {
  parameterPath: ["options", "quantity"],
  mapper: {
    serializedName: "quantity",
    type: {
      name: "Number"
    }
  }
};

export const ticketId: OperationQueryParameter = {
  parameterPath: ["options", "ticketId"],
  mapper: {
    serializedName: "ticketId",
    type: {
      name: "String"
    }
  }
};

export const gTIN: OperationQueryParameter = {
  parameterPath: ["options", "gTIN"],
  mapper: {
    serializedName: "gTIN",
    type: {
      name: "String"
    }
  }
};

export const shipmentStatus: OperationQueryParameter = {
  parameterPath: ["options", "shipmentStatus"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "shipmentStatus",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const shippedToIds: OperationQueryParameter = {
  parameterPath: ["options", "shippedToIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "shippedToIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const shippedFromIds: OperationQueryParameter = {
  parameterPath: ["options", "shippedFromIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "shippedFromIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const eventTypes: OperationQueryParameter = {
  parameterPath: ["options", "eventTypes"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "eventTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const locationIds1: OperationQueryParameter = {
  parameterPath: ["options", "locationIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "locationIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const startEventDate: OperationQueryParameter = {
  parameterPath: ["options", "startEventDate"],
  mapper: {
    serializedName: "startEventDate",
    type: {
      name: "DateTime"
    }
  }
};

export const endEventDate: OperationQueryParameter = {
  parameterPath: ["options", "endEventDate"],
  mapper: {
    serializedName: "endEventDate",
    type: {
      name: "DateTime"
    }
  }
};

export const startLoggedDate: OperationQueryParameter = {
  parameterPath: ["options", "startLoggedDate"],
  mapper: {
    serializedName: "startLoggedDate",
    type: {
      name: "DateTime"
    }
  }
};

export const endLoggedDate: OperationQueryParameter = {
  parameterPath: ["options", "endLoggedDate"],
  mapper: {
    serializedName: "endLoggedDate",
    type: {
      name: "DateTime"
    }
  }
};

export const eventIds: OperationQueryParameter = {
  parameterPath: ["options", "eventIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "eventIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const eventTypes1: OperationQueryParameter = {
  parameterPath: ["options", "eventTypes"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "eventTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const companyIds: OperationQueryParameter = {
  parameterPath: ["options", "companyIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "companyIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const productIds: OperationQueryParameter = {
  parameterPath: ["options", "productIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "productIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const instanceId: OperationQueryParameter = {
  parameterPath: ["options", "instanceId"],
  mapper: {
    serializedName: "instanceId",
    type: {
      name: "Uuid"
    }
  }
};

export const containerId: OperationQueryParameter = {
  parameterPath: ["options", "containerId"],
  mapper: {
    serializedName: "containerId",
    type: {
      name: "Uuid"
    }
  }
};

export const body12: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateAggregationRequestMapper
};

export const body13: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateDisaggregationRequestMapper
};

export const body14: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateCommissionRequestMapper
};

export const body15: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateMultiCommissionRequestMapper
};

export const body16: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateDecommissionRequestMapper
};

export const body17: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateObserveRequestMapper
};

export const body18: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateReceiveRequestMapper
};

export const body19: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateMultiObserveRequestMapper
};

export const body20: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateShipRequestMapper
};

export const body21: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateMultiShipRequestMapper
};

export const body22: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateMultiReceiveRequestMapper
};

export const body23: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateTransformRequestMapper
};

export const body24: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateBatchShipRequestMapper
};

export const lotId: OperationQueryParameter = {
  parameterPath: ["options", "lotId"],
  mapper: {
    serializedName: "lotId",
    type: {
      name: "String"
    }
  }
};

export const dispatchDate: OperationQueryParameter = {
  parameterPath: ["options", "dispatchDate"],
  mapper: {
    serializedName: "dispatchDate",
    type: {
      name: "DateTime"
    }
  }
};

export const body25: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateBatchShipRequestMapper
};

export const eventName: OperationQueryParameter = {
  parameterPath: ["options", "eventName"],
  mapper: {
    serializedName: "eventName",
    type: {
      name: "String"
    }
  }
};

export const eventTypes2: OperationQueryParameter = {
  parameterPath: ["options", "eventTypes"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "eventTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const body26: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: SimpleEventQueryRequestMapper
};

export const eventId1: OperationQueryParameter = {
  parameterPath: ["options", "eventId"],
  mapper: {
    serializedName: "eventId",
    type: {
      name: "String"
    }
  }
};

export const body27: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: {
    serializedName: "body",
    type: {
      name: "String"
    }
  }
};

export const accept3: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "text/plain, application/json, text/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const body28: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateIntegrationDataRequestMapper
};

export const body29: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateLanguageRequestMapper
};

export const languageCode: OperationQueryParameter = {
  parameterPath: ["options", "languageCode"],
  mapper: {
    serializedName: "languageCode",
    type: {
      name: "String"
    }
  }
};

export const body30: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: DeleteLanguageRequestMapper
};

export const body31: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: AddAttributeKeyRequestMapper
};

export const attributeKeyName: OperationQueryParameter = {
  parameterPath: ["options", "attributeKeyName"],
  mapper: {
    serializedName: "attributeKeyName",
    type: {
      name: "String"
    }
  }
};

export const type: OperationQueryParameter = {
  parameterPath: ["options", "type"],
  mapper: {
    serializedName: "type",
    type: {
      name: "String"
    }
  }
};

export const body32: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateAttributeKeyRequestMapper
};

export const body33: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: DeleteAttributeKeyRequestMapper
};

export const body34: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: AddAttributeKeyBulkRequestMapper
};

export const languageId: OperationQueryParameter = {
  parameterPath: ["options", "languageId"],
  mapper: {
    serializedName: "languageId",
    type: {
      name: "Uuid"
    }
  }
};

export const type1: OperationQueryParameter = {
  parameterPath: ["options", "type"],
  mapper: {
    serializedName: "type",
    type: {
      name: "String"
    }
  }
};

export const languageCodes: OperationQueryParameter = {
  parameterPath: ["options", "languageCodes"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "languageCodes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const status2: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const attributeValue: OperationQueryParameter = {
  parameterPath: ["options", "attributeValue"],
  mapper: {
    serializedName: "attributeValue",
    type: {
      name: "String"
    }
  }
};

export const types: OperationQueryParameter = {
  parameterPath: ["options", "types"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "types",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const body35: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: BulkUpdateAttributeKeyRequestMapper
};

export const isArchived: OperationQueryParameter = {
  parameterPath: ["options", "isArchived"],
  mapper: {
    defaultValue: false,
    serializedName: "isArchived",
    type: {
      name: "Boolean"
    }
  }
};

export const body36: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateLocationRequestMapper
};

export const partnerIds: OperationQueryParameter = {
  parameterPath: ["options", "partnerIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "partnerIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const tradePartnerStatus1: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerStatus"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "tradePartnerStatus",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const archived: OperationQueryParameter = {
  parameterPath: ["options", "archived"],
  mapper: {
    serializedName: "archived",
    type: {
      name: "Boolean"
    }
  }
};

export const body37: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateLocationRequestMapper
};

export const urn: OperationQueryParameter = {
  parameterPath: ["options", "urn"],
  mapper: {
    serializedName: "urn",
    type: {
      name: "String"
    }
  }
};

export const body38: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ArchiveLocationRequestMapper
};

export const body39: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UnarchiveLocationRequestMapper
};

export const body40: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ShareLocationRequestMapper
};

export const accept4: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const pgln: OperationURLParameter = {
  parameterPath: "pgln",
  mapper: {
    serializedName: "pgln",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const gln: OperationURLParameter = {
  parameterPath: "gln",
  mapper: {
    serializedName: "gln",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const gtin: OperationURLParameter = {
  parameterPath: "gtin",
  mapper: {
    serializedName: "gtin",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const lotSerial1: OperationURLParameter = {
  parameterPath: "lotSerial",
  mapper: {
    serializedName: "lotSerial",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const body41: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateNamespaceRequestMapper
};

export const body42: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateNamespaceRequestMapper
};

export const prefix: OperationURLParameter = {
  parameterPath: "prefix",
  mapper: {
    serializedName: "prefix",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const notificationType: OperationQueryParameter = {
  parameterPath: ["options", "notificationType"],
  mapper: {
    serializedName: "notificationType",
    type: {
      name: "String"
    }
  }
};

export const currentAccountId: OperationQueryParameter = {
  parameterPath: ["options", "currentAccountId"],
  mapper: {
    serializedName: "currentAccountId",
    type: {
      name: "Uuid"
    }
  }
};

export const body43: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ReadNotificationsRequestMapper
};

export const body44: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: SendSupportEmailRequestMapper
};

export const parentProductId: OperationURLParameter = {
  parameterPath: "parentProductId",
  mapper: {
    serializedName: "parentProductId",
    required: true,
    type: {
      name: "Uuid"
    }
  }
};


export const history: OperationQueryParameter = {
  parameterPath: ["options", "history"],
  mapper: {
    serializedName: "history",
    type: {
      name: "Boolean"
    }
  }
};

export const isShipped: OperationQueryParameter = {
  parameterPath: ["options", "isShipped"],
  mapper: {
    serializedName: "isShipped",
    type: {
      name: "Boolean"
    }
  }
};

export const isAggregated: OperationQueryParameter = {
  parameterPath: ["options", "isAggregated"],
  mapper: {
    serializedName: "isAggregated",
    type: {
      name: "Boolean"
    }
  }
};

export const isConsumed: OperationQueryParameter = {
  parameterPath: ["options", "isConsumed"],
  mapper: {
    serializedName: "isConsumed",
    type: {
      name: "Boolean"
    }
  }
};

export const isSerial: OperationQueryParameter = {
  parameterPath: ["options", "isSerial"],
  mapper: {
    serializedName: "isSerial",
    type: {
      name: "Boolean"
    }
  }
};

export const eventTypes3: OperationQueryParameter = {
  parameterPath: ["options", "eventTypes"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "eventTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const parentProductName: OperationQueryParameter = {
  parameterPath: ["options", "parentProductName"],
  mapper: {
    serializedName: "parentProductName",
    type: {
      name: "String"
    }
  }
};

export const parentProductId1: OperationQueryParameter = {
  parameterPath: ["options", "parentProductId"],
  mapper: {
    serializedName: "parentProductId",
    type: {
      name: "Uuid"
    }
  }
};

export const eventTypes4: OperationQueryParameter = {
  parameterPath: ["options", "eventTypes"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "eventTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const containerId1: OperationURLParameter = {
  parameterPath: "containerId",
  mapper: {
    serializedName: "containerId",
    required: true,
    type: {
      name: "Uuid"
    }
  }
};

export const containerId11: OperationQueryParameter = {
  parameterPath: ["options", "containerId1"],
  mapper: {
    serializedName: "containerId",
    type: {
      name: "Uuid"
    }
  }
};

export const productId: OperationQueryParameter = {
  parameterPath: ["options", "productId"],
  mapper: {
    serializedName: "productId",
    type: {
      name: "Uuid"
    }
  }
};

export const body45: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateProductRequestMapper
};

export const body46: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateProductRequestMapper
};

export const productIds1: OperationQueryParameter = {
  parameterPath: ["options", "productIds"],
  mapper: {
    serializedName: "productIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const companyName: OperationQueryParameter = {
  parameterPath: ["options", "companyName"],
  mapper: {
    serializedName: "companyName",
    type: {
      name: "String"
    }
  }
};

export const productId1: OperationQueryParameter = {
  parameterPath: ["options", "productId"],
  mapper: {
    serializedName: "productId",
    type: {
      name: "String"
    }
  }
};

export const sourceProductId: OperationQueryParameter = {
  parameterPath: ["options", "sourceProductId"],
  mapper: {
    serializedName: "sourceProductId",
    type: {
      name: "Uuid"
    }
  }
};

export const associatedProductId: OperationQueryParameter = {
  parameterPath: ["options", "associatedProductId"],
  mapper: {
    serializedName: "associatedProductId",
    type: {
      name: "Uuid"
    }
  }
};

export const body47: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: AddSubProductContractMapper
};

export const productId2: OperationURLParameter = {
  parameterPath: "productId",
  mapper: {
    serializedName: "productId",
    required: true,
    type: {
      name: "Uuid"
    }
  }
};

export const subProductId: OperationURLParameter = {
  parameterPath: "subProductId",
  mapper: {
    serializedName: "subProductId",
    required: true,
    type: {
      name: "Uuid"
    }
  }
};

export const direction: OperationQueryParameter = {
  parameterPath: ["options", "direction"],
  mapper: {
    serializedName: "direction",
    type: {
      name: "String"
    }
  }
};

export const primaryId1: OperationQueryParameter = {
  parameterPath: ["options", "primaryId"],
  mapper: {
    serializedName: "primaryId",
    type: {
      name: "String"
    }
  }
};

export const status3: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const startDate: OperationQueryParameter = {
  parameterPath: ["options", "startDate"],
  mapper: {
    serializedName: "startDate",
    type: {
      name: "DateTime"
    }
  }
};

export const endDate: OperationQueryParameter = {
  parameterPath: ["options", "endDate"],
  mapper: {
    serializedName: "endDate",
    type: {
      name: "DateTime"
    }
  }
};

export const purchaseOrderNumber1: OperationQueryParameter = {
  parameterPath: ["options", "purchaseOrderNumber"],
  mapper: {
    serializedName: "purchaseOrderNumber",
    type: {
      name: "String"
    }
  }
};

export const sortBy1: OperationQueryParameter = {
  parameterPath: ["options", "sortBy"],
  mapper: {
    serializedName: "sortBy",
    type: {
      name: "String"
    }
  }
};

export const originIds: OperationQueryParameter = {
  parameterPath: ["options", "originIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "originIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const destinationIds: OperationQueryParameter = {
  parameterPath: ["options", "destinationIds"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "destinationIds",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const tradePartnerURN: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerURN"],
  mapper: {
    serializedName: "tradePartnerURN",
    type: {
      name: "String"
    }
  }
};

export const locationId: OperationQueryParameter = {
  parameterPath: ["options", "locationId"],
  mapper: {
    serializedName: "locationId",
    type: {
      name: "Uuid"
    }
  }
};

export const senderName: OperationQueryParameter = {
  parameterPath: ["options", "senderName"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "senderName",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const receiverName: OperationQueryParameter = {
  parameterPath: ["options", "receiverName"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "receiverName",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const startDate1: OperationQueryParameter = {
  parameterPath: ["options", "startDate"],
  mapper: {
    serializedName: "startDate",
    type: {
      name: "Date"
    }
  }
};

export const endDate1: OperationQueryParameter = {
  parameterPath: ["options", "endDate"],
  mapper: {
    serializedName: "endDate",
    type: {
      name: "Date"
    }
  }
};

export const body48: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: RejectShipmentRequestMapper
};

export const body49: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateExternalShipmentRequestMapper
};

export const body50: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: SetExternalRegistryDetailsRequestMapper
};

export const body51: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateExternalRegistryDetailsRequestMapper
};

export const tradePartnerId: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerId"],
  mapper: {
    serializedName: "tradePartnerId",
    type: {
      name: "String"
    }
  }
};

export const url1: OperationQueryParameter = {
  parameterPath: ["options", "url"],
  mapper: {
    serializedName: "url",
    type: {
      name: "String"
    }
  }
};

export const linkType: OperationQueryParameter = {
  parameterPath: ["options", "linkType"],
  mapper: {
    serializedName: "linkType",
    type: {
      name: "String"
    }
  }
};

export const apiKey: OperationQueryParameter = {
  parameterPath: ["options", "apiKey"],
  mapper: {
    serializedName: "apiKey",
    type: {
      name: "String"
    }
  }
};

export const body52: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateTemplateAttributeRequestMapper
};

export const fieldName: OperationQueryParameter = {
  parameterPath: ["options", "fieldName"],
  mapper: {
    serializedName: "fieldName",
    type: {
      name: "String"
    }
  }
};

export const propertyName: OperationQueryParameter = {
  parameterPath: ["options", "propertyName"],
  mapper: {
    serializedName: "propertyName",
    type: {
      name: "String"
    }
  }
};

export const fieldTypes: OperationQueryParameter = {
  parameterPath: ["options", "fieldTypes"],
  mapper: {
    serializedName: "fieldTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const values: OperationQueryParameter = {
  parameterPath: ["options", "values"],
  mapper: {
    serializedName: "values",
    type: {
      name: "String"
    }
  }
};

export const defaultValue: OperationQueryParameter = {
  parameterPath: ["options", "defaultValue"],
  mapper: {
    serializedName: "defaultValue",
    type: {
      name: "String"
    }
  }
};

export const namespaces: OperationQueryParameter = {
  parameterPath: ["options", "namespaces"],
  mapper: {
    serializedName: "namespaces",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const propertyLocations: OperationQueryParameter = {
  parameterPath: ["options", "propertyLocations"],
  mapper: {
    serializedName: "propertyLocations",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const expandValues: OperationQueryParameter = {
  parameterPath: ["options", "expandValues"],
  mapper: {
    serializedName: "expandValues",
    type: {
      name: "Boolean"
    }
  }
};

export const body53: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateTemplateAttributeRequestMapper
};

export const fieldTypes1: OperationQueryParameter = {
  parameterPath: ["options", "fieldTypes"],
  mapper: {
    serializedName: "fieldTypes",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const propertyLocations1: OperationQueryParameter = {
  parameterPath: ["options", "propertyLocations"],
  mapper: {
    serializedName: "propertyLocations",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const body54: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateTradePartnerRequestMapper
};

export const tradePartnerName: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerName"],
  mapper: {
    serializedName: "tradePartnerName",
    type: {
      name: "String"
    }
  }
};

export const tradePartnerType: OperationQueryParameter = {
  parameterPath: ["options", "tradePartnerType"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "tradePartnerType",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const body55: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateTradePartnerRequestMapper
};

export const body56: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateUserRequestMapper
};

export const email: OperationQueryParameter = {
  parameterPath: ["options", "email"],
  mapper: {
    serializedName: "email",
    type: {
      name: "String"
    }
  }
};

export const role: OperationQueryParameter = {
  parameterPath: ["options", "role"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "role",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const status4: OperationQueryParameter = {
  parameterPath: ["options", "status"],
  mapper: {
    constraints: {
      UniqueItems: true
    },
    serializedName: "status",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "String"
        }
      }
    }
  }
};

export const picture: OperationParameter = {
  parameterPath: ["options", "picture"],
  mapper: {
    serializedName: "picture",
    type: {
      name: "Stream"
    }
  }
};

export const body57: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: ChangeCurrentAccountRequestMapper
};

export const body58: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: InviteUserRequestMapper
};

export const userId: OperationQueryParameter = {
  parameterPath: ["options", "userId"],
  mapper: {
    serializedName: "userId",
    type: {
      name: "Uuid"
    }
  }
};

export const isAdmin: OperationQueryParameter = {
  parameterPath: ["options", "isAdmin"],
  mapper: {
    serializedName: "isAdmin",
    type: {
      name: "Boolean"
    }
  }
};

export const body59: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateWorkflowTemplateRequestMapper
};

export const sortBy2: OperationQueryParameter = {
  parameterPath: ["options", "sortBy"],
  mapper: {
    serializedName: "sortBy",
    type: {
      name: "String"
    }
  }
};

export const body60: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateWorkflowTemplateRequestMapper
};

export const body61: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: CreateWorkflowRequestMapper
};

export const sortBy3: OperationQueryParameter = {
  parameterPath: ["options", "sortBy"],
  mapper: {
    serializedName: "sortBy",
    type: {
      name: "String"
    }
  }
};

export const body62: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: UpdateWorkflowRequestMapper
};
