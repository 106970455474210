import { UserItem } from 'pages/Accounts/components/InviteModal/typings';
import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  DeleteInviteOptionalParams,
  InviteUserRequest,
  ListUsersOptionalParams,
  RedeemInviteOptionalParams,
  UpdateUserRequest,
  UploadProfilePictureOptionalParams,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';
import { PreferenceAPI, User } from './typings';

/* get list of users */
const fetchUsers = async (params?: ListUsersOptionalParams) => HttpClient.listUsers(params);

/* get temp user by id  */
const getTempUserById = async (id: string): Promise<User> => {
  const res = await fetch(`https://62a88a19ec36bf40bda845da.mockapi.io/users/${id}`);
  const data = await res.json();

  return data as User;
};

/* get user by id  */
export const getUserById = async (id?: string) => (id ? HttpClient.getUserById(id) : undefined);

/* update user by id  */
const updateUserById = async (id: string, user: User) => {
  const res = await fetch(`https://62a88a19ec36bf40bda845da.mockapi.io/users/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
  const data = await res.json();

  return data as User;
};

/* get user preferences */
const getUserPreferences = async () => {
  const res = await fetch(`https://run.mocky.io/v3/46228785-7a01-46e4-b819-8bc1f9ed7b98`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const data = await res.json();

  return data as PreferenceAPI;
};
/* get user preferences */
const updateUserPreferences = async (body: PreferenceAPI) => {
  const res = await fetch(`https://run.mocky.io/v3/46228785-7a01-46e4-b819-8bc1f9ed7b98`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const data = await res.json();

  return data as User;
};

/* delete user by id */
const deleteUserById = (id: string) => HttpClient.deleteUser(id);

/* update user role by id */
export const updateUserRoleById = async (id: string, body: UpdateUserRequest) =>
  HttpClient.updateUser(id, { body });

const uploadProfilePicture = async (body: UploadProfilePictureOptionalParams) =>
  HttpClient.uploadProfilePicture(body);

/* create invite */
const createInvite = async (body: InviteUserRequest) => HttpClient.inviteUser({ body });

/* redeem invite */
const redeemInvite = async (id: string, options?: RedeemInviteOptionalParams) =>
  HttpClient.redeemInvite(id, options);

/* delete invite */
const deleteInvite = async (id: string, options?: DeleteInviteOptionalParams) =>
  HttpClient.deleteInvite(id, options);

const bulkUpdateDelete = (users?: Array<UserItem>) => {
  const removedUsers = users?.filter((user) => user.isDeleted);
  const updatedUsers = users?.filter((user) => user.isUpdated && !user.isDeleted);
  const removePromises = removedUsers?.map((user) => {
    if (user?.id) {
      return deleteUserById(user.id);
    }
    return deleteInvite(user?.inviteId || '');
  });
  const updatePromises = updatedUsers?.map((user) =>
    updateUserRoleById(user?.id || '', {
      id: user?.id || '',
      role: user.role,
    }),
  );
  return Promise.all([...(removePromises || []), ...(updatePromises || [])]);
};

export const useUserById = (id: string) => useQuery(['user', id], () => getUserById(id));
export const useTempUserById = (id: string) => useQuery(['tmpuser', id], () => getTempUserById(id));
export const useUpdateUserById = (queryClient: QueryClient) =>
  useMutation(({ id, user }: { id: string; user: User }) => updateUserById(id, user), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries(['tmpuser', data?.id]);
    },
  });

/* update user role by id */
export const useUpdateUserRoleById = (queryClient: QueryClient) =>
  useMutation(
    ({ id, body }: { id: string; body: UpdateUserRequest }) => updateUserRoleById(id, body),
    {
      onSuccess: ({ id }) => {
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries(['user', id]);
      },
    },
  );

/* delete user by id */
export const useDeleteUserById = (queryClient: QueryClient) =>
  useMutation((id: string) => deleteUserById(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

/* update create invite */
export const useCreateInvite = (queryClient: QueryClient) =>
  useMutation((body: InviteUserRequest) => createInvite(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

/* use redeem invite */
export const useRedeemInvite = (queryClient: QueryClient) =>
  useMutation((id: string) => redeemInvite(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

/* use delete invite */
export const useDeleteInvite = (queryClient: QueryClient) =>
  useMutation((id: string) => deleteInvite(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });
/* use bulk  update delete */
export const useBulkUpdateDelete = (queryClient: QueryClient) =>
  useMutation((users?: Array<UserItem>) => bulkUpdateDelete(users), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

/* use update preferences */
export const useUpdatePreferences = (queryClient: QueryClient) =>
  useMutation((body: PreferenceAPI) => updateUserPreferences(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('preferences');
    },
  });

/* use UploadProfilePicture */
export const useUploadProfilePicture = (queryClient: QueryClient) =>
  useMutation((body: UploadProfilePictureOptionalParams) => uploadProfilePicture(body), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries(['user', data?.id]);
    },
  });

/* use preferences */
export const usePreferences = () => useQuery(['preferences'], () => getUserPreferences());

/* use user */
const useUsers = (params?: ListUsersOptionalParams) =>
  useQuery(['users', params], () => fetchUsers(params));

export default useUsers;
