import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { ActionsProps } from './typings';

const Actions = <ActionPayloadType extends {}>({
  actionPayload,
  buttonProps,
  menuProps,
  className,
  items,
  onClick,
  trigger,
  actionsText = 'Actions',
  disabled,
}: ActionsProps<ActionPayloadType>) => {
  if ((items?.length || 0) <= 0) {
    return null;
  }

  const onItemClick: MenuClickEventHandler = ({ key }) => {
    if (onClick) {
      onClick(key, actionPayload);
    }
  };

  return (
    <Dropdown
      className={className}
      overlay={<Menu {...menuProps} onClick={onItemClick} items={items} />}
      placement="bottomRight"
      trigger={trigger}
      disabled={disabled}
    >
      <Button type="primary" shape="round" size="middle" {...buttonProps}>
        {actionsText}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default Actions;
