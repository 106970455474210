import { google } from 'google-maps';
import { useQuery } from 'react-query';
import { Address } from './typings';

/* get places from text input from google client sdk */
export const getPlaces = async (
  text?: string,
): Promise<google.maps.places.AutocompletePrediction[] | undefined> => {
  const { maps } = window.google;
  const { places } = maps;
  const service = new places.AutocompleteService();
  return new Promise((resolve, reject) => {
    if (String(text).trim()) {
      service.getPlacePredictions({ input: text || '' }, (predictions, status) => {
        if (status !== maps.places.PlacesServiceStatus.OK) {
          reject(status);
        }
        resolve(predictions as google.maps.places.AutocompletePrediction[]);
      });
    } else {
      resolve([]);
    }
  });
};
/* get geocode from google client sdk */
export const getGeocode = async (
  placeId: string,
): Promise<google.maps.GeocoderResult[] | undefined> => {
  const { maps } = window.google;
  const { Geocoder } = maps;
  const geocoder = new Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== maps.GeocoderStatus.OK) {
        reject(status);
      }
      resolve(results as google.maps.GeocoderResult[]);
    });
  });
};

/* get geocode from google client sdk */
export const getGeocodeFromString = async (
  address: string,
): Promise<google.maps.GeocoderResult[] | undefined> => {
  const { maps } = window.google;
  const { Geocoder } = maps;
  const geocoder = new Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status !== maps.GeocoderStatus.OK) {
        reject(status);
      }
      resolve(results as google.maps.GeocoderResult[]);
    });
  });
};
/* breakdown address components to address object with line1 , line2 , city, state, country, postalCode  */
export const getAddress = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const address: Address = {};
  addressComponents.forEach((component) => {
    const longName = component.long_name;
    const { types } = component;
    if (types.includes('street_number')) {
      address.line1 = longName;
    }
    if (types.includes('route')) {
      address.line1 = address?.line1 ? `${address.line1} ${longName}` : longName;
    }
    if (types.includes('sublocality')) {
      address.line2 = longName;
    }
    if (types.includes('locality')) {
      address.city = longName;
    }
    if (types.includes('administrative_area_level_1')) {
      address.state = longName;
    }
    if (types.includes('country')) {
      address.country = longName;
    }
    if (types.includes('postal_code')) {
      address.postalCode = longName;
    }
  });
  return address;
};

const usePlaces = (text?: string) => useQuery(['places', text], () => getPlaces(text));

export default usePlaces;
