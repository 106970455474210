import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message } from 'antd';
import { useModalVisibility } from 'hooks';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from 'utils/errorHandler';
import { dateLocaleFormat } from 'utils/helpers';
import ChangeCardForm from './ChangeCard';
import { DataItem, EditBillingColumns } from './EditBilling.fields';
import { CardProps, bodyStyle } from './typings';

const data = {
  nextPaymentDue: dateLocaleFormat(new Date()),
  billingType: 'monthlyPrice',
  wcSubscriptionPrice: '123',
  scSubscriptionPrice: '123',
  paymentMethod: '....6122',
  nextInvoice: dateLocaleFormat(new Date()),
  lastPayment: dateLocaleFormat(new Date()),
  totalSubscriptionPrice: '$123',
};

const EditBillingForm: FC<CardProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.edit_billing' });
  const modalFormRef = useRef<ProFormInstance>();
  const changeCardModal = useModalVisibility(false);

  // const setAttributes = useCallback(
  //   (value: DataItem) => modalFormRef?.current?.setFieldsValue(value),
  //   [modalFormRef],
  // );

  // useEffect(() => {
  //   setAttributes(data);
  // }, [setAttributes]);

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onFinish = async (formData: DataItem) => {
    try {
      message.success(t('edit_success_text', { company: formData.billingType }));
      closeModal();
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  const onChangeCard = useCallback(() => changeCardModal?.show(), [changeCardModal]);

  return (
    <>
      {changeCardModal.visible && <ChangeCardForm modal={changeCardModal} />}
      <BetaSchemaForm<DataItem>
        layoutType="ModalForm"
        title={t('edit_modal_title')}
        formRef={modalFormRef}
        columns={EditBillingColumns({
          t,
          onChangeCard,
        })}
        grid
        open={modal.visible}
        autoFocusFirstInput
        modalProps={{
          destroyOnClose: true,
          maskClosable: false,
          bodyStyle,
          width: '120vh',
          ...modal,
          onCancel: closeModal,
        }}
        submitter={{
          searchConfig: {
            submitText: t('save'),
            resetText: t('cancel'),
          },
          submitButtonProps: {
            shape: 'round',
          },
          resetButtonProps: {
            shape: 'round',
            type: 'primary',
            ghost: true,
          },
          render: (_, defaultDoms) => defaultDoms,
        }}
        submitTimeout={2000}
        initialValues={data}
        onFinish={onFinish}
      />
    </>
  );
};

export default EditBillingForm;
