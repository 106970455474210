import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-components';
import { Col, ColProps, Row, RowProps, Space, Typography } from 'antd';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Company, { Billing, Sourceview, Subscription, Users } from './components/CardContainer';
import styles from './index.module.less';
import { headerProps } from './typings';

const colMapProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 12 };
const rowProps: RowProps = { gutter: [12, 12] };

const ViewAccountTitle: FC<headerProps> = ({ title }) => {
  const navigate = useNavigate();

  const onBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <Space>
      <ArrowLeftOutlined className={styles.font18} onClick={onBack} />
      <Typography.Text className={styles.header}>{title}</Typography.Text>
    </Space>
  );
};

const ViewAccount = () => (
  <PageContainer title={<ViewAccountTitle title="Sample" />}>
    <Company />

    <Row {...rowProps}>
      <Col {...colMapProps}>
        <Subscription />
        <Billing />
      </Col>
      <Col {...colMapProps}>
        <Sourceview />
        <Users />
      </Col>
    </Row>
  </PageContainer>
);

export default ViewAccount;
