import { ProDescriptions, ProDescriptionsProps } from '@ant-design/pro-components';
import { GDescriptionsProps } from './typings';

const GCard = <RecordType extends Record<string, any>, ValueType = 'text'>({
  children,
  ...props
}: ProDescriptionsProps<RecordType, ValueType> & GDescriptionsProps) => (
  <ProDescriptions<RecordType, ValueType> {...props}>{children}</ProDescriptions>
);

export default GCard;
