import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  CreateAccountOptionalParams,
  GetAccountOptionalParams,
  GetSubscriptionOptionalParams,
  UpdateAccountRequest,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

/**
 * Get the company account that matches the currently logged in user
 */
export const getAccount = async (params?: GetAccountOptionalParams) =>
  HttpClient.getAccount(params);

/**
 * Create company account for new sign up
 */
const createAccount = async (params?: CreateAccountOptionalParams) =>
  HttpClient.createAccount(params);

/**
 * Get account subscription
 */
const getAccountSubscription = async (params?: GetSubscriptionOptionalParams) =>
  HttpClient.getSubscription(params);

/**
 * update company account that matches the currently logged in user
 */
const updateAccount = async (body: UpdateAccountRequest) => HttpClient.updateAccount({ body });

/**
 * Queries
 */

export const useUpdateAccount = (queryClient: QueryClient) =>
  useMutation((body: UpdateAccountRequest) => updateAccount(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('account');
    },
  });
export const useCreateAccount = (queryClient: QueryClient) =>
  useMutation((body: CreateAccountOptionalParams) => createAccount(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('account');
    },
  });

export const useAccountSubscription = (params?: GetSubscriptionOptionalParams) =>
  useQuery(['subscription', params], () => getAccountSubscription(params));

export const useAccount = (params?: GetAccountOptionalParams) =>
  useQuery(['account', params], () => getAccount(params));
