import { DeleteOutlined, ToolOutlined, UploadOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-components';
import Editor from '@monaco-editor/react';
import { Button, Row, Typography, Upload, UploadProps, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllLanguages } from 'services/api/useLanguage';
import { csvtoJSON } from 'utils/helpers';
import { bulkCreateUploadAPI } from 'utils/languageAdmin';
import { DataItem, JSONUploadProps } from './typings';

const dirtyJson = require('dirty-json');

// const rowProps: RowProps = { gutter: [32, 8] };

const InviteForm: FC<JSONUploadProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'json_uploader' });
  const modalFormRef = useRef<ProFormInstance>();
  const editorRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { data: languages } = useGetAllLanguages();

  const languageList = useMemo(
    () =>
      languages?.results?.reduce((acc: any, val) => {
        const obj = {
          [val.code as string]: {
            text: [val.name],
            value: [val.code || ''],
          },
        };
        return { ...acc, ...obj };
      }, {}),
    [languages?.results],
  );

  const closeModal = useCallback(() => {
    if (!loading) {
      modalFormRef.current?.resetFields();
      modal.hide();
    }
  }, [loading, modal]);

  const onFix = useCallback(async () => {
    const editor = editorRef.current;
    if (!editor) return;
    const data = editor && editor.getValue();
    const object = dirtyJson.parse(data || '');
    const minifiedValue = JSON.stringify(JSON.parse(JSON.stringify(object)), null, '\t');
    editor.setValue(minifiedValue);
  }, []);

  const onFinish = useCallback(
    async (formData: DataItem) => {
      const editor = editorRef.current;
      if (!editor) return;
      const data = editor && editor.getValue();
      if (!data) {
        message.error(t('empty_json'));
        return;
      }
      const object = dirtyJson.parse(data || '');
      const minifiedValue = JSON.stringify(JSON.parse(JSON.stringify(object)), null, '\t');
      editor.setValue(minifiedValue);
      const loadingMessage = message.loading(t('loading_message'), 0);
      setLoading(true);
      const success = await bulkCreateUploadAPI(
        object,
        formData?.platform,
        formData?.status,
        formData?.language,
      );
      loadingMessage();
      if (success) {
        message.success(t('upload_success'));
        closeModal();
      }
      setLoading(false);
    },
    [closeModal, t],
  );

  const onClear = useCallback(async () => {
    const editor = editorRef.current;
    if (!editor) return;
    editor.setValue('');
  }, []);

  const onUpload: UploadProps['customRequest'] = async ({ file }) => {
    const fileObj = file as RcFile;
    const extension = fileObj?.name?.split('.')?.pop()?.toLowerCase();
    const editor = editorRef.current;
    if (extension === 'csv') {
      const data: any = await csvtoJSON(fileObj as File);
      const flattenKeys = data?.map(
        (item: any) => (item?.Field ? { [`${item?.Field}`]: `${item.Value}` } : {}),
        [],
      );
      const flattenPairs = Object.assign({}, ...flattenKeys);
      const object = dirtyJson.parse(JSON.stringify(flattenPairs) || '');
      const minifiedValue = JSON.stringify(JSON.parse(JSON.stringify(object)), null, '\t');
      editor.setValue(minifiedValue);
    } else {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const result = fileReader.result as string;
        if (!editor) return;
        editor.setValue(result);
      };
      fileReader.readAsText(file as Blob);
    }
  };

  const validateFile = useCallback(
    (file: RcFile) => {
      const extension = file?.name?.split('.')?.pop()?.toLowerCase(); // file extension from input file
      const isSuccess = ['json', 'csv'].indexOf(extension || '') > -1;
      if (!isSuccess) {
        message.error(t('file_type_waraning'));
      }
      return isSuccess;
    },
    [t],
  );

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      title={t('modal_title')}
      formRef={modalFormRef}
      columns={[
        {
          valueType: 'group',
          // rowProps,
          colProps: { xs: 24, sm: 24, md: 24, lg: 24 },
          columns: [
            {
              title: <Typography.Text>{t('select_label')}</Typography.Text>,
              dataIndex: 'platform',
              valueType: 'select',
              initialValue: 'DESKTOP',
              colProps: { xs: 24, sm: 24, md: 24, lg: 6 },
              fieldProps: {
                allowClear: false,
                style: { width: 150 },
              },
              valueEnum: {
                All: {
                  text: t('all'),
                  status: 'Default',
                },
                DESKTOP: {
                  text: t('desktop'),
                  status: 'Default',
                },
                MOBILE: {
                  text: t('mobile'),
                  status: 'Default',
                },
              },
            },
            {
              title: <Typography.Text>Language</Typography.Text>,
              dataIndex: 'language',
              valueType: 'select',
              initialValue: 'en',
              colProps: { xs: 24, sm: 24, md: 24, lg: 5 },
              fieldProps: {
                allowClear: false,
                style: { width: 150 },
              },
              valueEnum: languageList,
            },
            {
              title: <Typography.Text>Status</Typography.Text>,
              dataIndex: 'status',
              valueType: 'select',
              initialValue: 'PENDING',
              colProps: { xs: 24, sm: 24, md: 24, lg: 5 },
              fieldProps: {
                allowClear: false,
                style: { width: 150 },
              },
              valueEnum: {
                PENDING: {
                  text: 'Pending',
                  status: 'Default',
                },
                COMPLETED: {
                  text: 'Completed',
                  status: 'Default',
                },
              },
            },
            {
              title: <Typography.Text />,
              dataIndex: 'statusbar',
              valueType: 'text',
              colProps: { xs: 24, sm: 24, md: 24, lg: 8 },
              renderFormItem: () => (
                <Row justify="end">
                  <Upload
                    accept="application/json"
                    maxCount={1}
                    customRequest={onUpload}
                    showUploadList={false}
                    beforeUpload={validateFile}
                  >
                    <Button type="link" disabled={loading} icon={<UploadOutlined />}>
                      {t('upload')}
                    </Button>
                  </Upload>

                  <Button
                    type="link"
                    onClick={onClear}
                    disabled={loading}
                    icon={<DeleteOutlined />}
                  >
                    {t('clear')}
                  </Button>
                  <Button type="link" onClick={onFix} disabled={loading} icon={<ToolOutlined />}>
                    {t('fix')}
                  </Button>
                </Row>
              ),
            },
          ],
        },
        {
          dataIndex: 'message',
          valueType: 'text',
          renderFormItem: () => (
            <Editor
              height="50vh"
              defaultLanguage="json"
              defaultValue={`{"key":"value"}`}
              onMount={(ref: any) => {
                editorRef.current = ref;
              }}
            />
          ),
          fieldProps: {
            placeholder: t('placeholder'),
          },
        },
      ]}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        maskClosable: false,
        width: '50%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('add'),
          resetText: t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
          loading,
        },
        resetButtonProps: {
          shape: 'round',
          disabled: loading,
        },
        render: (_, defaultDoms) => defaultDoms,
      }}
      initialValues={{
        connectionType: 'BUYER',
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default InviteForm;
