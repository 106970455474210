import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getAddress, getGeocode } from 'services';
import errorHandler from 'utils/errorHandler';
import { AddEditAccountColumns, DataItem } from './AddEditAccount.fields';
import { AddAccountProps, bodyStyle } from './typings';

const AddAccountForm: FC<AddAccountProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.add_account' });
  const modalFormRef = useRef<ProFormInstance>();

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onChangePlace = useCallback(
    async (value: string, option: DefaultOptionType | DefaultOptionType[]) => {
      if (!value) {
        modalFormRef.current?.setFieldsValue({
          address: '',
          addressLine1: '',
          addressLine2: '',
          addressCity: '',
          addressState: '',
          addressCountry: '',
          addressPostalCode: '',
          coordinates: '',
        });
        return;
      }
      // @ts-ignore
      const item: window.google.maps.places.AutocompletePrediction = option?.itemProps || '';
      const geoCode = await getGeocode(item?.place_id);
      const location = geoCode?.[0]?.geometry?.location;
      const address = getAddress(geoCode?.[0]?.address_components || []);
      modalFormRef.current?.setFieldsValue({
        address: value || '',
        addressLine1: address?.line1 || '',
        addressLine2: address?.line2 || '',
        addressCity: address?.city || '',
        addressState: address?.state || '',
        addressCountry: address?.country || '',
        addressPostalCode: address?.postalCode || '',
        coordinates: `${location?.lat?.() || 0},${location?.lng?.() || 0}`,
      });
    },
    [],
  );

  const onFinish = async (formData: DataItem) => {
    try {
      message.success(t('add_success_text', { company: formData.compName }));
      closeModal();
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      title={t('modal_title')}
      formRef={modalFormRef}
      columns={AddEditAccountColumns({
        t,
        modalFormRef,
        onChangePlace,
      })}
      grid
      open={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        maskClosable: false,
        bodyStyle,
        width: '120vh',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('save'),
          resetText: t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
        render: (_, defaultDoms) => defaultDoms,
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default AddAccountForm;
