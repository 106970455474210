import { UseModalVisibilityReturnType } from 'hooks';
import { CSSProperties } from 'react';

export const bodyStyle: CSSProperties = { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' };

export const planTextStyle: CSSProperties = {
  fontSize: '16px',
  fontWeight: 600,
};

export interface SourceviewProps {
  modal: UseModalVisibilityReturnType;
}
