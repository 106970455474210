import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message } from 'antd';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from 'utils/errorHandler';
import { DataItem, EditSubscriptionColumns } from './EditSubscription.fields';
import { EditSubscriptionProps, bodyStyle } from './typings';

const data = {
  subscriptionStatus: 'Active',
  billingType: 'monthlyPrice',
  selectedPlan: 'premium',
};

const EditSubscriptionForm: FC<EditSubscriptionProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.edit_subscription' });
  const modalFormRef = useRef<ProFormInstance>();

  const setAttributes = useCallback(
    (value: DataItem) => modalFormRef?.current?.setFieldsValue(value),
    [modalFormRef],
  );

  useEffect(() => {
    setAttributes(data);
  }, [setAttributes]);

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onFinish = async (formData: DataItem) => {
    try {
      message.success(t('edit_success_text', { company: formData.selectedPlan }));
      closeModal();
    } catch (error) {
      message.error(errorHandler(error));
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      title={t('edit_modal_title')}
      formRef={modalFormRef}
      columns={EditSubscriptionColumns({
        t,
        defaultPlan: data?.selectedPlan,
      })}
      grid
      open={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        maskClosable: false,
        bodyStyle,
        width: '170vh',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('save'),
          resetText: t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
        render: (_, defaultDoms) => defaultDoms,
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default EditSubscriptionForm;
