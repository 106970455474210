import { Avatar, Menu, Spin, Typography } from 'antd';
import { useAuthenticationContext } from 'contexts';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import React from 'react';
import { useUserById } from 'services/api/useUsers';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.module.less';
import { AvatarDropdownProps } from './typings';

const AvatarDropdown: React.FC<AvatarDropdownProps> = ({ accountName }) => {
  const { currentUser, signout, isAuthenticated } = useAuthenticationContext();
  const { data: user } = useUserById(currentUser?.id || '');

  const onMenuClick: MenuClickEventHandler = React.useCallback(
    ({ key }) => {
      switch (key) {
        case 'signout':
          signout();
          break;
        default:
          break;
      }
    },
    [signout],
  );

  // TODO: use Loading component
  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!currentUser || !currentUser.displayName) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {isAuthenticated && (
        <Menu.Item key="profile" className={styles.profile}>
          <span>
            {user?.givenName || ''} {user?.surname || ''}
          </span>
          <span>{currentUser?.mail}</span>
        </Menu.Item>
      )}

      <Menu.Item key="signout" className={styles.signout}>
        Sign Out
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar
          className={!user?.profilePictureUrl ? styles.avatar : undefined}
          src={user?.profilePictureUrl || undefined}
          alt="avatar"
        >
          {user?.givenName?.[0] || 'U'}
        </Avatar>
        <Typography.Text className={styles.accounttext}>{accountName}</Typography.Text>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
