import { TablePaginationConfig } from 'antd';
import { useMemo, useState } from 'react';
import { GetAllAttributeKeysOptionalParams } from 'services/api/client/src';
import { useAllAttributeKeys } from 'services/api/useLanguage';
import { AccountType } from '../components/AccountTable/typings';

const useAccountActions = (initParam?: GetAllAttributeKeysOptionalParams) => {
  const [param, setParam] = useState<GetAllAttributeKeysOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    ...initParam,
  });
  const { data: attributes, isLoading } = useAllAttributeKeys(param);

  const records: AccountType[] = useMemo(
    () =>
      attributes?.results?.map((item) => ({
        id: item?.id || '',
        accountName: item?.name || '',
        usersCount: 5,
        plan: 'Basic',
        status: item?.status || '',
      })) || [],
    [attributes?.results],
  );

  const onTableChange = (
    params: TablePaginationConfig,
    // filter: Record<string, Array<string> | null>,
  ) => {
    const paginate = { pageNumber: params?.current || 10, pageSize: params?.pageSize || 5 };
    setParam({
      ...paginate,
    });
  };
  return {
    records,
    attributes,
    onTableChange,
    param,
    isLoading,
  };
};
export default useAccountActions;
