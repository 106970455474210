import { ProColumns } from '@ant-design/pro-components';
import { Modal } from 'antd';
import GTable from 'components/GTable';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BillingHistoryProps } from './typings';

interface DataType {
  id: string;
  invoice: string;
  billed: string;
  product: string;
  date: string;
  status: string;
}

const BillingHistoryTable = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.view_billing_history' });
  const columns: ProColumns<DataType>[] = [
    {
      title: t('col.invoice'),
      dataIndex: 'invoice',
    },
    {
      title: t('col.billed'),
      dataIndex: 'billed',
    },
    {
      title: t('col.product'),
      dataIndex: 'product',
    },
    {
      title: t('col.date'),
      dataIndex: 'date',
    },
    {
      title: t('col.status'),
      dataIndex: 'status',
    },
  ];

  return (
    <GTable<DataType>
      rowKey="id"
      columns={columns}
      value={[
        { id: '1', invoice: '1', billed: '2', product: 'admin', date: '1', status: 'Pending' },
        { id: '2', invoice: '1', billed: '2', product: 'admin', date: '2', status: 'Paid' },
      ]}
      options={{
        reload: false,
        setting: false,
      }}
      pagination={{
        defaultPageSize: 10,
        total: 0,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      }}
    />
  );
};

const BillingHistory: FC<BillingHistoryProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.view_billing_history' });

  const closeModal = useCallback(() => modal?.hide(), [modal]);

  return (
    <Modal
      {...modal}
      title={t('modal_title')}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={t('done')}
      onOk={closeModal}
    >
      <BillingHistoryTable />
    </Modal>
  );
};

export default BillingHistory;
