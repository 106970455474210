import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'config/authConfig';
import { getVariableFromEnv } from 'utils/config';

export const msalInstance = new PublicClientApplication(msalConfig);
export const msalInstanceInvite = new PublicClientApplication({
  ...msalConfig,
  auth: {
    ...msalConfig.auth,
    authority: getVariableFromEnv('IDP_AUTHORITY_INVITE'),
    redirectUri: `${window.location.origin}`,
  },
});

export { default as HttpClient } from './HttpClient';
export { default as TokenCredential } from './TokenCredential';
