import { ProCard } from '@ant-design/pro-components';
import { Radio, Typography } from 'antd';
import { FC } from 'react';
import { RadioPlanProps, bullet, radioStyle, textStyle } from './typings';

const RadioSelectPlan: FC<RadioPlanProps> = ({ onChange, t, options, defaultValue }) => (
  <Radio.Group
    optionType="button"
    defaultValue={defaultValue}
    onChange={onChange}
    options={options?.map((item) => ({
      value: item?.value,
      style: radioStyle,
      label: (
        <ProCard title={t(item?.value)} bordered headerBordered extra={item?.extra} hoverable>
          <Typography.Paragraph style={textStyle}>
            {item?.options?.map((i: string) => `\n${bullet} ${i}`)}
          </Typography.Paragraph>
        </ProCard>
      ),
    }))}
  />
);

export default RadioSelectPlan;
