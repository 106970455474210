import { MenuDataItem } from '@ant-design/pro-layout';
import { TFunction } from 'react-i18next';

const menuData = (t: TFunction<'pages', 'layout'>): Array<MenuDataItem> => [
  {
    path: '/',
    name: t('nav_one'),
    exact: true,
  },
  {
    path: '/language',
    name: t('nav_two'),
  },
];

export default menuData;
