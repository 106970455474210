import { FormInstance } from 'antd';
import { UseModalVisibilityReturnType } from 'hooks';
import { CSSProperties } from 'react';

export const bodyStyle: CSSProperties = { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' };

export interface AddAccountProps {
  modal: UseModalVisibilityReturnType;
}

export interface EditAccountProps {
  modal: UseModalVisibilityReturnType;
}

export interface UploadBrandProps {
  form: FormInstance<any>;
}
