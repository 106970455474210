import { Spin } from 'antd';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import styles from './Loading.module.less';
import { LoadingProps } from './typings';

const Loading: FunctionComponent<LoadingProps> = ({ isLoading = true, fullOpacity }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.fullopacity]: fullOpacity,
      })}
    >
      <span className={styles.spin}>
        <Spin size="large" />
      </span>
    </div>
  );
};

export default Loading;
