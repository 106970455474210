import { DefaultFooter } from '@ant-design/pro-layout';
import { Divider, Space, Typography } from 'antd';
import styles from '../index.module.less';

const Footer = () => (
  <DefaultFooter
    // @ts-ignore
    copyright={
      <Space>
        2022 Wholechain, Inc. All rights reserved. Version: {process.env.REACT_APP_VERSION}
        <span>
          <Typography.Link type="secondary" href="https://wholechain.com/privacy">
            Privacy
          </Typography.Link>
          <Divider type="vertical" />
          <Typography.Link type="secondary" href="https://wholechain.com/terms">
            Terms
          </Typography.Link>
        </span>
      </Space>
    }
    className={styles.footerbg}
  />
);

export default Footer;
