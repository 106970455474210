import { MenuDataItem, ProLayout, ProLayoutProps } from '@ant-design/pro-components';
import { BackTop } from 'antd';
import { logoSvg, smallLogoColorSvg, smallLogoSvg } from 'assets';
import { Protected } from 'components';
import menuData from 'config/menuData';
import { useWindowSize } from 'hooks';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Colors from 'themes/colors';
import RightContent from './components/RightContent';
// import { Footer } from './components';
import { Footer } from './components';
import styles from './index.module.less';

const settings: ProLayoutProps = {
  contentWidth: 'Fluid',
  iconfontUrl: '',
  layout: 'top',
  menu: { locale: true },
  title: 'Wholechain',
  colorWeak: false,
  splitMenus: false,
};

const menuItemRender = (menuItem: MenuDataItem, defaultDom: ReactNode) => {
  if (menuItem.isUrl || menuItem.children || !menuItem.path) {
    return defaultDom;
  }
  return (
    <Link className={styles.infotitle} to={menuItem.path}>
      {defaultDom}
    </Link>
  );
};

const rightContentRender = () => <RightContent settings={{ navTheme: 'dark', layout: 'side' }} />;

const GeneralLayout = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [width] = useWindowSize();
  const { t } = useTranslation('pages', { keyPrefix: 'layout' });
  const logo = useMemo(() => {
    if (!collapsed) {
      return logoSvg;
    }
    if (width < 769) {
      return smallLogoColorSvg;
    }
    return smallLogoSvg;
  }, [collapsed, width]);

  return (
    <Protected>
      <ProLayout
        token={{
          header: {
            colorBgHeader: Colors.primary,
            colorHeaderTitle: Colors.white,
            colorBgMenuItemSelected: Colors.primaryOpacity,
          },
        }}
        logo={logo}
        location={location}
        menuDataRender={() => menuData(t)}
        menuItemRender={menuItemRender}
        itemRender={() => null}
        footerRender={Footer}
        onCollapse={setCollapsed}
        rightContentRender={rightContentRender}
        {...settings}
      >
        <Outlet />
        <BackTop />
      </ProLayout>
    </Protected>
  );
};

export default GeneralLayout;
