import type { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps, RadioChangeEvent, RowProps, Typography } from 'antd';
import { RadioSelectPlan } from 'components';
import { TFunction } from 'react-i18next';
import { planTextStyle } from '../AddSourceview/typings';

const rowProps: RowProps = { gutter: [32, 8] };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps8: ColProps = { xs: 8, sm: 8, md: 8, lg: 8 };
export type DataItem = {
  subscriptionStatus: string;
  billingType: string;
  selectedPlan: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'accounts.add_account'>;
  defaultPlan?: string;
};

const options = [
  {
    value: 'basic',
    options: [
      '1 Product',
      '1 Network Location',
      '1 Company Location',
      '1 User',
      '5 Documents',
      'Shared Only Templates',
    ],
    extra: 'Free',
  },
  {
    value: 'essentials',
    options: [
      '10 Product',
      '10 Network Location',
      '1 Company Location',
      '3 User',
      '50 Documents',
      'Build/Shared Templates',
      'Interoperability',
      'Sourceview',
    ],
    extra: '$299',
  },
  {
    value: 'premium',
    options: [
      '50 Product',
      '50 Network Location',
      '2 Company Location',
      '10 User',
      '200 Documents',
      'Build/Shared Templates',
      'Interoperability',
      'Sourceview',
    ],
    extra: '$699',
  },
  {
    value: 'enterprise',
    options: [
      'Custom Products',
      'Custom Network Locations',
      'Custom Company Locations',
      'Custom Users',
      'Custom Documents',
      'Build/Shared Templates',
      'Interoperability',
      'Sourceview',
      'API Access',
    ],
    extra: '$1500',
  },
];

export const EditSubscriptionColumns = ({
  t,
  defaultPlan,
}: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    colProps: colProps24,
    rowProps,
    columns: [
      {
        title: t?.('subscription_status'),
        dataIndex: 'subscriptionStatus',
        valueType: 'select',
        colProps: colProps8,
        valueEnum: {
          Active: {
            text: t('active'),
            status: 'Default',
          },
          Pending: {
            text: t('pending'),
            status: 'Default',
          },
          Expired: {
            text: t('expired'),
            status: 'Default',
          },
          Deactivated: {
            text: t('deactivated'),
            status: 'Default',
          },
        },
        formItemProps: {
          rules: [
            {
              required: true,
              message: t?.('subscription_status_col_req'),
            },
          ],
          hasFeedback: false,
        },
        fieldProps: {
          placeholder: t?.('subscription_status'),
          disabled: true,
        },
      },
      {
        title: t('billing_type'),
        dataIndex: 'billingType',
        valueType: 'select',
        valueEnum: {
          monthlyPrice: {
            text: t('monthly_price'),
            status: 'Default',
          },
          monthlyPriceAnually: {
            text: t('monthly_price_paid_annually'),
            status: 'Default',
          },
        },
        colProps: colProps8,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t?.('billing_type_col_req'),
            },
          ],
          hasFeedback: false,
        },
        fieldProps: {
          placeholder: t('billing_type'),
        },
      },
      {
        title: <Typography.Text style={planTextStyle}>{t('selected_plan')}</Typography.Text>,
        dataIndex: 'selectedPlan',
        colProps: colProps24,
        renderFormItem: (_, __, form) => (
          <RadioSelectPlan
            options={options}
            defaultValue={defaultPlan}
            t={t}
            onChange={(e: RadioChangeEvent) => {
              form?.setFieldsValue({
                selectedPlan: e.target.value,
              });
            }}
          />
        ),
      },
    ],
  },
];
