import { RadioChangeEvent } from 'antd';
import { CSSProperties } from 'react';
import { TFunction } from 'react-i18next';

export const radioStyle: CSSProperties = {
  height: 'auto',
  paddingInline: 0,
  padding: 5,
  borderRadius: 10,
  marginRight: 10,
  borderWidth: 3,
};

export const textStyle: CSSProperties = {
  whiteSpace: 'pre-line',
};

export const bullet = '\u2022';

interface OptionItem {
  value: string;
  options: string[];
  extra: string;
}

export interface RadioPlanProps {
  onChange: (e: RadioChangeEvent) => void;
  t: TFunction<'pages', 'accounts.add_account'>;
  options: OptionItem[];
  defaultValue?: string;
}
