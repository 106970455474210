import { Col, ColProps, Row, RowProps } from 'antd';
import { FC } from 'react';
import AccountTable from './components/AccountTable';

const colMapProps: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const rowProps: RowProps = { gutter: [24, 24] };
const Dashboard: FC = () => (
  <div className="dashboardcontent">
    <Row {...rowProps}>
      <Col {...colMapProps}>
        <AccountTable />
      </Col>
    </Row>
  </div>
);

export default Dashboard;
