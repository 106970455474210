import { message } from 'antd';
import flatten from 'flat';
import { AddAttributeKeyRequest, UpdateAttributeKeyRequest } from 'services/api/client/src';
import {
  bulkUpdateAttributeKeys,
  createAttributeKey,
  createAttributeKeyBulk,
  getAllAttributeKeys,
  updateAttributeKeys,
} from 'services/api/useLanguage';
import errorHandler from './errorHandler';

/* This file is to create language attributes in database 
 1. Login with admin account 
 2. Copy paste new keys to attributeKeys
 3. Change type key as DESKTOP/MOBILE
 4. Call bulkCreateAPI function
*/

const handleEachRow = async (pair: any, platform: string, status: string, languageCode: string) => {
  const [key, value] = pair;
  const attributeData = await getAllAttributeKeys({
    attributeKeyName: key,
    types: [platform],
  });
  const attributeObj = attributeData?.results?.length
    ? attributeData?.results?.find((item) => item?.name === key)
    : undefined;
  if (attributeObj) {
    const keyPairs = [
      ...(attributeObj?.languageValues || []),
      {
        languageCode,
        value: String(value) || '',
      },
    ];
    // @ts-ignore
    const newValues = [...new Map(keyPairs.map((item) => [item.languageCode, item])).values()];

    await updateAttributeKeys({
      id: attributeObj?.id,
      attributeName: attributeObj?.name,
      types: [platform],
      newValues,
      status,
    });
  } else {
    await createAttributeKey({
      name: key || '',
      languageValues: [
        {
          languageCode,
          value: String(value) || '',
        },
      ],
      status,
      type: platform,
    });
  }
};

const bulkCreateAPI = async (attributeKeys: object, platform: string) =>
  new Promise((resolve) => {
    const status = 'PENDING';
    const languageCode = 'en';
    try {
      // pass your keys to upload to admin app
      const flattenKeys = flatten(attributeKeys);
      // @ts-ignore
      const result = Object.entries(flattenKeys).reduce(
        (p, pair) => p.then(() => handleEachRow(pair, platform, status, languageCode)),
        Promise.resolve(),
      );
      result
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          message.error(errorHandler(err));
          resolve(false);
        });
    } catch (error) {
      message.error(errorHandler(error));
      resolve(false);
    }
  });

export const bulkCreateCSVAPI = async (flattenKeys: object, platform: string) =>
  new Promise((resolve) => {
    const status = 'COMPLETED';
    const languageCode = 'pt-br';
    try {
      // pass your keys to upload to admin app
      // const flattenKeys = flatten(attributeKeys);
      // @ts-ignore
      const result = Object.entries(flattenKeys).reduce(
        (p, pair) => p.then(() => handleEachRow(pair, platform, status, languageCode)),
        Promise.resolve(),
      );
      result
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          message.error(errorHandler(err));
          resolve(false);
        });
    } catch (error) {
      message.error(errorHandler(error));
      resolve(false);
    }
  });

// This method is purly to create new entries in bulk (will not support update)
export const bulkCreateUploadAPI = async (
  attributeKeys: object,
  platform: string,
  status: string,
  languageCode: string,
) =>
  new Promise((resolve) => {
    const requestAddBody: AddAttributeKeyRequest[] = [];
    const requestUpdateBody: UpdateAttributeKeyRequest[] = [];
    try {
      // pass your keys to upload to admin app
      const flattenKeys: object = flatten(attributeKeys);
      getAllAttributeKeys({
        types: [platform],
        pageSize: 100000,
      }).then((attributeData) => {
        Object.entries(flattenKeys).forEach((pair) => {
          const [key, value] = pair;
          const attributeObj = attributeData?.results?.length
            ? attributeData?.results?.find((item) => item?.name === key)
            : undefined;
          if (attributeObj) {
            const keyPairs = [
              ...(attributeObj?.languageValues || []),
              {
                languageCode,
                value: String(value) || '',
              },
            ];

            const newValues = [
              // @ts-ignore
              ...new Map(keyPairs.map((item) => [item.languageCode, item])).values(),
            ];

            // const newValues = Array.from(new Set(keyPairs.map(JSON.stringify))).map(JSON.parse);
            requestUpdateBody?.push({
              id: attributeObj?.id,
              attributeName: attributeObj?.name,
              types: [platform],
              newValues,
              status,
            });
          } else {
            requestAddBody.push({
              name: key || '',
              languageValues: [
                {
                  languageCode,
                  value: String(value) || '',
                },
              ],
              status,
              type: platform,
            });
          }
        });
        Promise.all([
          bulkUpdateAttributeKeys({
            requests: requestUpdateBody,
          }),
          createAttributeKeyBulk({
            requests: requestAddBody,
          }),
        ])
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            message.error(errorHandler(err));
            resolve(false);
          });
      });
    } catch (error) {
      message.error(errorHandler(error));
      resolve(false);
    }
  });

export default bulkCreateAPI;
