import * as coreClient from "@azure/core-client";

export const CreateAccountRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateAccountRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      isGS1Member: {
        serializedName: "isGS1Member",
        type: {
          name: "Boolean"
        }
      },
      prefix: {
        serializedName: "prefix",
        nullable: true,
        type: {
          name: "String"
        }
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      timezone: {
        serializedName: "timezone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      }
    }
  }
};

export const Address: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Address",
    modelProperties: {
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        type: {
          name: "String"
        }
      },
      line1: {
        serializedName: "line1",
        type: {
          name: "String"
        }
      },
      line2: {
        serializedName: "line2",
        nullable: true,
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GeoCoordinates: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GeoCoordinates",
    modelProperties: {
      latitude: {
        serializedName: "latitude",
        type: {
          name: "Number"
        }
      },
      longitude: {
        serializedName: "longitude",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CreateAccountResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateAccountResponse",
    modelProperties: {
      account: {
        serializedName: "account",
        type: {
          name: "Composite",
          className: "CreateAccountResponseAccount"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "Composite",
          className: "CreateAccountResponseUser"
        }
      }
    }
  }
};

export const CreateAccountResponseAccount: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateAccountResponseAccount",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      lastModifiedBy: {
        serializedName: "lastModifiedBy",
        type: {
          name: "Uuid"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      nameSlug: {
        serializedName: "nameSlug",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      tier: {
        serializedName: "tier",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      isGS1Member: {
        serializedName: "isGS1Member",
        type: {
          name: "Boolean"
        }
      },
      prefix: {
        serializedName: "prefix",
        nullable: true,
        type: {
          name: "String"
        }
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      timezone: {
        serializedName: "timezone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      }
    }
  }
};

export const CreateAccountResponseUser: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateAccountResponseUser",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      lastModifiedBy: {
        serializedName: "lastModifiedBy",
        type: {
          name: "Uuid"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      givenName: {
        serializedName: "givenName",
        type: {
          name: "String"
        }
      },
      surname: {
        serializedName: "surname",
        type: {
          name: "String"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetAccountResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetAccountResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      lastModifiedBy: {
        serializedName: "lastModifiedBy",
        type: {
          name: "Uuid"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      nameSlug: {
        serializedName: "nameSlug",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      timezone: {
        serializedName: "timezone",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      defaultLocationId: {
        serializedName: "defaultLocationId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      defaultLocationName: {
        serializedName: "defaultLocationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselEnabled: {
        serializedName: "vesselEnabled",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UpdateAccountRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateAccountRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      timezone: {
        serializedName: "timezone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      defaultLocationId: {
        serializedName: "defaultLocationId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselEnabled: {
        serializedName: "vesselEnabled",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "UpdateAccountRequestAddress"
        }
      }
    }
  }
};

export const UpdateAccountRequestAddress: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateAccountRequestAddress",
    modelProperties: {
      line1: {
        serializedName: "line1",
        nullable: true,
        type: {
          name: "String"
        }
      },
      line2: {
        serializedName: "line2",
        nullable: true,
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        nullable: true,
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        nullable: true,
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        nullable: true,
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateAccountResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateAccountResponse",
    modelProperties: {
      company: {
        serializedName: "company",
        type: {
          name: "Composite",
          className: "UpdateAccountResponseCompany"
        }
      }
    }
  }
};

export const UpdateAccountResponseCompany: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateAccountResponseCompany",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      lastModifiedBy: {
        serializedName: "lastModifiedBy",
        type: {
          name: "Uuid"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      timezone: {
        serializedName: "timezone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tier: {
        serializedName: "tier",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      }
    }
  }
};

export const UpdateAccountSubscriptionRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateAccountSubscriptionRequest",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String"
        }
      },
      monthlyRate: {
        serializedName: "monthlyRate",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      totalProducts: {
        serializedName: "totalProducts",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      shipmentType: {
        serializedName: "shipmentType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      annualShipments: {
        serializedName: "annualShipments",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      receiveSupplierShipments: {
        serializedName: "receiveSupplierShipments",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      partnerEvents: {
        serializedName: "partnerEvents",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      totalCompanyLocation: {
        serializedName: "totalCompanyLocation",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      totalOutsideLocation: {
        serializedName: "totalOutsideLocation",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      apiAccess: {
        serializedName: "apiAccess",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      customMSA: {
        serializedName: "customMSA",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      integrations: {
        serializedName: "integrations",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      sourceviewAddon: {
        serializedName: "sourceviewAddon",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      monthlySharedDocuments: {
        serializedName: "monthlySharedDocuments",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      eventLimitation: {
        serializedName: "eventLimitation",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      totalUsers: {
        serializedName: "totalUsers",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      totalLocationInstances: {
        serializedName: "totalLocationInstances",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      templatesType: {
        serializedName: "templatesType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      templates: {
        serializedName: "templates",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      workflowsType: {
        serializedName: "workflowsType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      workflows: {
        serializedName: "workflows",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      documents: {
        serializedName: "documents",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const UpdateAccountSubscriptionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateAccountSubscriptionResponse",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      subscription: {
        serializedName: "subscription",
        type: {
          name: "Composite",
          className: "Subscription"
        }
      }
    }
  }
};

export const Subscription: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Subscription",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      lastModifiedBy: {
        serializedName: "lastModifiedBy",
        type: {
          name: "Uuid"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      deleted: {
        serializedName: "deleted",
        type: {
          name: "Boolean"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      monthlyRate: {
        serializedName: "monthlyRate",
        type: {
          name: "Number"
        }
      },
      totalProducts: {
        serializedName: "totalProducts",
        type: {
          name: "Number"
        }
      },
      shipmentType: {
        serializedName: "shipmentType",
        type: {
          name: "String"
        }
      },
      annualShipments: {
        serializedName: "annualShipments",
        type: {
          name: "Number"
        }
      },
      receiveSupplierShipments: {
        serializedName: "receiveSupplierShipments",
        type: {
          name: "Boolean"
        }
      },
      partnerEvents: {
        serializedName: "partnerEvents",
        type: {
          name: "Boolean"
        }
      },
      totalCompanyLocation: {
        serializedName: "totalCompanyLocation",
        type: {
          name: "Number"
        }
      },
      totalOutsideLocation: {
        serializedName: "totalOutsideLocation",
        type: {
          name: "Number"
        }
      },
      apiAccess: {
        serializedName: "apiAccess",
        type: {
          name: "Boolean"
        }
      },
      customMSA: {
        serializedName: "customMSA",
        type: {
          name: "Boolean"
        }
      },
      integrations: {
        serializedName: "integrations",
        type: {
          name: "Boolean"
        }
      },
      sourceviewAddon: {
        serializedName: "sourceviewAddon",
        type: {
          name: "Boolean"
        }
      },
      monthlySharedDocuments: {
        serializedName: "monthlySharedDocuments",
        type: {
          name: "Number"
        }
      },
      eventLimitation: {
        serializedName: "eventLimitation",
        type: {
          name: "Number"
        }
      },
      totalUsers: {
        serializedName: "totalUsers",
        type: {
          name: "Number"
        }
      },
      totalLocationInstances: {
        serializedName: "totalLocationInstances",
        type: {
          name: "Number"
        }
      },
      templatesType: {
        serializedName: "templatesType",
        type: {
          name: "String"
        }
      },
      templates: {
        serializedName: "templates",
        type: {
          name: "Number"
        }
      },
      workflowsType: {
        serializedName: "workflowsType",
        type: {
          name: "String"
        }
      },
      workflows: {
        serializedName: "workflows",
        type: {
          name: "Number"
        }
      },
      documents: {
        serializedName: "documents",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const GetSubscriptionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetSubscriptionResponse",
    modelProperties: {
      subscription: {
        serializedName: "subscription",
        type: {
          name: "Composite",
          className: "SubscriptionResponse"
        }
      },
      paymentInfo: {
        serializedName: "paymentInfo",
        type: {
          name: "Composite",
          className: "PaymentInfo"
        }
      },
      subscriptionDetails: {
        serializedName: "subscriptionDetails",
        type: {
          name: "Composite",
          className: "Subscription"
        }
      }
    }
  }
};

export const SubscriptionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SubscriptionResponse",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      nextPaymentAmount: {
        serializedName: "nextPaymentAmount",
        nullable: true,
        type: {
          name: "String"
        }
      },
      nextPaymentDate: {
        serializedName: "nextPaymentDate",
        nullable: true,
        type: {
          name: "Date"
        }
      }
    }
  }
};

export const PaymentInfo: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaymentInfo",
    modelProperties: {
      cardLast4: {
        serializedName: "cardLast4",
        type: {
          name: "String"
        }
      },
      billingPeriodStart: {
        serializedName: "billingPeriodStart",
        nullable: true,
        type: {
          name: "Date"
        }
      },
      billingPeriodEnd: {
        serializedName: "billingPeriodEnd",
        nullable: true,
        type: {
          name: "Date"
        }
      },
      billingType: {
        serializedName: "billingType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetAccountByIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetAccountByIdResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateNetworkInvitationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateNetworkInvitationRequest",
    modelProperties: {
      connectionType: {
        serializedName: "connectionType",
        type: {
          name: "String"
        }
      },
      wholechainId: {
        serializedName: "wholechainId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationIds: {
        serializedName: "locationIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const CreateNetworkInvitationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateNetworkInvitationResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      connectionType: {
        serializedName: "connectionType",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationIds: {
        serializedName: "locationIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const ListNetworkInvitationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListNetworkInvitationsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NetworkInvitation"
            }
          }
        }
      }
    }
  }
};

export const NetworkInvitation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NetworkInvitation",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      lastUpdated: {
        serializedName: "lastUpdated",
        type: {
          name: "DateTime"
        }
      },
      incoming: {
        serializedName: "incoming",
        type: {
          name: "Boolean"
        }
      },
      connectionType: {
        serializedName: "connectionType",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String"
        }
      },
      isResentRequest: {
        serializedName: "isResentRequest",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ListNetworkResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListNetworkResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ListNetworkResponseResult"
            }
          }
        }
      }
    }
  }
};

export const ListNetworkResponseResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListNetworkResponseResult",
    modelProperties: {
      tradePartner: {
        serializedName: "tradePartner",
        type: {
          name: "Composite",
          className: "ListNetworkResponseTradePartner"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "ListNetworkResponseLocation"
        }
      },
      invite: {
        serializedName: "invite",
        type: {
          name: "Composite",
          className: "ListNetworkResponseInvite"
        }
      },
      networkStatus: {
        serializedName: "networkStatus",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListNetworkResponseTradePartner: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListNetworkResponseTradePartner",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListNetworkResponseLocation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListNetworkResponseLocation",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      hasActiveInventory: {
        serializedName: "hasActiveInventory",
        type: {
          name: "Boolean"
        }
      },
      isShared: {
        serializedName: "isShared",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ListNetworkResponseInvite: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListNetworkResponseInvite",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListUserAccountsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListUserAccountsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UserAccount"
            }
          }
        }
      }
    }
  }
};

export const UserAccount: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UserAccount",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      pinned: {
        serializedName: "pinned",
        type: {
          name: "Boolean"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddAccountToUserRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AddAccountToUserRequest",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddAccountToUserResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AddAccountToUserResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UserAccount"
            }
          }
        }
      }
    }
  }
};

export const HasActiveInventoryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "HasActiveInventoryResponse",
    modelProperties: {
      locations: {
        serializedName: "locations",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationsWithActiveInventory"
            }
          }
        }
      }
    }
  }
};

export const LocationsWithActiveInventory: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationsWithActiveInventory",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateNetworkInvitationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateNetworkInvitationResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      lastUpdated: {
        serializedName: "lastUpdated",
        type: {
          name: "DateTime"
        }
      },
      incoming: {
        serializedName: "incoming",
        type: {
          name: "Boolean"
        }
      },
      connectionType: {
        serializedName: "connectionType",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const BizStep: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BizStep",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DocumentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DocumentResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      ownerAccount: {
        serializedName: "ownerAccount",
        type: {
          name: "Uuid"
        }
      },
      ownerAccountName: {
        serializedName: "ownerAccountName",
        type: {
          name: "String"
        }
      },
      privacy: {
        serializedName: "privacy",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "String"
        }
      },
      documentType: {
        serializedName: "documentType",
        type: {
          name: "String"
        }
      },
      documentTypeName: {
        serializedName: "documentTypeName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      recentActivity: {
        serializedName: "recentActivity",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      expirationDate: {
        serializedName: "expirationDate",
        nullable: true,
        type: {
          name: "Date"
        }
      },
      expirationAlertDate: {
        serializedName: "expirationAlertDate",
        nullable: true,
        type: {
          name: "Date"
        }
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean"
        }
      },
      locations: {
        serializedName: "locations",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentAssociation"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentAssociation"
            }
          }
        }
      },
      tradePartners: {
        serializedName: "tradePartners",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentAssociation"
            }
          }
        }
      },
      events: {
        serializedName: "events",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentAssociation"
            }
          }
        }
      },
      certificationStandard: {
        serializedName: "certificationStandard",
        nullable: true,
        type: {
          name: "String"
        }
      },
      certificationAgency: {
        serializedName: "certificationAgency",
        nullable: true,
        type: {
          name: "String"
        }
      },
      certificationId: {
        serializedName: "certificationId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrderNumber: {
        serializedName: "purchaseOrderNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SharedWith"
            }
          }
        }
      },
      shipFrom: {
        serializedName: "shipFrom",
        nullable: true,
        type: {
          name: "String"
        }
      },
      shipTo: {
        serializedName: "shipTo",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sid: {
        serializedName: "sid",
        nullable: true,
        type: {
          name: "String"
        }
      },
      cid: {
        serializedName: "cid",
        nullable: true,
        type: {
          name: "String"
        }
      },
      fob: {
        serializedName: "fob",
        nullable: true,
        type: {
          name: "String"
        }
      },
      shipDate: {
        serializedName: "shipDate",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      carrier: {
        serializedName: "carrier",
        nullable: true,
        type: {
          name: "String"
        }
      },
      items: {
        serializedName: "items",
        nullable: true,
        type: {
          name: "String"
        }
      },
      quantitiesOfShippedItems: {
        serializedName: "quantitiesOfShippedItems",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DocumentAssociation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DocumentAssociation",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SharedWith: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SharedWith",
    modelProperties: {
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid"
        }
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateDocumentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateDocumentRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      privacy: {
        serializedName: "privacy",
        nullable: true,
        type: {
          name: "String"
        }
      },
      archived: {
        serializedName: "archived",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      locationsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "locationsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      productsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "productsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      tradePartnersIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "tradePartnersIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const ListDocumentsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListDocumentsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentResponse"
            }
          }
        }
      }
    }
  }
};

export const ShareDocumentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareDocumentRequest",
    modelProperties: {
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      wholechanIds: {
        serializedName: "wholechanIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ShareDocumentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareDocumentResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShareDocumentResult"
            }
          }
        }
      }
    }
  }
};

export const ShareDocumentResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareDocumentResult",
    modelProperties: {
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid"
        }
      },
      documentId: {
        serializedName: "documentId",
        type: {
          name: "Uuid"
        }
      },
      documentTitle: {
        serializedName: "documentTitle",
        nullable: true,
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UnshareDocumentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UnshareDocumentRequest",
    modelProperties: {
      documentId: {
        serializedName: "documentId",
        type: {
          name: "Uuid"
        }
      },
      wholechainIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "wholechainIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const CreateEpcisDataRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateEpcisDataRequest",
    modelProperties: {
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateProductRequest"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExternalCreateLocationRequest"
            }
          }
        }
      },
      tradePartners: {
        serializedName: "tradePartners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateTradePartnerRequest"
            }
          }
        }
      },
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExternalEvent"
            }
          }
        }
      }
    }
  }
};

export const CreateProductRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateProductRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String"
        }
      },
      productSharingPolicy: {
        serializedName: "productSharingPolicy",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "String"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      definedUnits: {
        serializedName: "definedUnits",
        type: {
          name: "Boolean"
        }
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomMasterData: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CustomMasterData",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      namespace: {
        serializedName: "namespace",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExternalCreateLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExternalCreateLocationRequest",
    modelProperties: {
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "Contact"
        }
      },
      vesselName: {
        serializedName: "vesselName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselID: {
        serializedName: "vesselID",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselRegistration: {
        serializedName: "vesselRegistration",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselPublicRegistryLink: {
        serializedName: "vesselPublicRegistryLink",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselSatelliteTrackingAuthority: {
        serializedName: "vesselSatelliteTrackingAuthority",
        nullable: true,
        type: {
          name: "String"
        }
      },
      imoNumber: {
        serializedName: "imoNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselStateFlag: {
        serializedName: "vesselStateFlag",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unloadingPort: {
        serializedName: "unloadingPort",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Contact: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Contact",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String"
        }
      },
      telephone: {
        serializedName: "telephone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateTradePartnerRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateTradePartnerRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      urNs: {
        serializedName: "urNs",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      connectionType: {
        serializedName: "connectionType",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ExternalEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExternalEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerUrn: {
        serializedName: "productOwnerUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      infoProviderUrn: {
        serializedName: "infoProviderUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      recordTime: {
        serializedName: "recordTime",
        type: {
          name: "DateTime"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      }
    }
  }
};

export const EventCustomProperty: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventCustomProperty",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      namespace: {
        serializedName: "namespace",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      propertyLocation: {
        serializedName: "propertyLocation",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const VesselCatchInformation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "VesselCatchInformation",
    modelProperties: {
      vesselName: {
        serializedName: "vesselName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselID: {
        serializedName: "vesselID",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselFlagState: {
        serializedName: "vesselFlagState",
        nullable: true,
        type: {
          name: "String"
        }
      },
      imoNumber: {
        serializedName: "imoNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselPublicRegistry: {
        serializedName: "vesselPublicRegistry",
        nullable: true,
        type: {
          name: "String"
        }
      },
      gpsAvailability: {
        serializedName: "gpsAvailability",
        nullable: true,
        type: {
          name: "String"
        }
      },
      satelliteTrackingAuthority: {
        serializedName: "satelliteTrackingAuthority",
        nullable: true,
        type: {
          name: "String"
        }
      },
      economicZone: {
        serializedName: "economicZone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      fisheryImprovementProject: {
        serializedName: "fisheryImprovementProject",
        nullable: true,
        type: {
          name: "String"
        }
      },
      rfmoArea: {
        serializedName: "rfmoArea",
        nullable: true,
        type: {
          name: "String"
        }
      },
      subnationalPermitArea: {
        serializedName: "subnationalPermitArea",
        nullable: true,
        type: {
          name: "String"
        }
      },
      catchArea: {
        serializedName: "catchArea",
        nullable: true,
        type: {
          name: "String"
        }
      },
      fishingGearTypeCode: {
        serializedName: "fishingGearTypeCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselTripDate: {
        serializedName: "vesselTripDate",
        nullable: true,
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const CertificationInfo: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CertificationInfo",
    modelProperties: {
      certificationType: {
        serializedName: "certificationType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      certificationStandard: {
        serializedName: "certificationStandard",
        nullable: true,
        type: {
          name: "String"
        }
      },
      certificationAgency: {
        serializedName: "certificationAgency",
        nullable: true,
        type: {
          name: "String"
        }
      },
      certificationValue: {
        serializedName: "certificationValue",
        nullable: true,
        type: {
          name: "String"
        }
      },
      certificationIdentification: {
        serializedName: "certificationIdentification",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EventTemplate: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventTemplate",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      templateFields: {
        serializedName: "templateFields",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateField"
            }
          }
        }
      },
      templateDocuments: {
        serializedName: "templateDocuments",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateDocument"
            }
          }
        }
      },
      allowVesselCatch: {
        serializedName: "allowVesselCatch",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      allowCertificationDocuments: {
        serializedName: "allowCertificationDocuments",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateSharedWith"
            }
          }
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      ownerAccount: {
        serializedName: "ownerAccount",
        type: {
          name: "Uuid"
        }
      },
      ownerAccountName: {
        serializedName: "ownerAccountName",
        type: {
          name: "String"
        }
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        type: {
          name: "Uuid"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "DateTime"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TemplateField: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateField",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      propertyName: {
        serializedName: "propertyName",
        type: {
          name: "String"
        }
      },
      fieldType: {
        serializedName: "fieldType",
        type: {
          name: "String"
        }
      },
      valueOptions: {
        serializedName: "valueOptions",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      defaultValues: {
        serializedName: "defaultValues",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DefaultValue"
            }
          }
        }
      },
      defaultValue: {
        serializedName: "defaultValue",
        nullable: true,
        type: {
          name: "String"
        }
      },
      required: {
        serializedName: "required",
        type: {
          name: "Boolean"
        }
      },
      namespace: {
        serializedName: "namespace",
        type: {
          name: "Composite",
          className: "TemplateNamespace"
        }
      },
      location: {
        serializedName: "location",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DefaultValue: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DefaultValue",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TemplateNamespace: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateNamespace",
    modelProperties: {
      uri: {
        serializedName: "uri",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      prefix: {
        serializedName: "prefix",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TemplateDocument: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateDocument",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      required: {
        serializedName: "required",
        type: {
          name: "Boolean"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const TemplateSharedWith: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateSharedWith",
    modelProperties: {
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid"
        }
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateEventTemplateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateEventTemplateRequest",
    modelProperties: {
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      templateFields: {
        serializedName: "templateFields",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateField"
            }
          }
        }
      },
      templateDocuments: {
        serializedName: "templateDocuments",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateDocument"
            }
          }
        }
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateSharedWith"
            }
          }
        }
      },
      allowVesselCatch: {
        serializedName: "allowVesselCatch",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      allowCertificationDocuments: {
        serializedName: "allowCertificationDocuments",
        nullable: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const GetEventTemplatesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetEventTemplatesResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventTemplate"
            }
          }
        }
      }
    }
  }
};

export const CreateEventTemplateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateEventTemplateRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      templateFields: {
        serializedName: "templateFields",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateField"
            }
          }
        }
      },
      templateDocuments: {
        serializedName: "templateDocuments",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateDocument"
            }
          }
        }
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateSharedWith"
            }
          }
        }
      },
      allowVesselCatch: {
        serializedName: "allowVesselCatch",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      allowCertificationDocuments: {
        serializedName: "allowCertificationDocuments",
        nullable: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ShareEventTemplateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareEventTemplateRequest",
    modelProperties: {
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      wholechainIds: {
        serializedName: "wholechainIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetStatusByEventIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetStatusByEventIdResponse",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      transactionAddress: {
        serializedName: "transactionAddress",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventData: {
        serializedName: "eventData",
        type: {
          name: "String"
        }
      },
      hashedEventData: {
        serializedName: "hashedEventData",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Event: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Event",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Location: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Location",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      accountName: {
        serializedName: "accountName",
        type: {
          name: "String"
        }
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid"
        }
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSharedWIth"
            }
          }
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        type: {
          name: "String"
        }
      },
      ownerHasWholechainAccount: {
        serializedName: "ownerHasWholechainAccount",
        type: {
          name: "Boolean"
        }
      },
      ownerWholechainAccountId: {
        serializedName: "ownerWholechainAccountId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      ownerIsAccount: {
        serializedName: "ownerIsAccount",
        type: {
          name: "Boolean"
        }
      },
      urNs: {
        serializedName: "urNs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationIdentifier"
            }
          }
        }
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Uuid"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "DateTime"
        }
      },
      lastModifiedUser: {
        serializedName: "lastModifiedUser",
        type: {
          name: "Uuid"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "Contact"
        }
      },
      isVessel: {
        serializedName: "isVessel",
        type: {
          name: "Boolean"
        }
      },
      vesselName: {
        serializedName: "vesselName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselID: {
        serializedName: "vesselID",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselRegistration: {
        serializedName: "vesselRegistration",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselPublicRegistryLink: {
        serializedName: "vesselPublicRegistryLink",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselSatelliteTrackingAuthority: {
        serializedName: "vesselSatelliteTrackingAuthority",
        nullable: true,
        type: {
          name: "String"
        }
      },
      imoNumber: {
        serializedName: "imoNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselStateFlag: {
        serializedName: "vesselStateFlag",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unloadingPort: {
        serializedName: "unloadingPort",
        nullable: true,
        type: {
          name: "String"
        }
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean"
        }
      },
      hasActiveInventory: {
        serializedName: "hasActiveInventory",
        type: {
          name: "Boolean"
        }
      },
      isShared: {
        serializedName: "isShared",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const LocationSharedWIth: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationSharedWIth",
    modelProperties: {
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid"
        }
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid"
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LocationIdentifier: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationIdentifier",
    modelProperties: {
      identifierType: {
        serializedName: "identifierType",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      companyIdentifier: {
        serializedName: "companyIdentifier",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const TradePartner: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TradePartner",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      wholechainId: {
        serializedName: "wholechainId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      urNs: {
        serializedName: "urNs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TradePartnerIdentifier"
            }
          }
        }
      },
      connectionType: {
        serializedName: "connectionType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      hasWholechainAccount: {
        serializedName: "hasWholechainAccount",
        type: {
          name: "Boolean"
        }
      },
      isOwnerAccount: {
        serializedName: "isOwnerAccount",
        type: {
          name: "Boolean"
        }
      },
      typePartnerType: {
        serializedName: "typePartnerType",
        type: {
          name: "String"
        }
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        nullable: true,
        type: {
          name: "String"
        }
      },
      totalLocations: {
        serializedName: "totalLocations",
        type: {
          name: "Number"
        }
      },
      showTraceInfoModal: {
        serializedName: "showTraceInfoModal",
        nullable: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const TradePartnerIdentifier: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TradePartnerIdentifier",
    modelProperties: {
      identifierType: {
        serializedName: "identifierType",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      companyIdentifier: {
        serializedName: "companyIdentifier",
        type: {
          name: "String"
        }
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const BlockchainEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BlockchainEvent",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      transactionAddress: {
        serializedName: "transactionAddress",
        nullable: true,
        type: {
          name: "String"
        }
      },
      hashedEventData: {
        serializedName: "hashedEventData",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetAllFirstEventsOfOrphansSupplyChainsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetAllFirstEventsOfOrphansSupplyChainsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrphanEventsResponse"
            }
          }
        }
      }
    }
  }
};

export const OrphanEventsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OrphanEventsResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "String"
        }
      },
      isContainer: {
        serializedName: "isContainer",
        type: {
          name: "Boolean"
        }
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      company: {
        serializedName: "company",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      shippedTo: {
        serializedName: "shippedTo",
        nullable: true,
        type: {
          name: "String"
        }
      },
      shippedFrom: {
        serializedName: "shippedFrom",
        nullable: true,
        type: {
          name: "String"
        }
      },
      shipmentStatus: {
        serializedName: "shipmentStatus",
        nullable: true,
        type: {
          name: "String"
        }
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListEventDetailsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListEventDetailsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventDetail"
            }
          }
        }
      }
    }
  }
};

export const EventDetail: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventDetail",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "String"
        }
      },
      isContainer: {
        serializedName: "isContainer",
        type: {
          name: "Boolean"
        }
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "String"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime"
        }
      },
      eventLoggedDate: {
        serializedName: "eventLoggedDate",
        type: {
          name: "DateTime"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      },
      identifierType: {
        serializedName: "identifierType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "String"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        nullable: true,
        type: {
          name: "String"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        nullable: true,
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      }
    }
  }
};

export const GetEventHistoryDiagramResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetEventHistoryDiagramResponse",
    modelProperties: {
      nodes: {
        serializedName: "nodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeStub"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MapDiagramEdge"
            }
          }
        }
      }
    }
  }
};

export const EventNodeStub: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventNodeStub",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance"
            }
          }
        }
      },
      container: {
        serializedName: "container",
        type: {
          name: "Composite",
          className: "EventNodeContainer"
        }
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeContainer"
            }
          }
        }
      },
      originLocation: {
        serializedName: "originLocation",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      destinationLocation: {
        serializedName: "destinationLocation",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      inputProducts: {
        serializedName: "inputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance"
            }
          }
        }
      },
      outputProducts: {
        serializedName: "outputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance"
            }
          }
        }
      }
    }
  }
};

export const EventNodeInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventNodeInstance",
    modelProperties: {
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String"
        }
      },
      uom: {
        serializedName: "uom",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EventNodeContainer: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventNodeContainer",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      identifier: {
        serializedName: "identifier",
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance"
            }
          }
        }
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const MapDiagramEdge: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MapDiagramEdge",
    modelProperties: {
      source: {
        serializedName: "source",
        type: {
          name: "String"
        }
      },
      target: {
        serializedName: "target",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetEventHistoryMapResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetEventHistoryMapResponse",
    modelProperties: {
      nodes: {
        serializedName: "nodes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MapNode"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MapDiagramEdge"
            }
          }
        }
      }
    }
  }
};

export const MapNode: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MapNode",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      latitude: {
        serializedName: "latitude",
        type: {
          name: "Number"
        }
      },
      longitude: {
        serializedName: "longitude",
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeStub"
            }
          }
        }
      },
      isReceivePlaceholder: {
        serializedName: "isReceivePlaceholder",
        type: {
          name: "Boolean"
        }
      },
      acceptedByReceiver: {
        serializedName: "acceptedByReceiver",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CreateAggregationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateAggregationRequest",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      }
    }
  }
};

export const EventProductInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventProductInstance",
    modelProperties: {
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      remainingQuantity: {
        serializedName: "remainingQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AggregationEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AggregationEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      container: {
        serializedName: "container",
        type: {
          name: "Composite",
          className: "Container"
        }
      }
    }
  }
};

export const Container: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Container",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      identifier: {
        serializedName: "identifier",
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstanceResponse"
            }
          }
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      shipped: {
        serializedName: "shipped",
        type: {
          name: "Boolean"
        }
      },
      shipEventId: {
        serializedName: "shipEventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      shipmentType: {
        serializedName: "shipmentType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        nullable: true,
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const EventProductInstanceResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventProductInstanceResponse",
    modelProperties: {
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      parentProductId: {
        serializedName: "parentProductId",
        type: {
          name: "Uuid"
        }
      },
      parentProductName: {
        serializedName: "parentProductName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      parentProductURN: {
        serializedName: "parentProductURN",
        nullable: true,
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      uom: {
        serializedName: "uom",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      containerUrn: {
        serializedName: "containerUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      lastEventType: {
        serializedName: "lastEventType",
        type: {
          name: "String"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      definedUnits: {
        serializedName: "definedUnits",
        type: {
          name: "Boolean"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      lastEventId: {
        serializedName: "lastEventId",
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      locationAddress: {
        serializedName: "locationAddress",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateDisaggregationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateDisaggregationRequest",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        type: {
          name: "Uuid"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      }
    }
  }
};

export const DisaggregationEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DisaggregationEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      container: {
        serializedName: "container",
        type: {
          name: "Composite",
          className: "Container"
        }
      }
    }
  }
};

export const CreateCommissionRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateCommissionRequest",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NewEventProductInstance"
            }
          }
        }
      }
    }
  }
};

export const NewEventProductInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NewEventProductInstance",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommissionEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CommissionEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstanceResponse"
            }
          }
        }
      }
    }
  }
};

export const CreateMultiCommissionRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateMultiCommissionRequest",
    modelProperties: {
      commissionEvents: {
        serializedName: "commissionEvents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateCommissionRequest"
            }
          }
        }
      }
    }
  }
};

export const CreateMultiCommissionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateMultiCommissionResponse",
    modelProperties: {
      eventBatchId: {
        serializedName: "eventBatchId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateDecommissionRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateDecommissionRequest",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      decommissionType: {
        serializedName: "decommissionType",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      }
    }
  }
};

export const DecommissionEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DecommissionEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      decommissionType: {
        serializedName: "decommissionType",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstanceResponse"
            }
          }
        }
      }
    }
  }
};

export const CreateObserveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateObserveRequest",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      }
    }
  }
};

export const ObservationEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ObservationEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstanceResponse"
            }
          }
        }
      },
      containers: {
        serializedName: "containers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Container"
            }
          }
        }
      }
    }
  }
};

export const CreateReceiveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateReceiveRequest",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      externalShipmentId: {
        serializedName: "externalShipmentId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      originLocationId: {
        serializedName: "originLocationId",
        type: {
          name: "Uuid"
        }
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReceiveEventProductInstance"
            }
          }
        }
      }
    }
  }
};

export const ReceiveEventProductInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReceiveEventProductInstance",
    modelProperties: {
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      remainingQuantity: {
        serializedName: "remainingQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      shipEventId: {
        serializedName: "shipEventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      aliasProductId: {
        serializedName: "aliasProductId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      newLotId: {
        serializedName: "newLotId",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ReceiveEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReceiveEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstanceResponse"
            }
          }
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      containers: {
        serializedName: "containers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Container"
            }
          }
        }
      },
      originLocation: {
        serializedName: "originLocation",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      shippedToTradePartner: {
        serializedName: "shippedToTradePartner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      shippedFromTradePartner: {
        serializedName: "shippedFromTradePartner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      }
    }
  }
};

export const CreateMultiObserveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateMultiObserveRequest",
    modelProperties: {
      observeEvents: {
        serializedName: "observeEvents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateObserveRequest"
            }
          }
        }
      }
    }
  }
};

export const CreateMultiObserveResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateMultiObserveResponse",
    modelProperties: {
      eventBatchId: {
        serializedName: "eventBatchId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateShipRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateShipRequest",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      originLocationId: {
        serializedName: "originLocationId",
        type: {
          name: "Uuid"
        }
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      automaticReceive: {
        serializedName: "automaticReceive",
        type: {
          name: "Boolean"
        }
      },
      receiveEventTime: {
        serializedName: "receiveEventTime",
        nullable: true,
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ShipEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstanceResponse"
            }
          }
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      containers: {
        serializedName: "containers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Container"
            }
          }
        }
      },
      destinationLocation: {
        serializedName: "destinationLocation",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      shipmentType: {
        serializedName: "shipmentType",
        type: {
          name: "String"
        }
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      shippedToTradePartner: {
        serializedName: "shippedToTradePartner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      shippedFromTradePartner: {
        serializedName: "shippedFromTradePartner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      }
    }
  }
};

export const CreateMultiShipRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateMultiShipRequest",
    modelProperties: {
      shipEvents: {
        serializedName: "shipEvents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateShipRequest"
            }
          }
        }
      }
    }
  }
};

export const CreateMultiShipResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateMultiShipResponse",
    modelProperties: {
      eventBatchId: {
        serializedName: "eventBatchId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateMultiReceiveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateMultiReceiveRequest",
    modelProperties: {
      receiveEvents: {
        serializedName: "receiveEvents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateReceiveRequest"
            }
          }
        }
      }
    }
  }
};

export const CreateMultiReceiveResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateMultiReceiveResponse",
    modelProperties: {
      eventBatchId: {
        serializedName: "eventBatchId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateTransformRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateTransformRequest",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      inputProducts: {
        serializedName: "inputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      outputProducts: {
        serializedName: "outputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NewEventProductInstance"
            }
          }
        }
      },
      isTransformAfterExternalReceive: {
        serializedName: "isTransformAfterExternalReceive",
        nullable: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const TransformEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TransformEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      inputProducts: {
        serializedName: "inputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstanceResponse"
            }
          }
        }
      },
      outputProducts: {
        serializedName: "outputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstanceResponse"
            }
          }
        }
      },
      transformationId: {
        serializedName: "transformationId",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateBatchShipRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateBatchShipRequest",
    modelProperties: {
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventDate: {
        serializedName: "eventDate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTimezone: {
        serializedName: "eventTimezone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        nullable: true,
        type: {
          name: "String"
        }
      },
      ticketItems: {
        serializedName: "ticketItems",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TicketItem"
            }
          }
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "TicketShipLocation"
        }
      },
      destination: {
        serializedName: "destination",
        type: {
          name: "Composite",
          className: "TicketShipLocation"
        }
      }
    }
  }
};

export const TicketItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TicketItem",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      isLot: {
        serializedName: "isLot",
        type: {
          name: "Boolean"
        }
      },
      currentInventory: {
        serializedName: "currentInventory",
        type: {
          name: "Number"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      instanceInventory: {
        serializedName: "instanceInventory",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "String"
        }
      },
      definedUnits: {
        serializedName: "definedUnits",
        type: {
          name: "Boolean"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "TicketShipLocation"
        }
      },
      destination: {
        serializedName: "destination",
        type: {
          name: "Composite",
          className: "TicketShipLocation"
        }
      }
    }
  }
};

export const TicketShipLocation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TicketShipLocation",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationAddress: {
        serializedName: "locationAddress",
        type: {
          name: "Composite",
          className: "Address"
        }
      }
    }
  }
};

export const ShipTicket: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipTicket",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      ticketId: {
        serializedName: "ticketId",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      totalProducts: {
        serializedName: "totalProducts",
        type: {
          name: "Number"
        }
      },
      totalLots: {
        serializedName: "totalLots",
        type: {
          name: "Number"
        }
      },
      dispatchDate: {
        serializedName: "dispatchDate",
        type: {
          name: "DateTime"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      ticketItems: {
        serializedName: "ticketItems",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TicketItem"
            }
          }
        }
      },
      documentIds: {
        serializedName: "documentIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      eventTime: {
        serializedName: "eventTime",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventDate: {
        serializedName: "eventDate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "TicketShipLocation"
        }
      },
      destination: {
        serializedName: "destination",
        type: {
          name: "Composite",
          className: "TicketShipLocation"
        }
      }
    }
  }
};

export const ListBatchShipResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListBatchShipResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShipTicket"
            }
          }
        }
      }
    }
  }
};

export const UpdateBatchShipRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateBatchShipRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      status: {
        serializedName: "status",
        nullable: true,
        type: {
          name: "String"
        }
      },
      dispatchDate: {
        serializedName: "dispatchDate",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventDate: {
        serializedName: "eventDate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTimezone: {
        serializedName: "eventTimezone",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        nullable: true,
        type: {
          name: "String"
        }
      },
      ticketItems: {
        serializedName: "ticketItems",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TicketItem"
            }
          }
        }
      },
      origin: {
        serializedName: "origin",
        type: {
          name: "Composite",
          className: "TicketShipLocation"
        }
      },
      destination: {
        serializedName: "destination",
        type: {
          name: "Composite",
          className: "TicketShipLocation"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const GetDashboardEventsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetDashboardEventsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DashboardEventsResponse"
            }
          }
        }
      }
    }
  }
};

export const DashboardEventsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DashboardEventsResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "String"
        }
      },
      isContainer: {
        serializedName: "isContainer",
        type: {
          name: "Boolean"
        }
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SimpleEventQueryRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SimpleEventQueryRequest",
    modelProperties: {
      request: {
        serializedName: "request",
        type: {
          name: "Composite",
          className: "SimpleEpcisEventQueryRequest"
        }
      }
    }
  }
};

export const SimpleEpcisEventQueryRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SimpleEpcisEventQueryRequest",
    modelProperties: {
      lERecordTime: {
        serializedName: "lE_recordTime",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      gERecordTime: {
        serializedName: "gE_recordTime",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      lEEventTime: {
        serializedName: "lE_eventTime",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      gEEventTime: {
        serializedName: "gE_eventTime",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      matcHAnyEPC: {
        serializedName: "matcH_anyEPC",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      matcHAnyEPCClass: {
        serializedName: "matcH_anyEPCClass",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      eQBizStep: {
        serializedName: "eQ_bizStep",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SimpleEventQueryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SimpleEventQueryResponse",
    modelProperties: {
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Event"
            }
          }
        }
      }
    }
  }
};

export const EventNode: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventNode",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productOwner: {
        serializedName: "productOwner",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      informationProvider: {
        serializedName: "informationProvider",
        type: {
          name: "Composite",
          className: "TradePartner"
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentIds: {
        serializedName: "documentIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customProperties: {
        serializedName: "customProperties",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      businessStep: {
        serializedName: "businessStep",
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainEvent"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance"
            }
          }
        }
      },
      inputProducts: {
        serializedName: "inputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance"
            }
          }
        }
      },
      outputProducts: {
        serializedName: "outputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance"
            }
          }
        }
      },
      container: {
        serializedName: "container",
        type: {
          name: "Composite",
          className: "EventNodeContainer"
        }
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeContainer"
            }
          }
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      originLocation: {
        serializedName: "originLocation",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      destinationLocation: {
        serializedName: "destinationLocation",
        type: {
          name: "Composite",
          className: "Location"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EventBatch: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventBatch",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      lastModifiedBy: {
        serializedName: "lastModifiedBy",
        type: {
          name: "Uuid"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      deleted: {
        serializedName: "deleted",
        type: {
          name: "Boolean"
        }
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "String"
        }
      },
      apiKey: {
        serializedName: "apiKey",
        type: {
          name: "String"
        }
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      statusQueryGetUri: {
        serializedName: "statusQueryGetUri",
        type: {
          name: "String"
        }
      },
      restartPostUri: {
        serializedName: "restartPostUri",
        type: {
          name: "String"
        }
      },
      terminatePostUri: {
        serializedName: "terminatePostUri",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateIntegrationDataRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateIntegrationDataRequest",
    modelProperties: {
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IntegrationProduct"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IntegrationLocation"
            }
          }
        }
      },
      tradePartners: {
        serializedName: "tradePartners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IntegrationTradePartner"
            }
          }
        }
      },
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IntegrationEvent"
            }
          }
        }
      }
    }
  }
};

export const IntegrationProduct: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IntegrationProduct",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String"
        }
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "String"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      blockchain: {
        serializedName: "blockchain",
        nullable: true,
        type: {
          name: "String"
        }
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      definedUnits: {
        serializedName: "definedUnits",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      productSharingPolicy: {
        serializedName: "productSharingPolicy",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IntegrationLocation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IntegrationLocation",
    modelProperties: {
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "Contact"
        }
      },
      vesselName: {
        serializedName: "vesselName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselID: {
        serializedName: "vesselID",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselRegistration: {
        serializedName: "vesselRegistration",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselPublicRegistryLink: {
        serializedName: "vesselPublicRegistryLink",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselSatelliteTrackingAuthority: {
        serializedName: "vesselSatelliteTrackingAuthority",
        nullable: true,
        type: {
          name: "String"
        }
      },
      imoNumber: {
        serializedName: "imoNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselStateFlag: {
        serializedName: "vesselStateFlag",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unloadingPort: {
        serializedName: "unloadingPort",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IntegrationTradePartner: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IntegrationTradePartner",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      pgln: {
        serializedName: "pgln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      connectionType: {
        serializedName: "connectionType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IntegrationEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IntegrationEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      invoiceNumber: {
        serializedName: "invoiceNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      recordTime: {
        serializedName: "recordTime",
        type: {
          name: "DateTime"
        }
      },
      tradePartner: {
        serializedName: "tradePartner",
        type: {
          name: "Composite",
          className: "IntegrationTradePartner"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      }
    }
  }
};

export const CreateLanguageRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateLanguageRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      },
      emailTemplateIds: {
        serializedName: "emailTemplateIds",
        type: {
          name: "Composite",
          className: "EmailTemplateIds"
        }
      }
    }
  }
};

export const EmailTemplateIds: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EmailTemplateIds",
    modelProperties: {
      sharedTemplate: {
        serializedName: "sharedTemplate",
        type: {
          name: "String"
        }
      },
      sharedDocument: {
        serializedName: "sharedDocument",
        type: {
          name: "String"
        }
      },
      rejectedNetworkInvite: {
        serializedName: "rejectedNetworkInvite",
        type: {
          name: "String"
        }
      },
      acceptedNetworkInvite: {
        serializedName: "acceptedNetworkInvite",
        type: {
          name: "String"
        }
      },
      newNetworkInvite: {
        serializedName: "newNetworkInvite",
        type: {
          name: "String"
        }
      },
      rejectedShipment: {
        serializedName: "rejectedShipment",
        type: {
          name: "String"
        }
      },
      receivedShipment: {
        serializedName: "receivedShipment",
        type: {
          name: "String"
        }
      },
      inboundShipment: {
        serializedName: "inboundShipment",
        type: {
          name: "String"
        }
      },
      userInvite: {
        serializedName: "userInvite",
        type: {
          name: "String"
        }
      },
      accountInvite: {
        serializedName: "accountInvite",
        type: {
          name: "String"
        }
      },
      expiringDocument: {
        serializedName: "expiringDocument",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LanguageResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LanguageResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      code: {
        serializedName: "code",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListLanguagesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListLanguagesResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LanguageResponse"
            }
          }
        }
      }
    }
  }
};

export const DeleteLanguageRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DeleteLanguageRequest",
    modelProperties: {
      languageCode: {
        serializedName: "languageCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AddAttributeKeyRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AddAttributeKeyRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        nullable: true,
        type: {
          name: "String"
        }
      },
      languageValues: {
        serializedName: "languageValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttributeValue"
            }
          }
        }
      }
    }
  }
};

export const AttributeValue: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AttributeValue",
    modelProperties: {
      languageCode: {
        serializedName: "languageCode",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AttributeKeyResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AttributeKeyResponse",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      languageCode: {
        serializedName: "languageCode",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetAttributeValueResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetAttributeValueResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttributeKeyResponse"
            }
          }
        }
      }
    }
  }
};

export const UpdateAttributeKeyRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateAttributeKeyRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      attributeName: {
        serializedName: "attributeName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      types: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "types",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      newValues: {
        serializedName: "newValues",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttributeValue"
            }
          }
        }
      },
      status: {
        serializedName: "status",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeleteAttributeKeyRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DeleteAttributeKeyRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const AddAttributeKeyBulkRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AddAttributeKeyBulkRequest",
    modelProperties: {
      requests: {
        serializedName: "requests",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AddAttributeKeyRequest"
            }
          }
        }
      }
    }
  }
};

export const ListAttributeKeysResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListAttributeKeysResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "String" } }
        }
      }
    }
  }
};

export const ListAllAttributeKeysResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListAllAttributeKeysResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ListAllAttributesResponse"
            }
          }
        }
      }
    }
  }
};

export const ListAllAttributesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListAllAttributesResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      languageValues: {
        serializedName: "languageValues",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttributeValue"
            }
          }
        }
      }
    }
  }
};

export const BulkUpdateAttributeKeyRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BulkUpdateAttributeKeyRequest",
    modelProperties: {
      requests: {
        serializedName: "requests",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UpdateAttributeKeyRequest"
            }
          }
        }
      }
    }
  }
};

export const UpdateLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateLocationRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "Contact"
        }
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      isVessel: {
        serializedName: "isVessel",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      vesselName: {
        serializedName: "vesselName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselID: {
        serializedName: "vesselID",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselRegistration: {
        serializedName: "vesselRegistration",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselPublicRegistryLink: {
        serializedName: "vesselPublicRegistryLink",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselSatelliteTrackingAuthority: {
        serializedName: "vesselSatelliteTrackingAuthority",
        nullable: true,
        type: {
          name: "String"
        }
      },
      imoNumber: {
        serializedName: "imoNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselStateFlag: {
        serializedName: "vesselStateFlag",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unloadingPort: {
        serializedName: "unloadingPort",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateLocationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateLocationResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      accountName: {
        serializedName: "accountName",
        type: {
          name: "String"
        }
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid"
        }
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSharedWIth"
            }
          }
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        type: {
          name: "String"
        }
      },
      ownerHasWholechainAccount: {
        serializedName: "ownerHasWholechainAccount",
        type: {
          name: "Boolean"
        }
      },
      ownerWholechainAccountId: {
        serializedName: "ownerWholechainAccountId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      ownerIsAccount: {
        serializedName: "ownerIsAccount",
        type: {
          name: "Boolean"
        }
      },
      urNs: {
        serializedName: "urNs",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationIdentifier"
            }
          }
        }
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Uuid"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "DateTime"
        }
      },
      lastModifiedUser: {
        serializedName: "lastModifiedUser",
        type: {
          name: "Uuid"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "Contact"
        }
      },
      isVessel: {
        serializedName: "isVessel",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      vesselName: {
        serializedName: "vesselName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselID: {
        serializedName: "vesselID",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselRegistration: {
        serializedName: "vesselRegistration",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselPublicRegistryLink: {
        serializedName: "vesselPublicRegistryLink",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselSatelliteTrackingAuthority: {
        serializedName: "vesselSatelliteTrackingAuthority",
        nullable: true,
        type: {
          name: "String"
        }
      },
      imoNumber: {
        serializedName: "imoNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselStateFlag: {
        serializedName: "vesselStateFlag",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unloadingPort: {
        serializedName: "unloadingPort",
        nullable: true,
        type: {
          name: "String"
        }
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ListLocationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListLocationsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Location"
            }
          }
        }
      }
    }
  }
};

export const CreateLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateLocationRequest",
    modelProperties: {
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "Contact"
        }
      },
      isVessel: {
        serializedName: "isVessel",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      vesselName: {
        serializedName: "vesselName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselID: {
        serializedName: "vesselID",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselRegistration: {
        serializedName: "vesselRegistration",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselPublicRegistryLink: {
        serializedName: "vesselPublicRegistryLink",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselSatelliteTrackingAuthority: {
        serializedName: "vesselSatelliteTrackingAuthority",
        nullable: true,
        type: {
          name: "String"
        }
      },
      imoNumber: {
        serializedName: "imoNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      vesselStateFlag: {
        serializedName: "vesselStateFlag",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unloadingPort: {
        serializedName: "unloadingPort",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const NetworkLocationDetailsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NetworkLocationDetailsResponse",
    modelProperties: {
      shipmentsReceived: {
        serializedName: "shipmentsReceived",
        type: {
          name: "Number"
        }
      },
      totalEvents: {
        serializedName: "totalEvents",
        type: {
          name: "Number"
        }
      },
      shipmentsSent: {
        serializedName: "shipmentsSent",
        type: {
          name: "Number"
        }
      },
      connectionType: {
        serializedName: "connectionType",
        type: {
          name: "String"
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "Contact"
        }
      },
      ownerWholechainId: {
        serializedName: "ownerWholechainId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ArchiveLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ArchiveLocationRequest",
    modelProperties: {
      archiveVessels: {
        serializedName: "archiveVessels",
        type: {
          name: "Composite",
          className: "ArchiveVessels"
        }
      },
      locationIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "locationIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const ArchiveVessels: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ArchiveVessels",
    modelProperties: {
      allTradePartners: {
        serializedName: "allTradePartners",
        type: {
          name: "Boolean"
        }
      },
      tradePartnerIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "tradePartnerIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const ArchiveLocationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ArchiveLocationResponse",
    modelProperties: {
      locationIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "locationIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const UnarchiveLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UnarchiveLocationRequest",
    modelProperties: {
      locationIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "locationIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const UnarchiveLocationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UnarchiveLocationResponse",
    modelProperties: {
      locationsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "locationsIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const ShareLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareLocationRequest",
    modelProperties: {
      locationIds: {
        serializedName: "locationIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      wholechanIds: {
        serializedName: "wholechanIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ShareLocationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareLocationResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShareLocationResult"
            }
          }
        }
      }
    }
  }
};

export const ShareLocationResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareLocationResult",
    modelProperties: {
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Product: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Product",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      accountName: {
        serializedName: "accountName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      definedUnits: {
        serializedName: "definedUnits",
        type: {
          name: "Boolean"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "String"
        }
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sharingPolicy: {
        serializedName: "sharingPolicy",
        type: {
          name: "String"
        }
      },
      currentInventory: {
        serializedName: "currentInventory",
        type: {
          name: "Number"
        }
      },
      shippedInventory: {
        serializedName: "shippedInventory",
        type: {
          name: "Number"
        }
      },
      currentInventoryTotal: {
        serializedName: "currentInventoryTotal",
        type: {
          name: "Number"
        }
      },
      created: {
        serializedName: "created",
        type: {
          name: "DateTime"
        }
      },
      creator: {
        serializedName: "creator",
        type: {
          name: "Uuid"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "DateTime"
        }
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        type: {
          name: "Uuid"
        }
      },
      recentActivity: {
        serializedName: "recentActivity",
        type: {
          name: "DateTime"
        }
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean"
        }
      },
      pendingShipmentItems: {
        serializedName: "pendingShipmentItems",
        type: {
          name: "Number"
        }
      },
      associatedProducts: {
        serializedName: "associatedProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AssociatedProduct"
            }
          }
        }
      },
      subProducts: {
        serializedName: "subProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubProduct"
            }
          }
        }
      }
    }
  }
};

export const AssociatedProduct: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AssociatedProduct",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SubProduct: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SubProduct",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ProductInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductInstance",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      accountName: {
        serializedName: "accountName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      definedUnits: {
        serializedName: "definedUnits",
        type: {
          name: "Boolean"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "String"
        }
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String"
        }
      },
      parentProductId: {
        serializedName: "parentProductId",
        type: {
          name: "Uuid"
        }
      },
      instanceURN: {
        serializedName: "instanceURN",
        type: {
          name: "Composite",
          className: "ProductInstanceIdentifier"
        }
      },
      consumed: {
        serializedName: "consumed",
        type: {
          name: "Boolean"
        }
      },
      shipped: {
        serializedName: "shipped",
        type: {
          name: "Boolean"
        }
      },
      shipEventId: {
        serializedName: "shipEventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      shipmentType: {
        serializedName: "shipmentType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      initialQuantity: {
        serializedName: "initialQuantity",
        type: {
          name: "Number"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      lastEventId: {
        serializedName: "lastEventId",
        type: {
          name: "String"
        }
      },
      lastEventType: {
        serializedName: "lastEventType",
        type: {
          name: "String"
        }
      },
      lastEventDate: {
        serializedName: "lastEventDate",
        type: {
          name: "DateTime"
        }
      },
      lastEventLoggedDate: {
        serializedName: "lastEventLoggedDate",
        type: {
          name: "DateTime"
        }
      },
      lastEventPurchaseOrder: {
        serializedName: "lastEventPurchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      lastEvent: {
        serializedName: "lastEvent",
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      inContainer: {
        serializedName: "inContainer",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        nullable: true,
        type: {
          name: "String"
        }
      },
      parentProductInventory: {
        serializedName: "parentProductInventory",
        type: {
          name: "Number"
        }
      },
      superseded: {
        serializedName: "superseded",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      parentProductUrn: {
        serializedName: "parentProductUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      remainingQuantity: {
        serializedName: "remainingQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ProductInstanceIdentifier: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductInstanceIdentifier",
    modelProperties: {
      identifierType: {
        serializedName: "identifierType",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      companyIdentifier: {
        serializedName: "companyIdentifier",
        type: {
          name: "String"
        }
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid"
        }
      },
      instanceIdentifier: {
        serializedName: "instanceIdentifier",
        type: {
          name: "String"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Namespace: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Namespace",
    modelProperties: {
      prefix: {
        serializedName: "prefix",
        type: {
          name: "String"
        }
      },
      uri: {
        serializedName: "uri",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateNamespaceRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateNamespaceRequest",
    modelProperties: {
      prefix: {
        serializedName: "prefix",
        type: {
          name: "String"
        }
      },
      uri: {
        serializedName: "uri",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateNamespaceRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateNamespaceRequest",
    modelProperties: {
      prefix: {
        serializedName: "prefix",
        type: {
          name: "String"
        }
      },
      uri: {
        serializedName: "uri",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListUserNotificationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListUserNotificationsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationItem"
            }
          }
        }
      },
      unreadAmount: {
        serializedName: "unreadAmount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const NotificationItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NotificationItem",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      url: {
        serializedName: "url",
        nullable: true,
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      read: {
        serializedName: "read",
        type: {
          name: "Boolean"
        }
      },
      actor: {
        serializedName: "actor",
        type: {
          name: "Composite",
          className: "Actor"
        }
      },
      kind: {
        serializedName: "kind",
        nullable: true,
        type: {
          name: "String"
        }
      },
      action: {
        serializedName: "action",
        type: {
          name: "Composite",
          className: "Action"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      translationParameters: {
        serializedName: "translationParameters",
        type: {
          name: "Composite",
          className: "TranslationCodeParameters"
        }
      }
    }
  }
};

export const Actor: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Actor",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      givenName: {
        serializedName: "givenName",
        type: {
          name: "String"
        }
      },
      surname: {
        serializedName: "surname",
        type: {
          name: "String"
        }
      },
      profileImageUrl: {
        serializedName: "profileImageUrl",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Action: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Action",
    modelProperties: {
      text: {
        serializedName: "text",
        nullable: true,
        type: {
          name: "String"
        }
      },
      resourceId: {
        serializedName: "resourceId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      resourceUrl: {
        serializedName: "resourceUrl",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TranslationCodeParameters: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TranslationCodeParameters",
    modelProperties: {
      messageCode: {
        serializedName: "messageCode",
        type: {
          name: "String"
        }
      },
      messageParameters: {
        serializedName: "messageParameters",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      descriptionCode: {
        serializedName: "descriptionCode",
        type: {
          name: "String"
        }
      },
      descriptionParameters: {
        serializedName: "descriptionParameters",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const ReadNotificationsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReadNotificationsRequest",
    modelProperties: {
      notificationsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "notificationsIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const SendSupportEmailRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SendSupportEmailRequest",
    modelProperties: {
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "String"
        }
      },
      inviteId: {
        serializedName: "inviteId",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListProductInstanceQuantitiesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListProductInstanceQuantitiesResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductInstanceQuantity"
            }
          }
        }
      }
    }
  }
};

export const ProductInstanceQuantity: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductInstanceQuantity",
    modelProperties: {
      parentProductId: {
        serializedName: "parentProductId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      urn: {
        serializedName: "urn",
        type: {
          name: "String"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      lastEvent: {
        serializedName: "lastEvent",
        type: {
          name: "String"
        }
      },
      lastEventId: {
        serializedName: "lastEventId",
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      destinationId: {
        serializedName: "destinationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime"
        }
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        nullable: true,
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        nullable: true,
        type: {
          name: "String"
        }
      },
      isContainer: {
        serializedName: "isContainer",
        type: {
          name: "Boolean"
        }
      },
      inContainer: {
        serializedName: "inContainer",
        type: {
          name: "Boolean"
        }
      },
      containerItems: {
        serializedName: "containerItems",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductInstanceQuantity"
            }
          }
        }
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        type: {
          name: "String"
        }
      },
      shipmentType: {
        serializedName: "shipmentType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      shipped: {
        serializedName: "shipped",
        type: {
          name: "Boolean"
        }
      },
      consumed: {
        serializedName: "consumed",
        type: {
          name: "Boolean"
        }
      },
      definedUnits: {
        serializedName: "definedUnits",
        type: {
          name: "Boolean"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      parentProductCurrentInventoryTotal: {
        serializedName: "parentProductCurrentInventoryTotal",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ListProductsInstanceQuantitiesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListProductsInstanceQuantitiesResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductsInstanceQuantity"
            }
          }
        }
      }
    }
  }
};

export const ProductsInstanceQuantity: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductsInstanceQuantity",
    modelProperties: {
      parentProductId: {
        serializedName: "parentProductId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      lastEvent: {
        serializedName: "lastEvent",
        type: {
          name: "String"
        }
      },
      lastEventId: {
        serializedName: "lastEventId",
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String"
        }
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime"
        }
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        nullable: true,
        type: {
          name: "String"
        }
      },
      isContainer: {
        serializedName: "isContainer",
        type: {
          name: "Boolean"
        }
      },
      containerItems: {
        serializedName: "containerItems",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductsInstanceQuantity"
            }
          }
        }
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        type: {
          name: "String"
        }
      },
      shipmentType: {
        serializedName: "shipmentType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListProductInstancesInContainerResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListProductInstancesInContainerResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContainerProductInstanceQuantity"
            }
          }
        }
      },
      containerDetails: {
        serializedName: "containerDetails",
        type: {
          name: "Composite",
          className: "ContainerDetails"
        }
      }
    }
  }
};

export const ContainerProductInstanceQuantity: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContainerProductInstanceQuantity",
    modelProperties: {
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      parentProductId: {
        serializedName: "parentProductId",
        type: {
          name: "Uuid"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      currentInventory: {
        serializedName: "currentInventory",
        type: {
          name: "Number"
        }
      },
      currentInventoryTotal: {
        serializedName: "currentInventoryTotal",
        type: {
          name: "Number"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      },
      definedUnits: {
        serializedName: "definedUnits",
        type: {
          name: "Boolean"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      lastEvent: {
        serializedName: "lastEvent",
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        type: {
          name: "String"
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        type: {
          name: "String"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ContainerDetails: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContainerDetails",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      identifier: {
        serializedName: "identifier",
        type: {
          name: "String"
        }
      },
      lastEvent: {
        serializedName: "lastEvent",
        nullable: true,
        type: {
          name: "String"
        }
      },
      lastEventDate: {
        serializedName: "lastEventDate",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ListProductsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListProductsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Product"
            }
          }
        }
      }
    }
  }
};

export const UpdateProductRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateProductRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        nullable: true,
        type: {
          name: "String"
        }
      },
      customAttributes: {
        serializedName: "customAttributes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData"
            }
          }
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      productSharingPolicy: {
        serializedName: "productSharingPolicy",
        nullable: true,
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      definedUnits: {
        serializedName: "definedUnits",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      urNs: {
        serializedName: "urNs",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String"
        }
      },
      archive: {
        serializedName: "archive",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String"
        }
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateProductResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateProductResponse",
    modelProperties: {
      product: {
        serializedName: "product",
        type: {
          name: "Composite",
          className: "Product"
        }
      }
    }
  }
};

export const ListPossibleSubProductsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListPossibleSubProductsResponse",
    modelProperties: {
      subProducts: {
        serializedName: "subProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubProduct"
            }
          }
        }
      }
    }
  }
};

export const AddSubProductContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AddSubProductContract",
    modelProperties: {
      subProductIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "subProductIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    }
  }
};

export const AddSubProductsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AddSubProductsResponse",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid"
        }
      },
      subProducts: {
        serializedName: "subProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubProduct"
            }
          }
        }
      }
    }
  }
};

export const RemoveSubProductResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RemoveSubProductResponse",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid"
        }
      },
      subProducts: {
        serializedName: "subProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubProduct"
            }
          }
        }
      }
    }
  }
};

export const ListNamesAndIdsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListNamesAndIdsResponse",
    modelProperties: {
      tradePartners: {
        serializedName: "tradePartners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SearchResult"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SearchResult"
            }
          }
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SearchResult"
            }
          }
        }
      }
    }
  }
};

export const SearchResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SearchResult",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ListShipmentsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListShipmentsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShipmentItem"
            }
          }
        }
      }
    }
  }
};

export const ShipmentItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipmentItem",
    modelProperties: {
      externalShipmentId: {
        serializedName: "externalShipmentId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productGtin: {
        serializedName: "productGTIN",
        nullable: true,
        type: {
          name: "String"
        }
      },
      productURN: {
        serializedName: "productURN",
        nullable: true,
        type: {
          name: "String"
        }
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      primaryId: {
        serializedName: "primaryId",
        type: {
          name: "String"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        nullable: true,
        type: {
          name: "String"
        }
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime"
        }
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrderNumber: {
        serializedName: "purchaseOrderNumber",
        nullable: true,
        type: {
          name: "String"
        }
      },
      isContainer: {
        serializedName: "isContainer",
        type: {
          name: "Boolean"
        }
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      containerItems: {
        serializedName: "containerItems",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContainerItem"
            }
          }
        }
      },
      shipmentEventId: {
        serializedName: "shipmentEventId",
        type: {
          name: "Uuid"
        }
      },
      originId: {
        serializedName: "originId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      originUrn: {
        serializedName: "originUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      destinationUrn: {
        serializedName: "destinationUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      originName: {
        serializedName: "originName",
        type: {
          name: "String"
        }
      },
      originAddress: {
        serializedName: "originAddress",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      destinationId: {
        serializedName: "destinationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      destinationName: {
        serializedName: "destinationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      destinationAddress: {
        serializedName: "destinationAddress",
        type: {
          name: "Composite",
          className: "Address"
        }
      },
      shipmentType: {
        serializedName: "shipmentType",
        type: {
          name: "String"
        }
      },
      senderName: {
        serializedName: "senderName",
        type: {
          name: "String"
        }
      },
      senderURN: {
        serializedName: "senderURN",
        type: {
          name: "String"
        }
      },
      receiverName: {
        serializedName: "receiverName",
        type: {
          name: "String"
        }
      },
      receiverURN: {
        serializedName: "receiverURN",
        type: {
          name: "String"
        }
      },
      isExternalShipment: {
        serializedName: "isExternalShipment",
        type: {
          name: "Boolean"
        }
      },
      direction: {
        serializedName: "direction",
        nullable: true,
        type: {
          name: "String"
        }
      },
      multiReceiveProcessingStatus: {
        serializedName: "multiReceiveProcessingStatus",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ContainerItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContainerItem",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      productGtin: {
        serializedName: "productGTIN",
        type: {
          name: "String"
        }
      },
      productURN: {
        serializedName: "productURN",
        type: {
          name: "String"
        }
      },
      primaryId: {
        serializedName: "primaryId",
        type: {
          name: "String"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ShipmentSummaryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipmentSummaryResponse",
    modelProperties: {
      shipments: {
        serializedName: "shipments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShipmentSummaryItem"
            }
          }
        }
      },
      totalOutboundShipments: {
        serializedName: "totalOutboundShipments",
        readOnly: true,
        type: {
          name: "Number"
        }
      },
      totalInboundShipments: {
        serializedName: "totalInboundShipments",
        readOnly: true,
        type: {
          name: "Number"
        }
      },
      totalShipments: {
        serializedName: "totalShipments",
        readOnly: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ShipmentSummaryItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipmentSummaryItem",
    modelProperties: {
      outboundShipments: {
        serializedName: "outboundShipments",
        type: {
          name: "Number"
        }
      },
      inboundShipments: {
        serializedName: "inboundShipments",
        type: {
          name: "Number"
        }
      },
      date: {
        serializedName: "date",
        nullable: true,
        type: {
          name: "Date"
        }
      }
    }
  }
};

export const RejectShipmentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RejectShipmentRequest",
    modelProperties: {
      shipmentEventId: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "shipmentEventId",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const CreateExternalShipmentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateExternalShipmentRequest",
    modelProperties: {
      shipment: {
        serializedName: "shipment",
        type: {
          name: "Composite",
          className: "ExternalShipEvent"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateProductRequest"
            }
          }
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExternalCreateLocationRequest"
            }
          }
        }
      },
      tradePartners: {
        serializedName: "tradePartners",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateTradePartnerRequest"
            }
          }
        }
      },
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExternalEvent"
            }
          }
        }
      }
    }
  }
};

export const ExternalShipEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExternalShipEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerUrn: {
        serializedName: "productOwnerUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      infoProviderUrn: {
        serializedName: "infoProviderUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      recordTime: {
        serializedName: "recordTime",
        type: {
          name: "DateTime"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      ilmdCertificationList: {
        serializedName: "ilmdCertificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      originLocationUrn: {
        serializedName: "originLocationUrn",
        type: {
          name: "String"
        }
      },
      destinationLocationUrn: {
        serializedName: "destinationLocationUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      originTradePartnerUrn: {
        serializedName: "originTradePartnerUrn",
        type: {
          name: "String"
        }
      },
      destinationTradePartnerUrn: {
        serializedName: "destinationTradePartnerUrn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      containerUrns: {
        serializedName: "containerUrns",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExternalProductInstance"
            }
          }
        }
      }
    }
  }
};

export const ExternalProductInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExternalProductInstance",
    modelProperties: {
      productUrn: {
        serializedName: "productUrn",
        type: {
          name: "String"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      productInstanceUrn: {
        serializedName: "productInstanceUrn",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateExternalShipmentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateExternalShipmentResponse",
    modelProperties: {
      externalShipmentId: {
        serializedName: "externalShipmentId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const GetExternalRegistryDetailsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetExternalRegistryDetailsResponse",
    modelProperties: {
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        type: {
          name: "String"
        }
      },
      apiKey: {
        serializedName: "apiKey",
        type: {
          name: "String"
        }
      },
      sourceTradePartyName: {
        serializedName: "sourceTradePartyName",
        type: {
          name: "String"
        }
      },
      sourceTradePartyEmail: {
        serializedName: "sourceTradePartyEmail",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SetExternalRegistryDetailsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SetExternalRegistryDetailsRequest",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "String"
        }
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        type: {
          name: "String"
        }
      },
      inboundApiKey: {
        serializedName: "inboundApiKey",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SetExternalRegistryDetailsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SetExternalRegistryDetailsResponse",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "String"
        }
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateExternalRegistryDetailsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateExternalRegistryDetailsRequest",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "String"
        }
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        nullable: true,
        type: {
          name: "String"
        }
      },
      inboundApiKey: {
        serializedName: "inboundApiKey",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateExternalRegistryDetailsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateExternalRegistryDetailsResponse",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "String"
        }
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        nullable: true,
        type: {
          name: "String"
        }
      },
      inboundApiKey: {
        serializedName: "inboundApiKey",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SetOutboundApiKeyResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SetOutboundApiKeyResponse",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "String"
        }
      },
      outboundApiKey: {
        serializedName: "outboundApiKey",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetDigitalLinkResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetDigitalLinkResponse",
    modelProperties: {
      linkType: {
        serializedName: "linkType",
        type: {
          name: "String"
        }
      },
      link: {
        serializedName: "link",
        type: {
          name: "String"
        }
      },
      authRequired: {
        serializedName: "authRequired",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const TemplateAttribute: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateAttribute",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      fieldProperties: {
        serializedName: "fieldProperties",
        type: {
          name: "Composite",
          className: "TemplateField"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        type: {
          name: "Uuid"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      lastModified: {
        serializedName: "lastModified",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const UpdateTemplateAttributeRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateTemplateAttributeRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      fieldProperties: {
        serializedName: "fieldProperties",
        type: {
          name: "Composite",
          className: "TemplateField"
        }
      }
    }
  }
};

export const ListTemplateAttributesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListTemplateAttributesResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateAttribute"
            }
          }
        }
      }
    }
  }
};

export const CreateTemplateAttributeRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateTemplateAttributeRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      fieldProperties: {
        serializedName: "fieldProperties",
        type: {
          name: "Composite",
          className: "TemplateField"
        }
      }
    }
  }
};

export const UpdateTradePartnerRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateTradePartnerRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String"
        }
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String"
        }
      },
      connectionType: {
        serializedName: "connectionType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      tradePartnerType: {
        serializedName: "tradePartnerType",
        nullable: true,
        type: {
          name: "String"
        }
      },
      showTraceInfoModal: {
        serializedName: "showTraceInfoModal",
        nullable: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const GetUserByIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetUserByIdResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      givenName: {
        serializedName: "givenName",
        type: {
          name: "String"
        }
      },
      surname: {
        serializedName: "surname",
        type: {
          name: "String"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      profilePictureUrl: {
        serializedName: "profilePictureUrl",
        nullable: true,
        type: {
          name: "String"
        }
      },
      preferences: {
        serializedName: "preferences",
        type: {
          name: "Composite",
          className: "UserPreferences"
        }
      },
      acceptedTermsAndConditions: {
        serializedName: "acceptedTermsAndConditions",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UserPreferences: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UserPreferences",
    modelProperties: {
      emailNotifications: {
        serializedName: "emailNotifications",
        type: {
          name: "Composite",
          className: "EmailNotificationsPreferences"
        }
      },
      languageCode: {
        serializedName: "languageCode",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EmailNotificationsPreferences: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EmailNotificationsPreferences",
    modelProperties: {
      inboundShipments: {
        serializedName: "inboundShipments",
        type: {
          name: "Boolean"
        }
      },
      outboundShipments: {
        serializedName: "outboundShipments",
        type: {
          name: "Boolean"
        }
      },
      networkInvites: {
        serializedName: "networkInvites",
        type: {
          name: "Boolean"
        }
      },
      sharing: {
        serializedName: "sharing",
        type: {
          name: "Boolean"
        }
      },
      documentAlert: {
        serializedName: "documentAlert",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UpdateUserRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateUserRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      role: {
        serializedName: "role",
        nullable: true,
        type: {
          name: "String"
        }
      },
      givenName: {
        serializedName: "givenName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      surname: {
        serializedName: "surname",
        nullable: true,
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        nullable: true,
        type: {
          name: "String"
        }
      },
      preferences: {
        serializedName: "preferences",
        type: {
          name: "Composite",
          className: "UserPreferences"
        }
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateUserResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateUserResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      },
      givenName: {
        serializedName: "givenName",
        type: {
          name: "String"
        }
      },
      surname: {
        serializedName: "surname",
        type: {
          name: "String"
        }
      },
      preferences: {
        serializedName: "preferences",
        type: {
          name: "Composite",
          className: "UserPreferences"
        }
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListUsersResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListUsersResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ListUsersResponseUser"
            }
          }
        }
      }
    }
  }
};

export const ListUsersResponseUser: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListUsersResponseUser",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      },
      invitation: {
        serializedName: "invitation",
        type: {
          name: "String"
        }
      },
      inviteUrl: {
        serializedName: "inviteUrl",
        nullable: true,
        type: {
          name: "String"
        }
      },
      inviteId: {
        serializedName: "inviteId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      acceptedTermsAndConditions: {
        serializedName: "acceptedTermsAndConditions",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const UploadProfilePictureResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UploadProfilePictureResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ChangeCurrentAccountRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeCurrentAccountRequest",
    modelProperties: {
      userId: {
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ChangeCurrentAccountResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeCurrentAccountResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const InviteUserRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InviteUserRequest",
    modelProperties: {
      emails: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "emails",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const InviteUserResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InviteUserResponse",
    modelProperties: {
      invites: {
        serializedName: "invites",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InviteUserResponseInvite"
            }
          }
        }
      }
    }
  }
};

export const InviteUserResponseInvite: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InviteUserResponseInvite",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      url: {
        serializedName: "url",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      expirationDate: {
        serializedName: "expirationDate",
        type: {
          name: "Date"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RedeemInviteResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RedeemInviteResponse",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      userId: {
        serializedName: "userId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const DeleteInviteResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DeleteInviteResponse",
    modelProperties: {
      inviteId: {
        serializedName: "inviteId",
        type: {
          name: "Uuid"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const IsInviteValidResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IsInviteValidResponse",
    modelProperties: {
      isValid: {
        serializedName: "isValid",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CreateWorkflowTemplateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateWorkflowTemplateRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateWorkflowTemplateRequestEventsItem"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEdge"
            }
          }
        }
      }
    }
  }
};

export const CreateWorkflowTemplateRequestEventsItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateWorkflowTemplateRequestEventsItem"
  }
};

export const WorkflowTemplateEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      eventTemplateId: {
        serializedName: "eventTemplateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowEdge: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowEdge",
    modelProperties: {
      source: {
        serializedName: "source",
        nullable: true,
        type: {
          name: "String"
        }
      },
      target: {
        serializedName: "target",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CreateWorkflowTemplateResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateWorkflowTemplateResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateWorkflowTemplateResponseEventsItem"
            }
          }
        }
      }
    }
  }
};

export const CreateWorkflowTemplateResponseEventsItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateWorkflowTemplateResponseEventsItem"
  }
};

export const WorkflowTemplateEventResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateEventResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      eventTemplateId: {
        serializedName: "eventTemplateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowTemplateTransformProducts: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateTransformProducts",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ListWorkflowTemplatesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListWorkflowTemplatesResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowTemplate"
            }
          }
        }
      }
    }
  }
};

export const WorkflowTemplate: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplate",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime"
        }
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime"
        }
      },
      createdBy: {
        serializedName: "createdBy",
        type: {
          name: "Uuid"
        }
      },
      lastModifiedBy: {
        serializedName: "lastModifiedBy",
        type: {
          name: "Uuid"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid"
        }
      },
      deleted: {
        serializedName: "deleted",
        type: {
          name: "Boolean"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      events: {
        serializedName: "events",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowTemplateEventsItem"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEdge"
            }
          }
        }
      }
    }
  }
};

export const WorkflowTemplateEventsItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateEventsItem"
  }
};

export const GetWorkflowTemplateByIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetWorkflowTemplateByIdResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      lastEdited: {
        serializedName: "lastEdited",
        type: {
          name: "DateTime"
        }
      },
      workflowEvents: {
        serializedName: "workflowEvents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "GetWorkflowTemplateByIdResponseWorkflowEventsItem"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEdge"
            }
          }
        }
      }
    }
  }
};

export const GetWorkflowTemplateByIdResponseWorkflowEventsItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetWorkflowTemplateByIdResponseWorkflowEventsItem"
  }
};

export const DeleteWorkflowTemplateResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DeleteWorkflowTemplateResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UpdateWorkflowTemplateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateWorkflowTemplateRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      workflowEvents: {
        serializedName: "workflowEvents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UpdateWorkflowTemplateRequestWorkflowEventsItem"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEdge"
            }
          }
        }
      }
    }
  }
};

export const UpdateWorkflowTemplateRequestWorkflowEventsItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateWorkflowTemplateRequestWorkflowEventsItem"
  }
};

export const UpdateWorkflowTemplateResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateWorkflowTemplateResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      workflowEvents: {
        serializedName: "workflowEvents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UpdateWorkflowTemplateResponseWorkflowEventsItem"
            }
          }
        }
      }
    }
  }
};

export const UpdateWorkflowTemplateResponseWorkflowEventsItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateWorkflowTemplateResponseWorkflowEventsItem"
  }
};

export const CreateWorkflowRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateWorkflowRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      workflowTemplateId: {
        serializedName: "workflowTemplateId",
        type: {
          name: "Uuid"
        }
      },
      deadline: {
        serializedName: "deadline",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      commissionEvents: {
        serializedName: "commissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowCommissionEvent"
            }
          }
        }
      },
      decommissionEvents: {
        serializedName: "decommissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowDecommissionEvent"
            }
          }
        }
      },
      aggregateEvents: {
        serializedName: "aggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowAggregateEvent"
            }
          }
        }
      },
      disaggregateEvents: {
        serializedName: "disaggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowDisaggregateEvent"
            }
          }
        }
      },
      observeEvents: {
        serializedName: "observeEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowObserveEvent"
            }
          }
        }
      },
      receiveEvents: {
        serializedName: "receiveEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowReceiveEvent"
            }
          }
        }
      },
      shipEvents: {
        serializedName: "shipEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowShipEvent"
            }
          }
        }
      },
      transformEvents: {
        serializedName: "transformEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowTransformEvent"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEdge"
            }
          }
        }
      }
    }
  }
};

export const WorkflowCommissionEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowCommissionEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NewEventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkflowEventProductInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowEventProductInstance",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      currentInventory: {
        serializedName: "currentInventory",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkflowDecommissionEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowDecommissionEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      decommissionType: {
        serializedName: "decommissionType",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkflowAggregateEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowAggregateEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      newProducts: {
        serializedName: "newProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkflowDisaggregateEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowDisaggregateEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        type: {
          name: "Uuid"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkflowObserveEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowObserveEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkflowReceiveEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowReceiveEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      externalShipmentId: {
        serializedName: "externalShipmentId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      originLocationId: {
        serializedName: "originLocationId",
        type: {
          name: "Uuid"
        }
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReceiveEventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkflowShipEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowShipEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      originLocationId: {
        serializedName: "originLocationId",
        type: {
          name: "Uuid"
        }
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      automaticReceive: {
        serializedName: "automaticReceive",
        type: {
          name: "Boolean"
        }
      },
      receiveEventTime: {
        serializedName: "receiveEventTime",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WorkflowTransformEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTransformEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      inputProducts: {
        serializedName: "inputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      outputProducts: {
        serializedName: "outputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NewEventProductInstance"
            }
          }
        }
      },
      isTransformAfterExternalReceive: {
        serializedName: "isTransformAfterExternalReceive",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      newInputProducts: {
        serializedName: "newInputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEventProductInstance"
            }
          }
        }
      },
      newOutputProducts: {
        serializedName: "newOutputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CreateWorkflowResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateWorkflowResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      workflowTemplateId: {
        serializedName: "workflowTemplateId",
        type: {
          name: "Uuid"
        }
      },
      deadline: {
        serializedName: "deadline",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      commissionEvents: {
        serializedName: "commissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowCommissionEvent"
            }
          }
        }
      },
      decommissionEvents: {
        serializedName: "decommissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowDecommissionEvent"
            }
          }
        }
      },
      aggregateEvents: {
        serializedName: "aggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowAggregateEvent"
            }
          }
        }
      },
      disaggregateEvents: {
        serializedName: "disaggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowDisaggregateEvent"
            }
          }
        }
      },
      observeEvents: {
        serializedName: "observeEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowObserveEvent"
            }
          }
        }
      },
      receiveEvents: {
        serializedName: "receiveEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowReceiveEvent"
            }
          }
        }
      },
      shipEvents: {
        serializedName: "shipEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowShipEvent"
            }
          }
        }
      },
      transformEvents: {
        serializedName: "transformEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowTransformEvent"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEdge"
            }
          }
        }
      }
    }
  }
};

export const EnrichedWorkflowCommissionEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EnrichedWorkflowCommissionEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NewEventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedNewEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EnrichedNewEventProductInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EnrichedNewEventProductInstance",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid"
        }
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      lotSerial: {
        serializedName: "lotSerial",
        type: {
          name: "String"
        }
      },
      currentInventory: {
        serializedName: "currentInventory",
        nullable: true,
        type: {
          name: "Number"
        }
      },
      productName: {
        serializedName: "productName",
        type: {
          name: "String"
        }
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const EnrichedWorkflowDecommissionEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EnrichedWorkflowDecommissionEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      decommissionType: {
        serializedName: "decommissionType",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedNewEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EnrichedWorkflowAggregateEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EnrichedWorkflowAggregateEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        type: {
          name: "String"
        }
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedNewEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EnrichedWorkflowDisaggregateEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EnrichedWorkflowDisaggregateEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        type: {
          name: "Uuid"
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedNewEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EnrichedWorkflowObserveEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EnrichedWorkflowObserveEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedNewEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EnrichedWorkflowReceiveEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EnrichedWorkflowReceiveEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      externalShipmentId: {
        serializedName: "externalShipmentId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      originLocationId: {
        serializedName: "originLocationId",
        type: {
          name: "Uuid"
        }
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReceiveEventProductInstance"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      originName: {
        serializedName: "originName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      destinationName: {
        serializedName: "destinationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedNewEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EnrichedWorkflowShipEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EnrichedWorkflowShipEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      originLocationId: {
        serializedName: "originLocationId",
        type: {
          name: "Uuid"
        }
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        type: {
          name: "Uuid"
        }
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      products: {
        serializedName: "products",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      automaticReceive: {
        serializedName: "automaticReceive",
        type: {
          name: "Boolean"
        }
      },
      receiveEventTime: {
        serializedName: "receiveEventTime",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      originName: {
        serializedName: "originName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      originTradePartnerId: {
        serializedName: "originTradePartnerId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      destinationName: {
        serializedName: "destinationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      destinationTradePartnerId: {
        serializedName: "destinationTradePartnerId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      newProducts: {
        serializedName: "newProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedNewEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EnrichedWorkflowTransformEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EnrichedWorkflowTransformEvent",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String"
        }
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "String"
        }
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String"
        }
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "String"
        }
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime"
        }
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        type: {
          name: "String"
        }
      },
      productOwnerId: {
        serializedName: "productOwnerId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      infoProviderId: {
        serializedName: "infoProviderId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty"
            }
          }
        }
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselCatchInformation"
            }
          }
        }
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo"
            }
          }
        }
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      documentsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid"
        }
      },
      inputProducts: {
        serializedName: "inputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventProductInstance"
            }
          }
        }
      },
      outputProducts: {
        serializedName: "outputProducts",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NewEventProductInstance"
            }
          }
        }
      },
      isTransformAfterExternalReceive: {
        serializedName: "isTransformAfterExternalReceive",
        nullable: true,
        type: {
          name: "Boolean"
        }
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventIdInTemplate: {
        serializedName: "eventIdInTemplate",
        nullable: true,
        type: {
          name: "String"
        }
      },
      targetId: {
        serializedName: "targetId",
        nullable: true,
        type: {
          name: "String"
        }
      },
      sourceId: {
        serializedName: "sourceId",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      newInputProducts: {
        serializedName: "newInputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedNewEventProductInstance"
            }
          }
        }
      },
      newOutputProducts: {
        serializedName: "newOutputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedNewEventProductInstance"
            }
          }
        }
      },
      workflowEventStatus: {
        serializedName: "workflowEventStatus",
        type: {
          name: "String"
        }
      },
      order: {
        serializedName: "order",
        nullable: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ListWorkflowsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListWorkflowsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        type: {
          name: "Number"
        }
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number"
        }
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number"
        }
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number"
        }
      },
      results: {
        serializedName: "results",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ListWorkflowItem"
            }
          }
        }
      }
    }
  }
};

export const ListWorkflowItem: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListWorkflowItem",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      deadline: {
        serializedName: "deadline",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      lastEditedBy: {
        serializedName: "lastEditedBy",
        type: {
          name: "String"
        }
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const GetWorkflowByIdResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetWorkflowByIdResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      workflowTemplateId: {
        serializedName: "workflowTemplateId",
        type: {
          name: "Uuid"
        }
      },
      workflowTemplateName: {
        serializedName: "workflowTemplateName",
        type: {
          name: "String"
        }
      },
      deadline: {
        serializedName: "deadline",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      commissionEvents: {
        serializedName: "commissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowCommissionEvent"
            }
          }
        }
      },
      decommissionEvents: {
        serializedName: "decommissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowDecommissionEvent"
            }
          }
        }
      },
      aggregateEvents: {
        serializedName: "aggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowAggregateEvent"
            }
          }
        }
      },
      disaggregateEvents: {
        serializedName: "disaggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowDisaggregateEvent"
            }
          }
        }
      },
      observeEvents: {
        serializedName: "observeEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowObserveEvent"
            }
          }
        }
      },
      receiveEvents: {
        serializedName: "receiveEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowReceiveEvent"
            }
          }
        }
      },
      shipEvents: {
        serializedName: "shipEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowShipEvent"
            }
          }
        }
      },
      transformEvents: {
        serializedName: "transformEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowTransformEvent"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEdge"
            }
          }
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DeleteWorkflowResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DeleteWorkflowResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UpdateWorkflowRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateWorkflowRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      workflowTemplateId: {
        serializedName: "workflowTemplateId",
        type: {
          name: "Uuid"
        }
      },
      deadline: {
        serializedName: "deadline",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      commissionEvents: {
        serializedName: "commissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowCommissionEvent"
            }
          }
        }
      },
      decommissionEvents: {
        serializedName: "decommissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowDecommissionEvent"
            }
          }
        }
      },
      aggregateEvents: {
        serializedName: "aggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowAggregateEvent"
            }
          }
        }
      },
      disaggregateEvents: {
        serializedName: "disaggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowDisaggregateEvent"
            }
          }
        }
      },
      observeEvents: {
        serializedName: "observeEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowObserveEvent"
            }
          }
        }
      },
      receiveEvents: {
        serializedName: "receiveEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowReceiveEvent"
            }
          }
        }
      },
      shipEvents: {
        serializedName: "shipEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowShipEvent"
            }
          }
        }
      },
      transformEvents: {
        serializedName: "transformEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowTransformEvent"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEdge"
            }
          }
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const UpdateWorkflowResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateWorkflowResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      workflowTemplateId: {
        serializedName: "workflowTemplateId",
        type: {
          name: "Uuid"
        }
      },
      deadline: {
        serializedName: "deadline",
        nullable: true,
        type: {
          name: "DateTime"
        }
      },
      commissionEvents: {
        serializedName: "commissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowCommissionEvent"
            }
          }
        }
      },
      decommissionEvents: {
        serializedName: "decommissionEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowDecommissionEvent"
            }
          }
        }
      },
      aggregateEvents: {
        serializedName: "aggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowAggregateEvent"
            }
          }
        }
      },
      disaggregateEvents: {
        serializedName: "disaggregateEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowDisaggregateEvent"
            }
          }
        }
      },
      observeEvents: {
        serializedName: "observeEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowObserveEvent"
            }
          }
        }
      },
      receiveEvents: {
        serializedName: "receiveEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowReceiveEvent"
            }
          }
        }
      },
      shipEvents: {
        serializedName: "shipEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowShipEvent"
            }
          }
        }
      },
      transformEvents: {
        serializedName: "transformEvents",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EnrichedWorkflowTransformEvent"
            }
          }
        }
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowEdge"
            }
          }
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1P0Y6ByDocumentsPostRequestbodyContentMultipartFormDataSchema: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1P0Y6ByDocumentsPostRequestbodyContentMultipartFormDataSchema",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      file: {
        serializedName: "file",
        type: {
          name: "Stream"
        }
      },
      privacy: {
        serializedName: "privacy",
        type: {
          name: "String"
        }
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "String"
        }
      },
      documentType: {
        serializedName: "documentType",
        type: {
          name: "String"
        }
      },
      documentTypeName: {
        serializedName: "documentTypeName",
        type: {
          name: "String"
        }
      },
      expirationDate: {
        serializedName: "expirationDate",
        type: {
          name: "Date"
        }
      },
      expirationAlertDate: {
        serializedName: "expirationAlertDate",
        type: {
          name: "Date"
        }
      },
      locationsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "locationsIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      productsIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "productsIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      tradePartnersIds: {
        constraints: {
          UniqueItems: true
        },
        serializedName: "tradePartnersIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      purchaseOrderNumber: {
        serializedName: "purchaseOrderNumber",
        type: {
          name: "String"
        }
      },
      certificationStandard: {
        serializedName: "certificationStandard",
        type: {
          name: "String"
        }
      },
      certificationAgency: {
        serializedName: "certificationAgency",
        type: {
          name: "String"
        }
      },
      certificationId: {
        serializedName: "certificationId",
        type: {
          name: "String"
        }
      },
      shipFrom: {
        serializedName: "shipFrom",
        type: {
          name: "String"
        }
      },
      shipTo: {
        serializedName: "shipTo",
        type: {
          name: "String"
        }
      },
      sID: {
        serializedName: "sID",
        type: {
          name: "String"
        }
      },
      cID: {
        serializedName: "cID",
        type: {
          name: "String"
        }
      },
      fOB: {
        serializedName: "fOB",
        type: {
          name: "String"
        }
      },
      shipDate: {
        serializedName: "shipDate",
        type: {
          name: "DateTime"
        }
      },
      carrier: {
        serializedName: "carrier",
        type: {
          name: "String"
        }
      },
      items: {
        serializedName: "items",
        type: {
          name: "String"
        }
      },
      quantitiesOfShippedItems: {
        serializedName: "quantitiesOfShippedItems",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths145UctvUsersProfilePicturePutRequestbodyContentMultipartFormDataSchema: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths145UctvUsersProfilePicturePutRequestbodyContentMultipartFormDataSchema",
    modelProperties: {
      picture: {
        serializedName: "picture",
        type: {
          name: "Stream"
        }
      }
    }
  }
};

export const WorkflowTemplateCommission: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateCommission",
    modelProperties: {
      ...WorkflowTemplateEvent.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowTemplateDecommission: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateDecommission",
    modelProperties: {
      ...WorkflowTemplateEvent.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowTemplateAggregate: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateAggregate",
    modelProperties: {
      ...WorkflowTemplateEvent.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowTemplateDisaggregate: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateDisaggregate",
    modelProperties: {
      ...WorkflowTemplateEvent.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowTemplateTransform: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateTransform",
    modelProperties: {
      ...WorkflowTemplateEvent.type.modelProperties,
      inputProductIds: {
        serializedName: "inputProductIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      outputProductIds: {
        serializedName: "outputProductIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowTemplateShip: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateShip",
    modelProperties: {
      ...WorkflowTemplateEvent.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      originId: {
        serializedName: "originId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      destinationId: {
        serializedName: "destinationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowTemplateReceive: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateReceive",
    modelProperties: {
      ...WorkflowTemplateEvent.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowTemplateObserve: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateObserve",
    modelProperties: {
      ...WorkflowTemplateEvent.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const WorkflowTemplateCommissionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateCommissionResponse",
    modelProperties: {
      ...WorkflowTemplateEventResponse.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTemplateName: {
        serializedName: "eventTemplateName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WorkflowTemplateDecommissionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateDecommissionResponse",
    modelProperties: {
      ...WorkflowTemplateEventResponse.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTemplateName: {
        serializedName: "eventTemplateName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WorkflowTemplateAggregateResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateAggregateResponse",
    modelProperties: {
      ...WorkflowTemplateEventResponse.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTemplateName: {
        serializedName: "eventTemplateName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WorkflowTemplateDisaggregateResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateDisaggregateResponse",
    modelProperties: {
      ...WorkflowTemplateEventResponse.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTemplateName: {
        serializedName: "eventTemplateName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WorkflowTemplateTransformResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateTransformResponse",
    modelProperties: {
      ...WorkflowTemplateEventResponse.type.modelProperties,
      inputProducts: {
        serializedName: "inputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowTemplateTransformProducts"
            }
          }
        }
      },
      outputProducts: {
        serializedName: "outputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WorkflowTemplateTransformProducts"
            }
          }
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTemplateName: {
        serializedName: "eventTemplateName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WorkflowTemplateShipResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateShipResponse",
    modelProperties: {
      ...WorkflowTemplateEventResponse.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      originId: {
        serializedName: "originId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      destinationId: {
        serializedName: "destinationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      originName: {
        serializedName: "originName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      destinationName: {
        serializedName: "destinationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTemplateName: {
        serializedName: "eventTemplateName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WorkflowTemplateReceiveResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateReceiveResponse",
    modelProperties: {
      ...WorkflowTemplateEventResponse.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTemplateName: {
        serializedName: "eventTemplateName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WorkflowTemplateObserveResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowTemplateObserveResponse",
    modelProperties: {
      ...WorkflowTemplateEventResponse.type.modelProperties,
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid"
        }
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String"
        }
      },
      eventTemplateName: {
        serializedName: "eventTemplateName",
        nullable: true,
        type: {
          name: "String"
        }
      }
    }
  }
};
