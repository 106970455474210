import { Button, Tag } from 'antd';
import { GCard } from 'components';
import { useModalVisibility } from 'hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import EditSubscription from '../../EditSubscription';
import PlanTable from '../../PlanTable/PlanTable';
import { DataType } from '../../PlanTable/typings';

const Subscription = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'accounts.view_account.subscription' });
  const editSubscriptionModal = useModalVisibility(false);

  const records: DataType[] = [
    {
      id: 1,
      title: t('row.users'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 2,
      title: t('row.products'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 3,
      title: t('row.documents'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 4,
      title: t('row.templates'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 5,
      title: t('row.comp_loc'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 6,
      title: t('row.network_loc'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 7,
      title: t('row.interoperability'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 8,
      title: t('row.api_access'),
      current: '33',
      plan: '88',
      custom: '5',
    },
    {
      id: 9,
      title: t('row.events'),
      current: '33',
      plan: '88',
      custom: '5',
    },
  ];

  const onSaveAttribute = useCallback(async (data: DataType) => {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ file: Subscription.tsx:108 ~ data:', data);
  }, []);

  return (
    <>
      {editSubscriptionModal.visible && <EditSubscription modal={editSubscriptionModal} />}
      <GCard
        title={t('header')}
        extra={[
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => editSubscriptionModal?.show()}
          >
            {t('edit')}
          </Button>,
        ]}
      >
        <PlanTable
          records={records}
          title={t('sub_header')}
          subTitle={t('premium')}
          extraSubTitle={<Tag className="green-tag">{t('active')}</Tag>}
          onSave={onSaveAttribute}
        />
      </GCard>
    </>
  );
};

export default Subscription;
