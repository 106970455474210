import { CSSProperties } from 'react';

export interface headerProps {
  title: string;
}

export const cardlabelStyle: CSSProperties = {
  fontSize: 14,
  fontWeight: 600,
};
